import '../css/home.css';
import React from 'react';
import { PublicNavbar, PublicFooter, ParticleBackground } from '../components/pagestyle.js';
import { Register } from '../components/header.js';

import { AliceChat } from './alice.js';
import { useState, useEffect, useRef } from "react";
import {
    Routes,
    Route,
    Link,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from "react-router-dom";
import Typed from 'typed.js';
import { GradientAnimation } from '../components/pagestyle.js';
import { ScrollToHashElement } from '../components/pagestyle.js';
import Carousel from 'react-material-ui-carousel'
import { useMediaQuery, useTheme } from '@mui/material';
import D3DynamicLine from '../d3Charts/d3DynamicLine.js';
import { TickerOfTheDay } from '../components/marketpulseComponents/TickerOfTheDay.js';


export function HomeStatisticsSection() {
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        fetch("/api/public/homepage_statistics")
            .then((response) => response.json())
            .then((json) => setStatistics(json));
    }, []);

    function formatNumber(num) {
        // add commas
        return num ? num.toLocaleString() : "..."
    }

    return (
        <div className="home-stat">
            <div className="home-stat-holder">
                <div className="home-stat">
                    <p className="home-stat-p">Active Companies and ETFs</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.debtorCount) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Daily Signals</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.signalCount) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Financial Ratios</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.financialRatios) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Monthly Insider Activity</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.insiderTradingUpdates) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Daily Sentiment Scores</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.ssCount) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Daily News Articles</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.articleCount) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Actively Covered Analysts</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.analysts) : "..."}
                    </h3>
                </div>
                <div className="home-stat">
                    <p className="home-stat-p">Relative Value Pairs</p>
                    <h3 className="home-stat-h3">
                        {statistics ? formatNumber(statistics.reValPairs) : "..."}
                    </h3>
                </div>
            </div>
        </div>
    );
}

function HomePagesDisplay() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }

    var subdomain = "";
    if (window.location.hostname.includes("researchdemo")) {
        subdomain = "researchdemo."
    }
    var api_url = `https://${subdomain}falpha.ai/api`
    if (window.location.hostname.includes("localhost")) {
        api_url = `http://localhost:3001/api`
    }
    const handleSignUp = (e) => {
        e.stopPropagation();
        window.location = `${api_url}/sign-up`;
    };
    return (
        <div style={{ width: "100vw", overflowX: "hidden" }}>

            <br></br>
            <br></br>
            <div style={{ width: "fit-content", maxWidth: "90%", margin: "auto" }} onClick={("Yes" == localStorage.getItem("user-is-logged-in-bam-money")) ? null : handleSignUp}>
                <TickerOfTheDay />
            </div>
            <br></br>

            <HomeStatisticsSection />

            <br></br>

            <div className="home-display-page dark" style={{ marginBottom: 0, padding: "20px" }}>
                <div>
                    <h1>Alpha-Enhancing Use Cases</h1>
                    <h3 style={{ width: "60%", margin: "auto" }}>Enhance your trading, analytical and hedging strategies with tailored AI models and integrated data. Our solution combines traditional and alternative data sources for insights into insider trading, unbiased sentiment analysis, and more.</h3>
                </div>
            </div>

            <div className="home-display-page dark" style={{ height: "fit-content", marginBottom: "40px" }}>
                <div className="home-display-cols">
                    <div style={{ flexBasis: "100%", margin: "0 20px" }}>
                        <img src="/images/home-icon-2.png" alt="" width="20%" class="color-icon" />
                        <h2>Top Relative Value</h2>
                        <h4>Companies are ranked by <strong style={{ color: 'var(--blue-font)' }}>future risk-return performance</strong>. Choose from 100+ trading strategies.</h4>
                    </div>
                    <div style={{ flexBasis: "100%", margin: "0 20px" }}>
                        <img src="/images/home-icon-3.png" alt="" width="20%" class="color-icon" />
                        <h2>Optimal Hedges & Portfolios</h2>
                        <h4>Compare ETFs, portfolios, and new recommendations. Discover <strong style={{ color: 'var(--blue-font)' }}>natural hedges</strong>.</h4>
                    </div>
                    <div style={{ flexBasis: "100%", margin: "0 20px" }}>
                        <img src="/images/home-icon-1.png" alt="" width="20%" class="color-icon" />
                        <h2>Improved Analytics Allocation</h2>
                        <h4>Data insights to help you target corporations <strong style={{ color: 'var(--blue-font)' }}>primed for</strong> price <strong style={{ color: 'var(--blue-font)' }}>action</strong>.</h4>
                    </div>
                    {/* <h1 className="home-display-h1">
                    <strong>“Past performance doesn't guarantee future results,”</strong><br />
                    <em className="orange" style={{ fontSize: "80px" }}>BUT</em><br />
                    <span style={{ fontSize: "25px" }}>our risk model gives you credit and equity predictions with confidence levels that outperform the market with precise trades (and we can prove it).</span><br />
                </h1> */}
                </div>
            </div>
            <div className="home-display-page" style={{ height: "fit-content", marginBottom: "20px" }}>
                <div>
                    <h1>Why fAlpha?</h1>
                    <h3 style={{ width: "60%", margin: "auto" }}>fAlpha exists to provide users with essential, high-quality information.</h3>
                    <h3 style={{ width: "60%", margin: "auto" }}>We ingest and clean vast amounts of traditional and alternative data, processing it through proprietary algorithms to ensure <span className="orange">information quality</span>. Using objective-driven AI and clear strategy insights, market regimes, trading horizons, and historical patterns, fAlpha predicts excess return directions with high <span className="orange">statistical confidence</span>. This information is seamlessly delivered, easily shareable, and securely hashed for immutability.</h3>
                </div>
                <br />
                <br />
                <div className="home-display-grid">
                    <div>
                        <img alt="" src="/images/NEWTP.gif" />
                    </div>
                    <div>
                        <h1>Enhanced Trading Intelligence</h1>
                        <h3>
                            Fundamental, market and technical data is changing faster than ever and in unexpected ways.
                            <br /><br />
                            Get ahead of the market with equity and credit signals that include <span className="orange">statistical confidence</span>, <span className="orange">performance vs indices</span>, and <span className="orange">sentiment</span> against similar companies.
                        </h3>
                    </div>
                    <div>
                        <h1>No Wasted Time or Real Estate</h1>
                        <h3>
                            You won't miss a beat or need to open a dashboard. fAlpha's Smart Button integrates advanced information directly into your workflow. Download it  once and enjoy seamless access without consuming valuable screen/real estate.
                        </h3>
                    </div>
                    <div>
                        <img alt="" src="/images/NEWSMB.gif" />
                    </div>
                    <div>
                        <img alt="" width="100" height="550" src="images/settings_newest.png" />
                    </div>
                    <div>
                        <h1>Truly Unique Alpha</h1>
                        <h3>
                            Achieve unique alpha by selecting from fAlpha's diverse data sets and strategies and/ or by integrating your own with our engine. While alpha typically decays as more traders use the same data, fAlpha's engine ensures your advantage remains distinct.
                        </h3>
                    </div>
                    <div>
                        <h1>Vast and Noise-free Data</h1>
                        <h3>
                            Information refers to data that has been processed, cleaned, organized, or structured in a way that provides context and meaning, therefore enabling actionable insights and understanding of relationships. fAlpha's proprietary algorithm efficiently cleans any data set within corporate risk management.
                        </h3>
                    </div>
                    <div>
                        <img alt="" src="/images/Popupnew.png" />
                        <p style={{ fontWeight: "400" }}>PNL charts provide actual results based on historical predictions and performance. Green shows profit, while red shows a loss.</p>
                    </div>
                    <div>
                        <h1>Trust Is Earned, Not Assumed</h1>
                        <h3>
                            Verify historical PnL from signals with fAlpha's immutable approach. It provides full-transparency by publishing historical data, signals, and confidence levels. Everything is time stamped by our cloud services for added trust and we'll provide access if requested.
                        </h3>
                    </div>
                    <div>
                        <h1>Designed For an Informed Network</h1>
                        <h3>
                            Just as you wouldn't overlook human insights or information, fAlpha delivers these to you by presenting analyst insights and updates on insider and analyst activity. Furthermore, the platform enhances user expertise by showcasing contributions when they prove valuable.
                        </h3>
                    </div>
                </div>
                <div style={{ transform: "scale(1.5)" }}><Register text="Try it Free" highlight /></div>
            </div>
            <div>
                <img alt="" src="/images/home-page-cycle-3-no-bam.svg" width="100%" />
            </div>
        </div>
    );
}

export function HomeOnboardingSection() {

    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        if (!fname) {
            setResponse("First name required to submit");
            return;
        }
        if (!lname) {
            setResponse("Last name required to submit");
            return;
        }
        if (!email) {
            setResponse("Email required to submit");
            return;
        }
        const validateEmail = (em) => {
            return String(em)
                .toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        if (!validateEmail(email)) {
            setResponse("Valid email required to submit");
            return;
        }
        /*let token = captchaRef.current.getValue();
        if (!token) {
            setResponse("Recaptcha required to submit");
            return;
        }*/
        try {
            let res = await fetch("/api/public/contact", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: {
                        first: fname,
                        last: lname,
                    },
                    email: email,
                    message: message,
                    //captcha: token
                }),
            });
            //captchaRef.current.reset();
            if (res.status === 200) {
                setFName("");
                setLName("");
                setEmail("");
                setMessage("");
                setResponse("Message sent successfully");
            } else if (res.status === 403 || res.status === "403") {
                setResponse("Message error: please write in English and do not include links");
            } else {
                setResponse("Some error occurred");
                console.log("not here");
            }
        } catch (err) {
            console.log(err);
        }
    };

    function handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${Math.min(e.target.scrollHeight - 20, 116)}px`;
    }

    return (
        <div className="home-onboarding-section">
            <div className="home-onboarding-text" style={{ color: "black" }}>
                <h3 className="home-onboarding-h3" style={{ color: "black" }}>See why the <span className="orange">world's top</span> asset managers, i-bankers, and <span className="orange">traders</span> seek fAlpha.</h3>
            </div>
            <form className="home-onboarding-form" style={{ color: "black" }}>
                <h2 className="home-onboarding-h2" style={{ color: "black" }}>Book a DEMO</h2>
                <label htmlFor="fname">First Name</label>
                <input type="text" value={fname} id="fname" onChange={(e) => setFName(e.target.value)} />
                <label htmlFor="lname">Last Name</label>
                <input type="text" value={lname} id="lname" onChange={(e) => setLName(e.target.value)} />
                <label htmlFor="email">Email</label>
                <input type="text" value={email} id="email" onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="message">Message</label>
                <textarea rows="1" name="text" value={message} id="message" placeholder="optional" onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyDown} />
                {/*<div className="home-onboarding-recaptcha">{recaptchaSiteKey ? <ReCAPTCHA sitekey={recaptchaSiteKey} ref={captchaRef} /> : null}</div>*/}
                <button onClick={handleSubmit}>Submit</button>
                <div className="message">{response ? <p>{response}</p> : null}</div>
            </form>
        </div>
    );

}

const Typewriter = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        let timeout;
        if (currentIndex < text.length) {
            timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);
        }
        return () => clearTimeout(timeout);
    }, [currentIndex, delay, text]);
    return <span>{currentText}</span>;
};

export function HomeCardTemplate(props) {
    const [cardState, setCardState] = useState(false);
    const pRef = useRef(null);

    const Trigger = () => {
        setCardState(true);
        pRef.current.style.height = 'fit-content';
    }
    useEffect(() => {
        if (props.triggered) {
            pRef.current.style.height = 'fit-content';
        }
    }, []);

    return (
        <div className="home-card">
            <h3 className="home-card-h3">{props.title}</h3>
            <p ref={pRef} className="home-card-p">{props.shown} {props.hidden}</p>
            {props.triggered || cardState ? null : <button onClick={Trigger} style={{ border: "none" }}>Learn more</button>}
        </div>
    )
}

function HomeCardsSection() {
    return (
        <div className="home-card-container">
            <HomeCardTemplate
                title="A New Way for Worldclass Traders"
                shown="Traders have always needed unique, quantifiable, and reliable alpha to succeed. But with digital sources the game has gotten tougher."
                hidden="Alpha uniqueness is key even in non-zero-sum game market situations. It must allow traders to generate profits not easily replicated by others. On the other hand, risk management is about quantifying and acting in markets. This requires having sound and flexible statistical models. But that is not enough, data reliability is essential. Traders need to be able to trust their data. That is, traders should not use black boxes. Instead they should only rely on data that is verifiable and transparent. Doing this with outside data providers is revolutionary especially in this day of generative AI."
            />
            <HomeCardTemplate
                title="Quantifiable and Verifiable Alpha"
                shown="Quantification and verification are two sides of the same coin. fAlpha constantly calculates trading signals, confidence levels of its large sets of data, then it backtests its statistical summaries (ex ante and ex post)."
                hidden="And, to make YOU (the data user) comfortable and confident, fAlpha releases the performance of its statistical predictions. Its sources and historical sets are not only made available to YOU, they are intrinsic to fAlpha's ongoing product. This keeps us focused on our truth telling mission. That is, fAlpha goes beyond a “we stand by our data” rhetoric. We even guarantee some users' with their money back (depending the account settings). "
            />
            <HomeCardTemplate
                title="Traders and Analysts Use Cases"
                shown="Risk takers and analysts focused on corporate names use fAlpha's platform and/or its PRA to make more accurate, faster and reliable decisions. In a few seconds they gain insights on potentially hidden digital data."
                hidden="Traders get buy/sell signals, associated confidence levels, and various sentiment dimensions current and historical. For instance, they can check current and historical risk metrics, and accurately perform what if analyses. They can also create relative value groups, and get insights about corporates with similar behaviors. Analysts can also download and check historical data sets. They can mix those with their own other time series and even train their models with fAlpha's sentiment scores. Both traders and analysts can keep their stakeholders easily and automatically in the loop."
            />
            <HomeCardTemplate
                title="Context and Investment Signals"
                shown="Markets can move mostly in one direction but even in those cases some names lag. While there is always a reason time is of the essence. And since it is about YOUR alpha. YOUR concerns may differ from others."
                hidden="For instance, you may want to optimize some macro economic factor or view. fAlpha machines help you spot checking historical results. Its proprietary indicators identify market opportunities that are imperceptible to the naked eye. Plus it makes it easy for your eyes only. fAlpha helps you get ahead of the market and make smarter investment decisions right away."
            />
            <HomeCardTemplate
                title="How  Uses AI and LLMs"
                shown="fAlpha uses backpropagation to train several of its signals, it also uses large language models (LLMs) to communicate with its proprietary database."
                hidden="However, fAlpha is not a generative AI tech company, so it does not run the risk of producing unverifiable data or hallucinations around its signals. fAlpha delivers its data through a variety of forms, including a dashboard, a mobile app, an API, and Simple Storage Service (S3)."
            />
            <HomeCardTemplate
                title="Why fAlpha"
                shown="fAlpha was founded to help bring operational efficiencies to the corporate trading markets. Some of its founders lived through those inefficiencies (as users and producers) and know fixing is within reach."
                hidden="fAlpha's goal is to reduce market noise, especially in the one associated with digital data sets. More than being confident about its data and models, fAlpha is accountable to it outcomes. Regardless if the outcomes agree with fAlpha's initial predictions it displays them.  fAlpha will even issue partial refunds when the overall statistical results are off. There are reasons for fAlpha to be trusted by world-renowned institutions. Request special access through our contact form above."
            />
        </div>
    );
}

export function HomePageChatOnly() {
    const navigate = useNavigate();

    function handleQuestion(question) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question: question }),
        };
        fetch('/api/public/create-chat', requestOptions)
            .then(response => response.json())
            .then(uuid => navigate(`/demo/par/${uuid}/${encodeURIComponent(question)}`));
    }

    const [exampleCompany1, setExampleCompany1] = useState(null);
    const [exampleCompany2, setExampleCompany2] = useState(null);

    function setUpExamples() {
        const companies = [
            "The Bancorp Inc",
            "Healthcare Services Group Inc",
            "Fisker Inc",
            "Stem Inc",
            "Global Industrial Co",
            "Express Inc",
            "American Eagle Outfitters Inc",
            "Microstrategy Inc",
            "ImmunityBio Inc",
            "Proterra Inc",
            "Meta",
            "Amazon",
            "Walmart",
            "Fortinet Inc",
            "Qualcomm",
            "Pfizer Inc",
            "Moderna Inc",
            "Nordstrom Inc",
            //"Under Armour Inc", // Disabled for ticker ambiguity reasons
            "Avis Budget Group Inc",
            "Post Holdings Inc",
            "Southwestern Energy Co"
        ];
        const shuffled = companies.sort(() => 0.5 - Math.random());
        let selected = shuffled.slice(0, 2);
        setExampleCompany1(selected[0]);
        setExampleCompany2(selected[1]);
    }

    function example1() {
        handleQuestion(encodeURIComponent(`What is the current news sentiment towards ${exampleCompany1}? Include sources.`));
    }

    function example2() {
        handleQuestion(encodeURIComponent(`I'm looking at ${exampleCompany2}, what other companies should I look into?`));
    }

    const [input, setInput] = useState("");

    let selfSubmit = async (e) => {
        e.preventDefault();
        handleQuestion(encodeURIComponent(input));
        setInput("");
    }

    function isLikelyUUID(str) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(str);
    }

    var displayHeaderUUID = null;

    function initializeUUID() {
        // detect and display header
        if (localStorage) {
            const localuuid = localStorage.getItem("lastChat");
            if (isLikelyUUID(localuuid)) {
                displayHeaderUUID = localuuid;
                return;
            }
        }
        if (sessionStorage) {
            const sessionuuid = sessionStorage.getItem("lastChat");
            if (isLikelyUUID(sessionuuid)) {
                displayHeaderUUID = sessionuuid;
                return;
            }
        }
    }

    const homePageTyped = useRef(null);

    useEffect(() => {
        initializeUUID();
        setUpExamples();
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <div className="home-background" style={{ color: "var(--navy)" }}>
                <div style={{ zIndex: "5", marginBottom: "60px" }} className="home-headline">
                    <h1 style={{ textAlign: "left" }}>Meet <span className="orange">Alice</span>, your AI-assistant to easily access fAlpha's insights<span style={{ fontSize: "1rem" }}>(BETA)</span></h1>
                </div>
                <div className="home-chatbox" style={{ zIndex: "5" }}>
                    <div className="home-capabilities-row">
                        <button onClick={example1} className="home-capabilities-button">What is the current news sentiment towards {exampleCompany1}? Include sources.</button>
                        <button onClick={example2} className="home-capabilities-button">I'm looking at {exampleCompany2}, what other companies should I look into?</button>
                    </div>
                    {/* Different sizing on mobile vs. desktop */}
                    <form id={isMobile ? "home-chat-form-mobile" : "home-chat-form"}>
                        <input id={isMobile ? "home-chat-input-mobile" : "home-chat-input"}
                            type="text"
                            placeholder="Ask me about corporate credit trading..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)} />
                        <button onClick={selfSubmit}
                            id={isMobile ? "home-chat-submit-mobile" : "home-chat-submit"}
                            style={{ boxShadow: "none" }}>
                            <img className={isMobile ? "home-chat-submit-icon-mobile" : "home-chat-submit-icon"}
                                src="/images/submit-button.png" alt="submit question" />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

function Testimonial(props) {
    return (
        <div className='home-testimonials'>
            <p>{props.text}</p>
            <p>
                <strong>{props.name}</strong>
                <br />
                <em>{props.description}</em>
            </p>
        </div>
    )
}

function HomeTestimonialsSection() {
    return (
        <div className='home-testimonial-card'>
            <h1>What Industry and Academic Experts Say</h1>
            <Carousel autoPlay indicators swipe cycleNavigation fullHeightHover animation="slide">
                <Testimonial
                    name="Bruce Petersen"
                    description="Trader, quantitative strategist and risk manager FICC (rates and credit), Retired from Goldman Sachs"
                    text="fAlpha.ai has distinguished itself within the crowded field of market data providers. Its state-of-the-art financial AI and machine learning models and dedicated research team ensure the accuracy of the confidence levels displayed. I am also impressed with fAlpha's transparency, including acknowledging errors. These strengths will allow it to compete effectively with much larger players in the space."
                />
                <Testimonial
                    name="Kay Giesecke"
                    description="Professor of Engineering and Director of Advanced FinTech Lab and Comp Finance, Stanford University"
                    text="fAlpha is a beacon in the vast landscape of market data providers, where many offerings are simply noise. This can be largely attributed to fAlpha's team, and in particular the visionary leadership of CEO Geraldo Filgueiras. The firm's cutting-edge ML technology offers robust and transparent corporate market insights, delivered with statistical confidence levels that quantify forecast uncertainty. This sets them apart from other providers in a competitive sector where some players spend as much as $15 billion annually on data and technology."
                />
            </Carousel>
        </div>
    )
}

export function HomePageAnimation(props) {

    if (props.topHalf) {
        return (
            <div style={{ overflow: "hidden", height: "300px" }}>
                <div style={{ position: "relative", height: "600px" }} className={props.darkBenchmark ? "truecolor" : ""}>
                    <div className="home-page-gear-chart-container">
                        <div style={{
                            borderTop: "2px solid #3E6A96",
                            borderBottom: "2px solid #3E6A96",
                            height: "calc(100% - 100px)",
                            marginTop: "50px"
                        }}>
                            <D3DynamicLine data={null} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={{ position: "relative", height: "fit-content", maxWidth: "90vh", overflow: "hidden" }} className={props.darkBenchmark ? "truecolor" : ""}>

            <div className="home-page-gear-chart-container">
                <div style={{
                    borderTop: "2px solid #3E6A96",
                    borderBottom: "2px solid #3E6A96",
                    height: "calc(100% - 100px)",
                    marginTop: "50px"
                }}>
                    <D3DynamicLine data={null} />
                </div>
            </div>
            <img src="/images/home-page-animation-back-new2.png" alt="" width="100%" />
            <img src="/images/front-gear-spinner.png" alt="" width="33.5%" className="home-page-gear-new-spin" />
            {
                props.darkBenchmark &&
                <div style={{
                    backgroundColor: "var(--lightsteelblue)",
                    position: "absolute",
                    top: "5%",
                    left: "12%",
                    height: "3.5%",
                    width: "18.5%",
                    zIndex: -1,
                    borderRadius: "3px"
                }}></div>
            }
        </div>
    )
}

function HomePageMainNew() {
    const homePageTyped = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        if (homePageTyped.current) {
            const typed = new Typed(homePageTyped.current, {
                strings: [ /* old orange: #FF820E*/
                    "<span style='color:var(--blue-font)'>trading opportunities</span>",
                    "<span style='color:var(--blue-font)'>financial risks</span>",
                    "<span style='color:var(--blue-font)'>portfolio optimizations</span>"
                ],
                startDelay: 100,
                typeSpeed: 70,
                backSpeed: 0,
                backDelay: 1000,
                loop: true,
                loopCount: Infinity,
            });

            return () => {
                typed.destroy();
            };
        }

    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <PublicNavbar />
            <GradientAnimation class="home-background-canvas" />
            <div className="home-background">
                <div className="home-headline" >
                    <div style={{ width: isMobile ? "100%" : "50%", float: "left", paddingTop: "5vh", overflowX: "visible", color: "var(--dark-font)" }}>
                        {/*
                            ((new Date().getDate()) % 4 < 2) ?
                                (
                                    ((new Date().getDate()) % 4 == 0) ?
                                        <h1 className="home-h1 home-h1-t3"><em>The</em> Trading Data Engine for Agile Investors to Optimize<strong> Your Risk-Return Performance</strong><strong style={{ color: 'var(--orange)' }}>.</strong></h1>
                                        :
                                        <h1 className="home-h1 home-h1-t3">For Traders Like You Who Value Due <strong>Diligence First</strong><strong style={{ color: 'var(--orange)' }}>.</strong></h1>
                                )
                                :
                                (
                                    ((new Date().getDate()) % 4 == 3) ?
                                        <h1 className="home-h1 home-h1-t3"><em>The</em> Ultimate Risk Assistant for <strong><em>Credit and Equity</em></strong> Trading to Grow Your Business<strong style={{ color: 'var(--orange)' }}>.</strong></h1>
                                        :
                                        <h1 className="home-h1 home-h1-t3">Premier AI-Driven <strong>Top Risk Management</strong> for Your Trading<strong style={{ color: 'var(--orange)' }}>.</strong></h1>
                                )
                        */}
                        <h1 className="home-h1">
                            <em>The Risk Assistant to</em>
                            <br />
                            <strong>Grow Your Assets,</strong>
                            <br />
                            <strong>Statistically Beat Indices</strong>
                        </h1>
                        <br />
                        <p style={{ textAlign: "left", fontSize: "0.9rem" }}>
                        Tool for accurate predictions with <strong>proven 1% annualized <em>excess returns</em></strong> on corporates, ETFs.
                        <br /><br />
                        Enterprise-ready, tailored risk management engine with <strong>no-code or data scientists needed</strong>.
                        <br /><br />
                        Check the impact of facts and perceptions fast, <strong>70%+ savings of tedious, relevant work</strong>.
                        </p>
                        <br />
                        <br />
                        <div style={{ transform: "scale(1.5)" }}>
                            <Register text="Try it Free" demoLeft />
                        </div>
                    </div>


                    {!isMobile &&
                        <div className='home-main-right-container'>
                            <HomePageAnimation />
                        </div>
                    }
                </div>
                <div style={{ position: "absolute", bottom: "0", left: "50%", transform: "translate(-50%, 0)" }}>
                    <svg className="arrows">
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>
                </div>
            </div>
        </div>

    );
}


export default function Home() {
    let location = useLocation();

    const [installPrompt, setInstallPrompt] = useState(null);

    window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        setInstallPrompt(event);
    });

    window.document.title = "fAlpha.ai | Home";

    const [showDiv, setShowDiv] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 1) {
                setShowDiv(true);
            } else {
                setShowDiv(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {showDiv && <div className='home-tmp-navbar'><PublicNavbar /></div>}
            <ScrollToHashElement />
            <div className="particle-background">
                <ParticleBackground />
            </div>
            <Routes location={location}>
                <Route path="/" element={<HomePageMainNew />} />
                {/*<Route path="/demo" element={<HomePageMain />} />*/}
                <Route path="/demo/par/" element={<AliceChat installPrompt={installPrompt} />} />
                <Route path="/demo/par/:id/:question?" element={<AliceChat installPrompt={installPrompt} />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <HomePagesDisplay />
            <Routes location={location}>
                <Route path="/" element={<HomePageChatOnly />} />
            </Routes>
            <HomeCardsSection />
            <HomeTestimonialsSection />
            <br></br><br></br>
            <span id="contact-us"></span>
            <HomeOnboardingSection />
            <PublicFooter />
        </>
    );
}
