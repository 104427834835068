import React, { useState, useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import {
    Link,
    Navigate,
    useLocation,
    useParams,
    useNavigate
} from "react-router-dom";

import { Sentiment, Check, HistoricalCheck } from '../dataComponents/charts.js';
import { PublicNavbar } from '../components/pagestyle.js';
import { FeedbackModal } from "../components/FeedbackModal";
import { Register } from '../components/header.js';


function AliceChatQuestion(props) {
    return (
        <div className="home-chat-query">
            <span className="home-chat-query-dot"></span>
            <div className="home-chat-query-text">{`${props.question}`}</div>
        </div>
    );
}

function AliceChatResponse(props) {

    const [action, setAction] = useState(null);
    var ssTickers = [];

    function likeAction() {
        if (action === "like") {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=-1&dislike=0`);
            setAction(null);
        } else if (action === "dislike") {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=1&dislike=-1`);
            setAction("like");
        } else {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=1&dislike=0`);
            setAction("like");
        }
    }

    function dislikeAction() {
        if (action === "dislike") {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=0&dislike=-1`);
            setAction(null);
        } else if (action === "like") {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=-1&dislike=1`);
            setAction("dislike");
        } else {
            fetch(`/api/public/update-interactions?uuid=${props.uuid}&index=${props.index}&like=0&dislike=1`);
            setAction("dislike");
        }
    }

    function shareAction() {
        if (navigator.share) {
            try {
                const data = { url: document.location.href };
                navigator.share(data);
            } catch (e) {
                navigator.clipboard.writeText(document.location.href);
            }
        } else {
            navigator.clipboard.writeText(document.location.href);
        }
    }

    function parseToStrongTags(text, keyPrefix) {
        const prefix = "### ";
        if (text.startsWith(prefix)) {
            return (
                <h3 className='home-chat-parsed-h3' key={keyPrefix}>{text.slice(prefix.length)}</h3>
            )
        }
        const regex = /\*\*(.*?)\*\*/g; // Regular expression to find text surrounded by **
        const parts = [];
        let lastIndex = 0;
        let match;
        let keyIndex = 0;

        while ((match = regex.exec(text)) !== null) {
            // Push the text before the ** part
            if (match.index > lastIndex) {
                parts.push(
                    <React.Fragment key={`${keyPrefix}-${keyIndex++}`}>
                        {text.slice(lastIndex, match.index)}
                    </React.Fragment>
                );
            }
            // Push the **text** part wrapped in <strong>
            parts.push(
                <strong key={`${keyPrefix}-${keyIndex++}`}>
                    {match[1]}
                </strong>
            );
            lastIndex = match.index + match[0].length;
        }

        // Push any remaining text after the last match
        if (lastIndex < text.length) {
            parts.push(
                <React.Fragment key={`${keyPrefix}-${keyIndex++}`}>
                    {text.slice(lastIndex)}
                </React.Fragment>
            );
        }

        return parts;
    }

    const parseResponse = (str) => {
        const tickerTagRegex = /\(([A-Z]{1,5})\)/g;
        const tickerMatches = str.match(tickerTagRegex);

        if (tickerMatches) {
            ssTickers = [tickerMatches[0].substring(1, tickerMatches[0].length - 1)];
        }

        const urlRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
        const newlineRegex = /(\r\n|\n|\r)+/g;
        const parts = [];
        let lastIndex = 0;
        let match;
        while ((match = urlRegex.exec(str)) !== null) {
            const [fullMatch, text, url] = match;
            const beforeLink = str.slice(lastIndex, match.index);
            const linesBeforeLink = beforeLink.split(newlineRegex);
            linesBeforeLink.forEach((line, index) => {
                if (line.includes("\n")) {
                    parts.push(
                        <br key={`line-br-${props.index}-${lastIndex}-${index}`} />
                    );
                } else {
                    parts.push(
                        parseToStrongTags(line, `line-${props.index}-${lastIndex}-${index}`)
                    );
                }
            });
            parts.push(
                <a className="home-chat-response-link" key={`line-${props.index}-${lastIndex}-${url}`} href={url} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            );
            lastIndex = urlRegex.lastIndex;
        }
        const remainingText = str.slice(lastIndex);
        const linesAfterLastLink = remainingText.split(newlineRegex);
        linesAfterLastLink.forEach((line, index) => {
            if (line.includes("\n")) {
                parts.push(
                    <br key={`after-br-${props.index}-${index}`} />
                );
            } else {
                parts.push(
                    parseToStrongTags(line, `after-${props.index}-${index}`)
                );
            }
        });
        return parts;
    };

    function parseCharts(tickers) {
        var chartArr = [];

        for (let i = 0; i < tickers.length; i++) {
            chartArr.push(<Sentiment ticker={tickers[i]} key={`sschart-${props.index}-${i}`} />);
            chartArr.push(<Check ticker={tickers[i]} key={`checkchart-${props.index}-${i}`} />);
            chartArr.push(<HistoricalCheck ticker={tickers[i]} key={`histcheck-${props.index}-${i}`} />);
        }
        return chartArr;
    }

    return (
        <div className="home-chat-response">
            <span className="home-chat-response-dot"></span>
            <div className="home-chat-response-text">
                {parseResponse(props.text)}
            </div>
            <div className="home-chat-response-widget">
                {ssTickers ? parseCharts(ssTickers) : null}
            </div>

            <div className="home-chat-response-buttons">
                <button className={`home-chat-response-button ${action === "like" ? "highlighted-button" : null}`} onClick={likeAction}>
                    <img src="/images/like-icon.png" alt="" />
                </button>
                <button className={`home-chat-response-button ${action === "dislike" ? "highlighted-button" : null}`} onClick={dislikeAction}>
                    <img className="dislike-icon" src="/images/like-icon.png" alt="" />
                </button>
                {navigator.share ?
                    <button className="home-chat-response-button" onClick={shareAction}>
                        <img src="/images/share-icon.png" alt="" />
                    </button> :
                    <Popup

                        trigger={open => (
                            <button className={`home-chat-response-button ${open ? "highlighted-button" : null}`}>
                                <img src="/images/share-icon.png" alt="" />
                            </button>
                        )}
                        position="right center"
                        closeOnDocumentClick
                        modal
                    >
                        <FeedbackModal type="share" />
                    </Popup>
                }
                <Popup

                    trigger={open => (
                        <button>
                            Feedback
                        </button>
                    )}
                    position="right center"
                    closeOnDocumentClick
                    modal
                >
                    <FeedbackModal type="feedback" />
                </Popup>

                <div className="home-chat-response-button-right">
                    <Register text="Register to get more" />
                </div>
            </div>
        </div>
    );
}

function AliceChatLoading() {
    const [message, setMessage] = useState(null);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setTimeout(setMessage, 5000, true);
        const interval = setInterval(() => {
            setCounter((counter) => counter + 1);
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="home-chat-response">
            <span className="home-chat-response-dot"></span>
            <div className="home-chat-response-text home-chat-response-text-loading">
                <div className="home-spinner"></div>
                <p className='counter'>{(counter / 10).toFixed(1)}s</p>
                {message ? <p>Responses may take up to 20 seconds.</p> : null}
            </div>
            <div className="home-chat-response-buttons">
                <button className={`home-chat-response-button`} disabled>
                    <img src="/images/like-icon.png" alt="" />
                </button>
                <button className={`home-chat-response-button`} disabled>
                    <img className="dislike-icon" src="/images/like-icon.png" alt="" />
                </button>
                <button className="home-chat-response-button" disabled>
                    <img src="/images/share-icon.png" alt="" />
                </button>
                <div className="home-chat-response-button-right">
                    <Register />
                </div>
            </div>
        </div>
    );
}

function AliceChatLimit() {
    return (
        <div className="home-chat-response">
            <span className="home-chat-response-dot"></span>
            <div className="home-chat-response-text">
                <p>You have used your 10 daily free questions. Please register below for a free trial.</p>
                <Register />
                <p><Link to="/contact" style={{ color: "var(--light-font)" }}>Contact us</Link> for more details.</p>
            </div>
            <div className="home-chat-response-buttons">
                <button className={`home-chat-response-button`} disabled>
                    <img src="/images/like-icon.png" alt="" />
                </button>
                <button className={`home-chat-response-button`} disabled>
                    <img className="dislike-icon" src="/images/like-icon.png" alt="" />
                </button>
                <button className="home-chat-response-button" disabled>
                    <img src="/images/share-icon.png" alt="" />
                </button>
                <div className="home-chat-response-button-right">
                    <Register />
                </div>
            </div>
        </div>
    );
}

export function AliceChat(props) {

    const [messages, setMessages] = useState([]);

    const [tmpQuestion, setTmpQuestion] = useState(null);
    const [tmpResponse, setTmpResponse] = useState(null);

    const [showLimit, setShowLimit] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    var pathPrefix = "/alice";
    if (location.pathname.includes("/demo/par")) {
        pathPrefix = "/demo/par";
    }


    function isLikelyUUID(str) {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(str);
    }

    function getMessageHistory(uuid) {
        function handleUpdate(msgs) {
            setMessages(msgs);
            setTmpQuestion(null);
            setTmpResponse(null);
        }

        fetch(`/api/public/retrieve-chat?uuid=${uuid}`)
            .then((response) => response.json())
            .then((json) => handleUpdate(json.messages))
            .catch((error) => {
                handleNewThread("");
                return;
            });
    }

    function updateMessageHistory(input, uuid) {
        setTmpResponse(input);

        function handleUpdate(msgs) {
            setMessages(msgs);
            setTmpQuestion(null);
            setTmpResponse(null);
        }

        fetch(`/api/public/retrieve-chat?uuid=${uuid}`)
            .then((response) => response.json())
            .then((json) => handleUpdate(json.messages))
            .catch((error) => {
                handleNewThread("");
                return;
            });
    }

    function handleQuestionResponse(question, uuid) {
        if ((question === null) || (encodeURIComponent(question) === "undefined")) {
            navigate(`${pathPrefix}/${uuid}`);
            return;
        }
        navigate(`${pathPrefix}/${uuid}`);
        setTmpQuestion(question);

        if (localStorage.getItem("user-is-logged-in-bam-money") != "Yes") {
            // not logged in
            const count = localStorage.getItem("free-question-count-bam-money");
            if (count == null) {
                const date = Date.now();
                localStorage.setItem("free-question-count-bam-money", "1");
                localStorage.setItem("free-question-date-bam-money", date.toString());
            } else {
                const num_count = parseInt(count);
                const last_date = localStorage.getItem("free-question-date-bam-money");
                const num_last_date = parseInt(last_date);

                const date = Date.now();

                if (num_last_date + 86400000 < date) {
                    // cooldown reset cooldown
                    localStorage.setItem("free-question-date-bam-money", date.toString());
                    localStorage.setItem("free-question-count-bam-money", "1");
                } else {
                    if (num_count < 10) {
                        localStorage.setItem("free-question-count-bam-money", (num_count + 1).toString());
                    } else {
                        //bad limit hit
                        setShowLimit(true);
                        return;
                    }
                }
            }
        }


        if (localStorage) {
            localStorage.setItem("lastChat", uuid);
        }
        if (sessionStorage) {
            sessionStorage.setItem("lastChat", uuid);
        }
        if ((question === null) || (encodeURIComponent(question) === "undefined")) {
            return;
        }
        const requestOptions = {
            mode: 'no-cors',
            timeout: 200000,
        };
        let url = `https://researchdemo.agent.bam.money/free/${uuid}?question=${encodeURIComponent(question)}`;
        if (window.location.hostname == "bam.money") {
            url = `https://agent.bam.money/free/${uuid}?question=${encodeURIComponent(question)}`;
        }
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((json) => updateMessageHistory(json, uuid))
            .catch((e) => {
                updateMessageHistory("", uuid)
            });
    }

    function handleNewThread(question) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ question: question }),
        };
        fetch('/api/public/create-chat', requestOptions)
            .then(response => response.json())
            .then(uuid => handleQuestionResponse(question, uuid))
            .catch((error) => {
                setTmpQuestion(`Error code: ${error.status}. Sorry, our system seems to be malfunctioning. Please reload or contact us.`)
                return;
            });
    }

    function resetChat() {
        setTmpQuestion(null);
        setTmpResponse(null);
        setMessages(null);
        handleNewThread(null);
    }

    function TriggerPWA() {
        props.installPrompt.prompt();
        console.log("event: triggered PWA install");
    }

    const { id, question } = useParams();
    const homeChatSave = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        //save button
        if (props.installPrompt) {
            homeChatSave.current.removeAttribute("hidden");
        }

        // load history/question/chat
        if (isLikelyUUID(id) && question) {
            fetch(`/api/public/retrieve-chat?uuid=${id}`)
                .then((response) => response.json())
                .then((json) => setMessages(json.messages))
                .catch((error) => {
                    handleNewThread("");
                    return;
                });
            handleQuestionResponse(decodeURIComponent(question), id);
        } else {
            if (isLikelyUUID(id)) {
                getMessageHistory(id);
                return;
            }
            if (localStorage) {
                const localuuid = localStorage.getItem("lastChat");
                if (isLikelyUUID(localuuid)) {
                    navigate(`${pathPrefix}/${localuuid}`);
                    getMessageHistory(localuuid);
                    return;
                }
            }
            if (sessionStorage) {
                const sessionuuid = sessionStorage.getItem("lastChat");
                if (isLikelyUUID(sessionuuid)) {
                    navigate(`${pathPrefix}/${sessionuuid}`);
                    getMessageHistory(sessionuuid);
                    return;
                }
            }
            handleNewThread("");
        }
    }, []);

    const [input, setInput] = useState("");
    let selfSubmit = async (e) => {

        e.preventDefault();
        handleQuestionResponse(input, id);
        setInput("");
    }

    return (
        <>
            <div style={{ paddingBottom: "10px" }}>
                <PublicNavbar />
            </div>
            <div className="home-background-chat">
                <h1 className="home-h1 home-h1-t2"> <span className="orange">Alice</span> <span className="home-h1-t1">| Easily Access fAlpha's Insights</span><span className='beta'>(BETA)</span></h1>
                <div className="home-chat-register">
                    <button ref={homeChatSave} onClick={TriggerPWA} hidden>Save PWA</button>
                    <button onClick={resetChat}>Reset Chat</button>
                </div>
                <div className="home-chat-conversation">
                    {showLimit ? <AliceChatLimit /> : null}
                    {tmpResponse ? <AliceChatResponse text={tmpResponse} key={`response-${id}-${messages ? messages.length + 1 : "1"}`} /> : (tmpQuestion && !showLimit) ? <AliceChatLoading /> : null}
                    {tmpQuestion ? <AliceChatQuestion question={tmpQuestion} key={`question-${id}-${messages ? messages.length : "0"}`} /> : null}
                    {id && messages ? messages.map((message, index) => (
                        message.message.type === "human" ?
                            <AliceChatQuestion
                                question={message.message.data.content}
                                index={index}
                                key={`question-${id}-${index}`}
                            /> :
                            <AliceChatResponse
                                text={message.message.data.content}
                                uuid={id}
                                index={index}
                                key={`response-${id}-${index}`}
                            />
                    )).reverse() : null}
                    {(!messages?.length) && (!tmpQuestion) ? <h1>Get started below</h1> : null}
                </div>
                {/*<button onClick={handleNewThread} className="home-chat-reset-button" disabled>Reset</button>*/}
                <div className="home-chat-input-container">
                    <button id="home-chat-reset" onClick={resetChat}><img className="home-chat-reset-icon" src="/images/delete-trash.png" alt="reset chat" /></button>
                    <form id="home-chat-form">
                        <input id="home-chat-input" type="text" placeholder="Ask me about corporate credit trading..." value={input} onChange={(e) => setInput(e.target.value)} />
                        <button onClick={selfSubmit} id="home-chat-submit"><img className="home-chat-submit-icon" src="/images/submit-button.png" alt="submit question" /></button>
                    </form>
                </div>
            </div>
        </>
    );
}