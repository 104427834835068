import '../css/profile.css';
import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';

import { Typography, Box, Button, List, TextField, Paper, ListItemButton, Switch, InputLabel } from '@mui/material';
import { TableCell, TableRow, Table, TableHead, TableBody, TableContainer, Select, MenuItem, Grid } from '@mui/material';
import { Popper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatePicker } from '@mui/x-date-pickers';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';

import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { scroller, ScrollLink, Element } from 'react-scroll';

import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

import { PublicFooter, PublicNavbar, PublicTitle } from "../components/pagestyle";

import D3TrainingResult from '../d3Charts/d3TrainingResult';
import '../css/custom-data.css'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


/*
Help Steps:
    1. API URL
    2. Upload CSV
    3. Update Data
    4. Train Now
    5. View Result
    6. Delete Data
    7. Help Button
*/

function CustomData() {
    const [modelsData, setModelsData] = useState([]);
    const [presetsModelsData, setPresetsModelsData] = useState([]);
    const [selectedModel, setSelectedModel] = useState(modelsData[0]);
    const [isPreset, setIsPreset] = useState(false);
    const [fAlphaData, setfAlphaData] = useState({});
    const [apiurl, setApiurl] = useState("");
    const [openSetting, setOpenSetting] = useState(false);
    const [openPage, setOpenPage] = useState("data"); // data, settings, results

    const [showHelpFill, setShowHelpFill] = useState(false);
    const [helpFillState, setHelpFillState] = useState("");
    const [helpFillImage, setHelpFillImage] = useState("");
    const [helpFillText, setHelpFillText] = useState("");

    // const [currentHelp, setCurrentHelp] = useState(0); // 0 = off, otherwise it's the step
    // const [helpText, setHelpText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingState, setSettingState] = useState({ 'bam_columns': [], 'columns': [], 'date_column': '' })
    const [modelName, setModelName] = useState("");
    const [graphState, setGraphState] = useState({
        "is_open": false,
    });

    const handleOpenSettings = () => setOpenSetting(true);

    const handleOpenPage = (page) => {
        if (page === "results") {
            handleGetGraph();
        }
        setOpenPage(page);
    };

    const [uploadingData, setUploadingData] = useState(false);

    // const handleOpenHelp = () => {
    //     setCurrentHelp(1);
    // };

    // const nextHelp = () => {
    //     setCurrentHelp(currentHelp + 1);
    // }

    // const backHelp = () => {
    //     setCurrentHelp(currentHelp - 1);
    // }

    // // Function updates the help window based on the current help step
    // useEffect(() => {
    //     const helpStepRefs = [
    //         helpStepOneRef,
    //         helpStepTwoRef,
    //         helpStepThreeRef,
    //         helpStepFourRef,
    //         helpStepFiveRef,
    //         helpStepSixRef,
    //         helpStepSevenRef,
    //     ]
    //     const helpTexts = [
    //         "To enter in your custom data, please paste an API URL that contains the data that you would like to upload.",
    //         "Alternatively, you may enter in your custom data by uploading a CSV file where each row represents the data at a certain time.",
    //         "After uploading your data, please click this button to save your changes. This will show your data in the table below.",
    //         "After the data is finished uploading, click this button to train a model with the custom data you just uploaded.",
    //         "When training is completed, click this button to show the results of the training.",
    //         "If you would like to delete the data you uploaded, click this button.",
    //         "If you ever need help, click this button to show these instructions again."
    //     ]
    //     // setAnchorEl(helpStepRefs[1].current);
    //     if (currentHelp > 7) {
    //         setCurrentHelp(0);
    //     }
    //     else if (currentHelp > 0) {
    //         setAnchorEl(helpStepRefs[currentHelp - 1].current);
    //         setHelpText(helpTexts[currentHelp - 1]);
    //     }
    // }, [currentHelp])

    // useEffect(() => {
    //     if (!selectedModel || !selectedModel.custom_data) {

    //     }
    // }, [selectedModel.custom_data])

    useEffect(() => {
        const helpImages = {
            "": "",
            "ZERO": "/images/custom_data_help/fillzero.png",
            "LINEAR": "/images/custom_data_help/filllinear.png",
            "LOCF": "/images/custom_data_help/filllocf.png",
        }
        const helpTexts = {
            "": "Select a fill method above to see more information",
            "ZERO": "This method fills in gaps with 0. This may be useful for sporadic data, such as insider trading information.",
            "LINEAR": "This method fills in gaps with a linear interpolation. This may be useful for continuous data with gaps.",
            "LOCF": "This stands for Last Observation Carried Forward (LOCF). This may be useful for data such as financial ratios.",
        }

        setHelpFillImage(helpImages[helpFillState]);
        setHelpFillText(helpTexts[helpFillState]);
    }, [helpFillState])

    const fetchModelsData = async () => {
        try {
            const response = await fetch('/api/custom_model');
            if (response.ok) {
                const result = await response.json();

                setfAlphaData(result.bam_model);
                setModelsData(result.models);

                const storedModelId = localStorage.getItem('selectedModelId');
                console.log(storedModelId)
                if (storedModelId && !storedModelId.includes('preset')) {
                    const storedModel = result.models.find(model => model.id === storedModelId);
                    if (storedModel) {
                        setSelectedModel(storedModel);
                        setModelName(storedModel.model_name);
                        setApiurl(storedModel.api_url)
                        setSettingState({ 'bam_columns': storedModel.bam_columns, 'columns': storedModel.columns, 'date_column': storedModel.date_column })
                    }
                } else {
                    setSelectedModel(result.models[0]);
                    setModelName(result.models[0].model_name);
                    setApiurl(result.models[0].api_url)
                    setSettingState({ 'bam_columns': result.models[0].bam_columns, 'columns': result.models[0].columns, 'date_column': result.models[0].date_column })
                }

            } else {
                console.error('Error fetching models data:', response.statusText);
            }
            const response2 = await fetch('/api/custom_model/get_presets');
            if (response2.ok) {
                const result = await response2.json();
                setPresetsModelsData(result.models)
                console.log(result)
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    // useEffect(() => {
    //     const storedModelId = localStorage.getItem('selectedModelId');
    //     if (storedModelId) {
    //         const storedModel = modelsData.find(model => model.id === storedModelId);
    //         if (storedModel) {
    //             setSelectedModel(storedModel);
    //             setModelName(storedModel.model_name);
    //             setApiurl(storedModel.api_url)
    //             setSettingState({'bam_columns': storedModel.bam_columns, 'columns': storedModel.columns, 'date_column': storedModel.date_column})
    //         }
    //     }
    // }, [modelsData]);

    useEffect(() => {
        fetchModelsData();
    }, []);


    const handleModelSelect = (model_id) => {
        localStorage.setItem('selectedModelId', model_id);
        if (!model_id.includes('preset')) {
            const model = modelsData.find((item) => item.id == model_id)
            setSelectedModel(model)
            console.log(model.training_result)
            setApiurl(model.api_url)
            setModelName(model.model_name)
            setSettingState({ 'bam_columns': model.bam_columns, 'columns': model.columns, 'date_column': model.date_column })
            setIsPreset(false);

            setOpenPage("data");
            if (model.is_training || model.training_result.pnl) {
                setOpenPage("results");
            }
            return
        }

        const model = presetsModelsData.find((item) => `preset_${item.model_name}` == model_id)
        setSelectedModel({
            'id': model_id,
            'training_result': model ? model.training_result : {}
        })
        setIsPreset(true)
        setApiurl('')
        setModelName(model_id.substring(7));

        setSettingState({})
    };
    const handleModelCreate = async () => {
        try {
            const response = await fetch('/api/custom_model/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const result = await response.json();
                fetchModelsData();
                setOpenPage("data");
                // console.log(result.message);
            } else if (response.status == 401) {
                alert('Model limit reached.')
            } else {
                const errorData = await response.json();
                console.error('Error creating model:', errorData.error);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };
    const handleModelTrain = async () => {
        try {
            const response = await fetch('/api/custom_model/trigger_training', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model_id: selectedModel.id
                })
            });

            if (response.ok) {
                const result = await response.json();
                fetchModelsData();
                alert("Training submitted! An email will notify you once the training finished.")
                console.log(result.message);
            } else {
                const errorData = await response.json();
                console.error('Error creating model:', errorData.error);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
        setOpenPage("results")
    };
    const handleGetGraph = async () => {
        setGraphState({
            "is_open": true,
        })
    };
    const handleModelDelete = async () => {
        setOpenPage("data");
        try {
            const response = await fetch('/api/custom_model/delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model_id: selectedModel.id
                })
            });

            if (response.ok) {
                const result = await response.json();
                fetchModelsData();
                console.log(result.message);
            } else {
                const errorData = await response.json();
                console.error('Error creating model:', errorData.error);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
        setSelectedModel(null);
    };
    const handleModelUpdate = async () => {
        if (modelName.trim() === '') return
        try {
            setUploadingData(true);
            const response = await fetch('/api/custom_model/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model_id: selectedModel.id,
                    api_url: apiurl,
                    model_name: modelName
                })
            });

            if (response.ok) {
                const result = await response.json();
                fetchModelsData();
                setUploadingData(false);
                console.log(result.message);
            } else {
                const errorData = await response.json();
                setUploadingData(false);
                if (errorData.error == 'data invalid') {
                    alert("Data does not have a properly formatted 'date' field")
                }
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };
    const handleApplySetting = async () => {
        try {
            const response = await fetch('/api/custom_model/change_setting', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model_id: selectedModel.id,
                    setting: settingState
                })
            });
            if (response.ok) {
                const result = await response.json();
                fetchModelsData();
                setOpenSetting(false);
            } else {
                alert("Date column is not valid")
                setOpenSetting(true);
            }
        } catch (error) {
            console.error('Request failed:', error);
            setOpenSetting(false);
        }
    }
    const handleShowHelpFill = () => {
        setShowHelpFill(true);
    }

    const handleCSVUpload = (event) => {
        const selectedFile = event.target.files[0];

        const formData = new FormData();
        formData.append('model_id', selectedModel.id);
        formData.append('csvFile', selectedFile);

        fetch('/api/custom_model/upload_csv', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.error == 'data invalid') {
                    alert("Data does not have a properly formatted 'date' column")
                } else {
                    fetchModelsData()
                }
            })
            .catch(error => {
                console.error('Error uploading file', error);
            });
    };

    const changeTrainingPeriod = async (date, type) => {
        try {
            const response = await fetch('/api/custom_model/change_training_period', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model_id: selectedModel.id,
                    type: type,
                    date: date
                })
            });
            if (response.ok) {
                const result = await response.json();
                setOpenSetting(false);
            } else {
                alert("Date column is not valid")
                setOpenSetting(true);
            }
        } catch (error) {
            console.error('Request failed:', error);
            setOpenSetting(false);
        }
    };

    const bam_column_map = {
        avg_ss: "Average Sentiment Score",
        open_price: "Open Price",
        index_price: "Index Price",
        volume: "Volume",
        ros: "Return On Sales (ROS)",
        irc: "IRC",
        lts: "LTS",
        market_cap: "Market Cap"
    };
    return (
        <>

            {/* <Modal
                open={currentHelp !== 0}
                onClose={() => setOpenSetting(0)}
            >
                <Paper>
                    hi
                </Paper>
            </Modal> */}
            {/* <Modal
                open={graphState.is_open}
                onClose={() => setGraphState({
                    'is_open': false
                })}

            >
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    height: '320px',
                    width: '80%',
                    overflowY: 'auto'
                }} >
                    {selectedModel && selectedModel['training_result'] ?
                        <>
                            <Box style={{width: '100%', height: '100%', display: 'flex'}}>
                                <D3TrainingResult custom_pnl={selectedModel['training_result']['pnl'][14][0]['pnl']} bam_pnl={fAlphaData['pnl'][14][0]['pnl']}/>
                            </Box>
                        </>
                        : <>
                            No training result yet. Please train the model or wait until the training finished
                        </>
                    }
                </Paper>
            </Modal> */}

            <Box style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 165px)', boxSizing: 'border-box' }}>
                <Paper style={{ flex: 1, marginRight: '10px', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                    <List>
                        {<ListItemButton
                            key={`preset_fAlpha`}
                            selected={selectedModel && selectedModel.id === `preset_fAlpha`}
                            onClick={() => handleModelSelect(`preset_fAlpha`)}
                        >
                            fAlpha
                        </ListItemButton>
                        }
                        {presetsModelsData.map((model) => (
                            model.model_name !== "fAlpha" ?
                            <ListItemButton
                                key={`preset_${model.model_name}`}
                                selected={selectedModel && selectedModel.id === `preset_${model.model_name}`}
                                onClick={() => handleModelSelect(`preset_${model.model_name}`)}
                            >
                                {model.model_name}
                            </ListItemButton>
                            : <></>
                        ))}
                    </List>
                    <Button variant="contained" color="primary" onClick={() => handleModelCreate()} style={{ height: '50px' }}>
                        <div className="circle">1</div>
                        Create New Model
                    </Button>
                    <List>
                        {modelsData.map((model) => (
                            <ListItemButton
                                key={model.id}
                                selected={selectedModel && selectedModel.id === model.id}
                                onClick={() => handleModelSelect(model.id)}
                            >
                                {model.model_name}
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
                <Paper style={{ flex: 1, marginRight: '10px', padding: '10px', display: 'flex', flexDirection: 'column', minWidth: '200px' }}>
                    {selectedModel ?
                        (<>
                            <TextField
                                style={{ margin: '10px' }}
                                id="model-name-input"
                                variant="outlined"
                                value={modelName}
                                disabled={isPreset}
                                onChange={(event) => setModelName(event.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        handleModelUpdate();
                                    }
                                }}
                            />
                            {!isPreset ?
                                (<>
                                    <Button variant="contained" color="primary" onClick={() => handleOpenPage("settings")} style={{ height: '50px', margin: '10px' }}
                                        disabled={(!selectedModel.custom_data || selectedModel.custom_data.length === 0) || selectedModel.is_training || selectedModel.training_result.pnl}
                                    >
                                        <div className="circle">3</div>Edit Settings
                                    </Button>
                                    <Button variant="contained" color="warning" onClick={handleModelTrain} style={{ height: '50px', margin: '10px' }}
                                        disabled={((('is_training' in selectedModel) && selectedModel['is_training'])) || (!selectedModel.custom_data || selectedModel.custom_data.length === 0) || selectedModel.training_result.pnl}
                                    >
                                        {(('is_training' in selectedModel) && selectedModel['is_training']) ?
                                            <><div className="circle">4</div> Training...</>
                                            :
                                            <><div className="circle">4</div> Train Now</>
                                        }
                                    </Button>

                                    <Button variant="contained" color="primary" onClick={() => handleOpenPage("results")} style={{ height: '50px', margin: '10px' }}
                                        disabled={(!selectedModel.custom_data || selectedModel.custom_data.length === 0)}
                                    >
                                        <div className="circle">5</div>View Result
                                    </Button>
                                    <Button variant="contained" color="error" onClick={handleModelDelete} style={{ height: '50px', margin: '10px', marginTop: 'auto' }}>
                                        Delete Model
                                    </Button>
                                </>) : (<></>)
                            }
                        </>)
                        : (
                            <></>
                        )}
                </Paper>
                <Paper style={{ flex: 5, paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px', display: 'flex', flexDirection: 'column', overflowX: 'scroll' }}>
                    {!isPreset && selectedModel && openPage === "data" ? (
                        <>
                            <Box style={{ marginBottom: '50px', display: 'flex' }}>
                                <TextField
                                    style={{ flex: 7, margin: '10px' }} id="outlined-basic"
                                    label={<Box><div className="circleGray">2</div> API URL</Box>}
                                    variant="outlined"
                                    value={apiurl}
                                    onChange={(event) => { setApiurl(event.target.value) }} />
                                <Box style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    or
                                </Box>
                                <Button component="label" variant="contained" style={{ width: '165px', margin: '10px' }}>
                                    <VisuallyHiddenInput type="file" accept=".csv" onClick={(event) => { event.target.value = null }} onChange={handleCSVUpload} />
                                    <div className="circle">2</div>Upload csv
                                </Button>

                                {/* <Popper open={currentHelp !== 0} anchorEl={anchorEl} modifiers={[{
                                name: 'arrow',
                                enabled: true,
                                options: {
                                    element: '.arrow, .reverse-arrow',
                                }
                            }]}>
                                { currentHelp !== 7 ?
                                    <span className="arrow" /> : <></>
                                }
                                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }} style={{ marginTop: '10px', marginBottom: '0px', maxWidth: '300px'}}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex" justifyContent="flex-start">
                                            { helpText }
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" pl={1}>
                                            <ClearIcon onClick={() => setCurrentHelp(0)} style = {{cursor: "pointer"}}/>
                                        </Box>
                                    </Box>

                                    <Box display="flex" justifyContent="space-between" pt={2}>
                                        <Box display="flex" justifyContent="flex-start" style={{ marginTop: '9px' }}>
                                            { currentHelp } of { totalStepAmount }
                                        </Box>


                                        <Box display="flex" justifyContent="flex-end">
                                            { currentHelp !== 1 ?
                                            <Button onClick={backHelp} className="back-next-button">
                                                Back
                                            </Button>
                                            : <></> }
                                            <Button onClick={nextHelp} className="back-next-button">
                                                { currentHelp === 7 ? "Done" : "Next"}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                { currentHelp === 7 ?
                                    <span className="reverse-arrow" /> : <></>
                                }

                            </Popper> */}
                                <Button variant="contained" color="primary" onClick={handleModelUpdate} style={{ width: '140px', margin: '10px' }}>
                                    Update Data
                                </Button>
                            </Box>
                            {uploadingData ? // CREATE LOADING ANIMATION
                                <Box>
                                    uploading data...
                                </Box>
                                : <></>
                            }
                            {/* { (selectedModel.custom_data && selectedModel.custom_data.length !== 0) ?
                        <Box>
                            {(() => {
                                try {
                                    if (!selectedModel.date_range[0]) {
                                        throw new Error("Invalid date");
                                    }
                                    let startDateObj = new Date(selectedModel.date_range[0]);
                                    let endDateObj = new Date(selectedModel.date_range[1]);
                                    // console.log(startDateObj)
                                    // console.log(endDateObj)

                                    let start_val = startDateObj.toLocaleDateString("en-US");
                                    if (selectedModel.training_period && selectedModel.training_period.start) {
                                        start_val = selectedModel.training_period.start
                                    }
                                    let end_val = endDateObj.toLocaleDateString("en-US");
                                    if (selectedModel.training_period && selectedModel.training_period.end) {
                                        end_val = selectedModel.training_period.end
                                    }
                                    // console.log(start_val)
                                    // console.log(end_val)
                                    return <>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box style={{display:'flex'}}>
                                                <Box style={{padding:"20px"}}>
                                                    Select training period:
                                                </Box>
                                                <DatePicker
                                                    minDate={dayjs(startDateObj.toLocaleDateString("en-US"))}
                                                    maxDate={dayjs(endDateObj.toLocaleDateString("en-US"))}
                                                    value={dayjs(start_val)}
                                                    onChange={(value) => changeTrainingPeriod(value, 'start')}
                                                />
                                                <Box style={{padding:"20px"}}>
                                                    to
                                                </Box>
                                                <DatePicker
                                                    minDate={dayjs(startDateObj.toLocaleDateString("en-US"))}
                                                    maxDate={dayjs(endDateObj.toLocaleDateString("en-US"))}
                                                    value={dayjs(end_val)}
                                                    onChange={(value) => changeTrainingPeriod(value, 'end')}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </>;
                                } catch (error) {
                                    return `Date column is invalid. Please set the date column.`;
                                }
                            })()}
                        </Box>
                        : <></> } */}

                            {(selectedModel.custom_data && selectedModel.custom_data.length !== 0) ?
                                <Paper style={{ display: 'flex', padding: 10, backgroundColor: '#d3d3d3' }}>
                                    <TableContainer component={Paper} style={{ marginBottom: '5px', height: "53vh", flex: 1, zIndex: 0, overflowX: 'scroll' }}>
                                        <Table stickyHeader aria-label="simple table">
                                            {selectedModel.custom_data && selectedModel.custom_data.length != 0 &&
                                                <>
                                                    <TableHead>
                                                        <TableRow>
                                                            {Object.keys(selectedModel.custom_data[0]).map((header) => (
                                                                <TableCell key={header}>{header}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {selectedModel.custom_data.map((row, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                {Object.values(row).map((value, innerIndex) => (
                                                                    <TableCell key={innerIndex}>{value}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </>
                                            }
                                        </Table>
                                    </TableContainer>

                                    {/* <FormControl style={{padding: 20, width: 200, textAlign:'left'}}>
                                <FormLabel>Type</FormLabel>
                                <RadioGroup
                                    value={"global"}
                                >
                                    <FormControlLabel value="dnspec" control={<Radio />} label="Debtor Specific" />
                                    <FormControlLabel value="global" control={<Radio />} label="Global" />
                                </RadioGroup>
                            </FormControl> */}

                                </Paper>
                                : <></>}

                        </>
                    ) : <></>}
                    {!isPreset && selectedModel && openPage === "settings" ?
                        (<>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Custom Model Settings
                                </Typography>
                                <CloseIcon onClick={() => setOpenPage("data")} />
                            </Box>

                            <Paper style={{ display: 'flex', flexDirection: 'row', maxHeight: '61vh', overflowY: 'auto' }}>
                                <Box style={{ flex: 1, padding: '20px' }}>
                                    <Typography variant="h6" gutterBottom textAlign="center">
                                        Set date column
                                    </Typography>
                                    <Select
                                        value={settingState['date_column']}
                                        onChange={(event) => {
                                            const updatedState = { ...settingState };
                                            updatedState['date_column'] = event.target.value;
                                            let index = -1;
                                            for (let i = 0; i < updatedState['columns'].length; i++) {
                                                if (updatedState['columns'][i]['name'] === event.target.value) {
                                                    index = i;
                                                    break;
                                                }
                                            }
                                            updatedState['columns'][index].enabled = false;
                                            setSettingState(updatedState);
                                        }}
                                        MenuProps={{ disableScrollLock: true }}
                                        style={{ minWidth: 150 }}
                                        displayEmpty
                                    >
                                        {settingState['columns'].map((item, index) => (
                                            <MenuItem
                                                value={item.name}
                                            >{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box style={{ flex: 2, padding: '20px', width: '300px' }}>
                                    <Typography variant="h6" gutterBottom textAlign="center">
                                        Enable custom columns and select fill method
                                        <IconButton onClick={() => handleShowHelpFill()} aria-label="help">
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Typography>
                                    {<Modal
                                        open={showHelpFill}
                                        onClose={() => setShowHelpFill(false)}>
                                        <Paper sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            padding: '20px',
                                            height: '500px',
                                            width: '900px',
                                            overflowY: 'auto'
                                        }}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography variant="h4" gutterBottom>
                                                    Fill Data Help
                                                </Typography>
                                                <CloseIcon onClick={() => setShowHelpFill(false)} />
                                            </Box>
                                            <Box>
                                                <Typography variant="h8">
                                                    This allows you to customize the method of filling in any gaps in the given data. Select the methods below for more information.
                                                </Typography>
                                            </Box>
                                            <Box style={{ display: 'flex', flexDirection: 'row', padding: '30px' }}>
                                                <Box style={{ width: '35%', padding: '20px' }}>
                                                    <Select
                                                        value={helpFillState}
                                                        onChange={(event) => {
                                                            setHelpFillState(event.target.value);
                                                        }}
                                                        style={{ minWidth: 150, marginBottom: 20 }}
                                                        displayEmpty
                                                        MenuProps={{ disableScrollLock: true }}
                                                    >
                                                        <MenuItem value="ZERO">Zero</MenuItem>
                                                        <MenuItem value="LINEAR">Linear</MenuItem>
                                                        <MenuItem value="LOCF">LOCF</MenuItem>
                                                    </Select>
                                                    <Typography gutterBottom>
                                                        {helpFillText}
                                                    </Typography>
                                                </Box>
                                                <Box style={{ width: '65%', padding: '10px' }}>
                                                    <img src={helpFillImage} style={{ width: '90%' }} />
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Modal>}

                                    {settingState['columns'].map((item, index) => (
                                        ('date_column' in settingState && item.name !== settingState['date_column']) ?
                                            (<Grid container key={index} direction="row" justifyContent="flex-start" alignItems="center" style={{ marginBottom: '10px', textAlign: 'left' }}>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={item.enabled}
                                                                onChange={(e) => {
                                                                    const updatedState = { ...settingState };
                                                                    updatedState['columns'][index].enabled = e.target.checked;
                                                                    setSettingState(updatedState);
                                                                }}
                                                            />
                                                        }
                                                        label={item.name}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        value={settingState['columns'][index].fill_method}
                                                        onChange={(event) => {
                                                            const updatedState = { ...settingState };
                                                            updatedState['columns'][index].fill_method = event.target.value;
                                                            setSettingState(updatedState);
                                                        }}
                                                        style={{ minWidth: 160 }}
                                                        displayEmpty
                                                        MenuProps={{ disableScrollLock: true }}
                                                    >
                                                        <MenuItem value="ZERO">Zeroes</MenuItem>
                                                        <MenuItem value="LINEAR">Linear</MenuItem>
                                                        <MenuItem value="LOCF">LOCF</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>) : (
                                                <></>
                                            )
                                    ))}
                                </Box>
                                <Box style={{ flex: 2, padding: '20px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Enable fAlpha columns
                                    </Typography>
                                    {settingState['bam_columns'].map((item, index) => (
                                        <Grid container key={index} direction="row" justifyContent="flex-start" alignItems="center" style={{ marginBottom: '10px', textAlign: 'left' }}>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={item.enabled}
                                                            onChange={(e) => {
                                                                const updatedState = { ...settingState };
                                                                updatedState['bam_columns'][index].enabled = e.target.checked;
                                                                setSettingState(updatedState);
                                                            }}
                                                        />
                                                    }
                                                    label={bam_column_map[item.name]}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </Paper>
                            <Button variant='contained' onClick={handleApplySetting} style={{ width: '400px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                                Apply Settings
                            </Button>
                        </>
                        ) : (
                            <></>
                        )
                    }
                    {isPreset || selectedModel && openPage === "results" ?
                        <>
                            {selectedModel.id != 'preset_fAlpha' ?
                                ((selectedModel && selectedModel.training_result && selectedModel.training_result.pnl) ?
                                    <Box style={{ width: '100%', height: '100%', display: 'flex' }}>
                                        <D3TrainingResult model_name={modelName} custom_pnl={selectedModel['training_result']['pnl'][0]['pnl']} bam_pnl={fAlphaData['pnl'][0]['pnl']}
                                            iis={(selectedModel['training_result']['iis']['iis15'] + selectedModel['training_result']['iis']['iis5']) / 2} />
                                    </Box> : <>
                                        No training results yet. Please train the model and wait until the training is finished.
                                    </>
                                ) : <D3TrainingResult onlyfAlpha bam_pnl={fAlphaData['pnl'][0]['pnl']} iis={0.0} />
                            }
                        </> : <></>
                    }
                </Paper>
            </Box>

        </>
    )
}

const PlanCard = styled(Paper)(({ theme }) => ({
    height: 350,
    minWidth: 200,
    padding: 30,
    width: "20%",
    textAlign: 'center',
    alignItems: 'center',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...theme
}));

export default function Subscription() {
    window.document.title = "fAlpha.ai | Custom Data";

    const [firstTimeCustom, setFirstTimeCustom] = useState(null);
    const [showFirstTimeSection, setShowFirstTimeSection] = useState(null);

    const [helpOpen, setHelpOpen] = useState(false);
    const [helpSlider, setHelpSlider] = useState(0);
    const [helpStep, setHelpStep] = useState(0);
    const [helpImage, setHelpImage] = useState("");
    const [helpText, setHelpText] = useState("");

    const totalStepAmount = 5;

    useEffect(() => {
        if (!helpOpen) {
            setHelpSlider(1);
        }
        const helpImages = [
            "/images/custom_data_help/step1.png",
            "/images/custom_data_help/step2.png",
            "/images/custom_data_help/step3.png",
            "/images/custom_data_help/step4.png",
            "/images/custom_data_help/step5.png"
        ]
        const helpTexts = [
            "To begin, click the \"Create Model\" button on the left side. This will create a new model for you.",
            "To enter in your custom data, either paste an API URL or upload a CSV that contains the data that you would like to upload.",
            "Now, please click the Settings button. Select which column represents the date. Optionally, choose to enable or disable various other columns and customize the data imputation method.",
            "Click \"Apply Settings\". Click Train.",
            "When training is completed, click the \"View Results\" button to show the results of the training."
        ]

        setHelpImage(helpImages[helpSlider - 1]);
        setHelpText(helpTexts[helpSlider - 1]);
    }, [helpSlider, helpOpen])

    const firstTime = async () => {
        try {
            const response = await fetch('/api/account/first-time-custom', {
                method: 'GET'
            });

            if (response.ok) {
                const result = await response.json();
                setFirstTimeCustom(result.firstTimeCustom);
                setShowFirstTimeSection(result.firstTimeCustom); // Show first-time section if the user is first time
                console.log(result);
            } else {
                const errorData = await response.json();
                console.error('Error getting var:', errorData.error);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    const oldUser = async () => {
        try {
            const response = await fetch('/api/account/old-user-custom', {
                method: 'POST'
            });

            if (response.ok) {
                const result = await response.json();
                setFirstTimeCustom(false);
            } else {
                const errorData = await response.json();
                console.error('Error getting var:', errorData.error);
            }
        } catch (error) {
            console.error('Request failed:', error);
        }
    };

    useEffect(() => {
        firstTime();

        return () => {
            oldUser();
        };
    }, []);

    const handleGetStarted = () => {
        setShowFirstTimeSection(false); // Hide the first-time section
    };

    const handleBack = () => {
        setShowFirstTimeSection(true); // Show the first-time section
    };

    // Only render the component once the initial state is determined
    if (showFirstTimeSection === null) {
        return null; // or a loading indicator
    }

    return (
        <>
            <PublicNavbar forceRedirectIfNoAccount={true} forceRedirectIfSubscriptionNotActive={true} />
            <PublicTitle
                title="Custom Data (BETA)"
                help={<IconButton onClick={() => setShowFirstTimeSection(true)}>
                    <HelpOutlineIcon style={{ fontSize: 35 }}/>
                </IconButton>}
            />
            {/* {showFirstTimeSection ? (
                <div>
                    <h1>A 3 Step Process to Uncover Hidden Alpha in Your Data</h1>
                    <button className="button" onClick={handleGetStarted}>Get Started</button>
                    <div className="container" style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "40px", gap: "10px" }}>
                        <PlanCard>
                            <h2>1</h2>
                            <h3>Upload Custom Time Series Data</h3>
                            <p>Our custom data engine allows you to upload whatever data you want, so long as it has some dates attached to it. We will add it to our proprietary AI system, using a LSTM model to determine whether your data can deliver you alpha.</p>
                        </PlanCard>
                        <PlanCard>
                            <h2>2</h2>
                            <h3>See its Value, Privately and Securely</h3>
                            <p>We won’t share your results with anyone, or allow anyone else to use your data. We will inform you whether or not your data has been informative and useful, and show you exactly how effective it is.</p>
                        </PlanCard>
                        <PlanCard>
                            <h2>3</h2>
                            <h3>Make Informed Investment Decisions</h3>
                            <p>You can add the models you develop with your custom data to your TradingPulse or experiment with as many different datasets as you want. We will keep securely hold your data and models to use when you need.</p>
                        </PlanCard>
                    </div>
                </div>
            ) : (
                <div>
                    <CustomData />
                    <button className="button back-button" onClick={handleBack} style={{float: "right"}}>Tutorial</button>
                </div>
            )} */}
            <Modal
                open={showFirstTimeSection}
                onClose={() => setShowFirstTimeSection(false)}>
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    height: '600px',
                    width: '900px',
                    overflowY: 'auto'
                }}>
                    <Paper style={{ display: 'flex', height: '510px', overflowY: 'auto', padding: '20px' }}>
                        <Grid
                            container
                            rowSpacing={0}
                            columnSpacing={2}
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h1" justifyContent="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>1</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h1" justifyContent="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>2</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h1" justifyContent="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>3</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h4" align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>Upload Custom Time Series Data</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h4" align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>See its Value, Privately and Securely</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant="h4" align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700 }}>Make Informed Investment Decisions</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 400 }}>Our custom data engine allows you to upload whatever data you want, so long as it has some dates attached to it. We will add it our proprietary AI system, using a LSTM model to determin whether your data can deliver you alpha.</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 400 }}>We won't share your results with anyone, or allow anyone else to use your data. We will inform you whether or not your data has been informative and useful, and show you exactly how effective it is.</Typography>
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography align="center" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 400 }}>You can add the models you develop with your custom data to your TradingPulse or experiment with as many different datasets as you want.  We will keep securely hold your data and models to use when you need.</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Box display="flex" style={{ marginTop: 'auto', justifyContent: "center" }}>
                        <Button variant='contained' onClick={() => {setShowFirstTimeSection(false); setHelpOpen(true)}} style={{ width: '300px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                            See How it Works
                        </Button>
                    </Box>
                </Paper>
            </Modal>

            <Modal
                open={helpOpen}
                onClose={() => setHelpOpen(false)}>
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    height: '600px',
                    width: '900px',
                    overflowY: 'auto'
                }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h4" style={{ fontFamily: 'Inter, sans serif', fontWeight: 700 }}>
                            Custom Data Help
                        </Typography>
                        <CloseIcon onClick={() => setHelpOpen(false)} />
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                        <img src={helpImage} style={{ width: '75%' }} />
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                        <Typography gutterBottom>
                            {helpText}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', width: '850px', position: 'absolute', bottom: '25px' }}>
                            <Typography>
                                Step {helpSlider} of {totalStepAmount}
                            </Typography>
                            <Slider
                                defaultValue={1}
                                getAriaValueText={(value) => value}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={1}
                                marks
                                min={1}
                                max={totalStepAmount}
                                onChange={(event, newValue) => setHelpSlider(newValue)}
                            />
                        </Box>
                    </Box>

                </Paper>
            </Modal>
            <CustomData />
            <PublicFooter />
        </>
    );
}
