//imports
import React, { useState, useEffect, useRef } from 'react';
import { Add, Remove } from '@mui/icons-material';
import '../css/internal.css';

import { Typography, Container, Box, Button, ButtonGroup, List, TextField, Paper, Slider, ListItemButton, Switch, IconButton } from '@mui/material';
import { TableCell, TableRow, Table, TableHead, TableBody, TableContainer, Select, MenuItem, FormControl, InputLabel, } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useGoogleLogin, googleLogout, TokenResponse, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import Papa from 'papaparse';
import { sp500chart } from './image'; //fAlpha chart component

const CLIENT_ID = "175931625097-smg92nbv00mv708savd7bu13d1aaoogr.apps.googleusercontent.com";
const API_KEY = "AIzaSyCa7zM7G-3QJP5CiM9tPbHSd-UyYZzvOZw";
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';
const SCOPES = 'https://www.googleapis.com/auth/gmail.compose';


export function ChartGen() {
    return (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            <ChartGenInside />
        </GoogleOAuthProvider>
    )
}

//inner component for chart gen
function ChartGenInside() {

    //state variables
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(localStorage.getItem("bam-money-internal-name") ? localStorage.getItem("bam-money-internal-name") : "");
    const [email, setEmail] = useState(localStorage.getItem("bam-money-internal-email") ? localStorage.getItem("bam-money-internal-email") : "");
    const [linkedin, setLinkedin] = useState(localStorage.getItem("bam-money-internal-linkedin") ? localStorage.getItem("bam-money-internal-linkedin") : "");
    
    //recipient info variable names
    var rfname = ""
    var rlname = ""
    var rtitle = ""
    var rfirm = ""
    var remail = ""

    //state variables for token + file data 
    const [tokenResponse, setTokenResponse] = useState(null);
    const [fileData, setFileData] = useState([]);
    const [template, setTemplate] = useState("General");

    const [user, setUser] = useState(null);
    const [log, setLog] = useState([]);
    const logTmp = []

    //function for downloading the csv with analysts + tickers
    async function downloadCSV() {
        if (fileData.length === 0) {
            alert("No data to export!!!!  :( ");
            return;
        }
    
        // Array to hold processed data with tickers
        const processedData = [];
    
        // Loop through fileData to fetch tickers for each row
        for (let i = 0; i < fileData.length; i++) {
            const row = fileData[i];
            try {
                const response = await fetch(`/api/analyst/get_top_tickers_public?name=${row.first_name} ${row.last_name}`);
                const responseData = await response.json();
                const tickers = responseData.tickers.filter((t) => t);  // Filter out empty values
    
                // Add tickers to  row
                processedData.push({
                    email: row.email,
                    company: row.company,
                    title: row.title,
                    first_name: row.first_name,
                    last_name: row.last_name,
                    industry: row.industry,
                    tickers: tickers.join(', '), // combine tickers
                });
    
            } catch (error) {
                console.error(`Error fetching tickers for ${row.first_name} ${row.last_name}:`, error);
            }
        }
    
        // Use PapaParse to convert processed data to CSV
        const csv = Papa.unparse(processedData);
    
        // make  Blob from the CSV string
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    
        // Create  link element
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "tickersData.csv");
        link.style.visibility = 'hidden';
    
        //append link to  body and trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    
    //function to add messages to log 
    function addToLog(message) {
        const now = new Date();
        const time = now.toTimeString().split(' ')[0];
        const fixedDistance = 15;
        const spaces = ' '.repeat(fixedDistance - time.length);
        logTmp.unshift(`${time}${spaces}${message}`);
        setLog([...logTmp])
    }

    //function to handle file change + parse csv
    function handleFileChange(event) {
        if (!event.target.files[0] || event.target.files[0].type != "text/csv") {
            alert("file must be csv type")
            addToLog("file must be csv type")
            return;
        }
        const reader = new FileReader();
        reader.onload = function (e) {
            const csvData = e.target.result;

            Papa.parse(csvData, {
                header: true,
                dynamicTyping: true,
                complete: function (results) {
                    const jsonData = results.data;

                    const fdata = jsonData.map((row) => {
                        try {
                            row.top_iis_holdings = row.top_iis_holdings.replace("\\", "")
                            console.log(row)
                            return row;
                        } catch {
                            return row;
                        }

                    })

                    setFileData(fdata)
                    addToLog("CSV File successfully added")
                },
                error: function (error) {
                    setFileData([])
                    alert("Error parsing CSV file: " + error.message);
                    addToLog("Error parsing CSV file: " + error.message);
                }
            });
        };
        reader.readAsText(event.target.files[0]);
    }

    //effect to save user info in local storage
    useEffect(() => {
        localStorage.setItem("bam-money-internal-name", name)
        localStorage.setItem("bam-money-internal-email", email)
        localStorage.setItem("bam-money-internal-linkedin", linkedin)
    }, [name, email, linkedin])
    
    //funct to submit data + fetch start
    async function submitData(_data_) {
        if (_data_.hasOwnProperty("")) {
            delete _data_[""]
        }
        var sum = Object.values(_data_).reduce((acc, value) => acc + value, 0);
        if (sum < .99 || sum > 1.01) {
            console.log("weights must sum to roughly 1");
            return null;
        }
        return await fetch(`https://researchdemo.agent.bam.money/chart?symbols=${encodeURIComponent(JSON.stringify(_data_))}`)
            .then((response) => response.json())
            .then((json) => { return json; })
            .catch((e) => {
                addToLog(`error retrieving chart data: ${e}`);
                return null;
            });
    }

    //funct to get email template for generalchart data 
    function getTemplateGeneral(chartData, data, image_src) {
        var msg = "could have performed better at least in terms of risk and peace of mind with our advanced data engine"
        if (data.raw_performance > 0) {
            msg = "performed well, there's potential for even better returns and/or peace of mind with our advanced data engine"
        } else if (data.raw_performance > -.05) {
            msg = "performed okay, there's however potential for better returns and peace of mind with our advanced data engine"
        }

        const sourceEmailParam = (remail == "" ? "" : `?source_email=${encodeURIComponent(remail)}`);
        
        return `<p>Hi ${rfname}, </p>

                <p>I'm ${name.split(" ")[0]}, Co-founder of <a href="https://bam.money/landing${sourceEmailParam}">fAlpha.ai</a>. fAlpha reviewed your fund's recent 13F filings and noticed that while your top holdings in ${Object.keys(chartData).join(", ")} ${msg}.</p>
                <p>At fAlpha.ai, we provide discretionary funds like yours with actionable insights by analyzing a multitude of scenarios. By utilizing our engine, you could have more effectively supported your trading positions with the same corporate exposures, achieving an additional PnL of <strong>${Math.round(data.pnl * 100000) / 1000}%</strong> over the last quarter, with a worst trough difference of only <strong>${Math.round(data.worst_trough * 100000) / 1000}%</strong>.(see chart below)</p>
                <a href="https://bam.money/landing${sourceEmailParam}"><img src="${image_src}" alt="" width="300px" /></a>
                <p>Can we schedule a 15-minute call this week to discuss how our engine can enhance your fund's performance?</p>
                <p>If you're not the right person to discuss this with, could you please point me to the appropriate individual in your firm who handles these decisions?</p>
                <p>Best Regards,</p>
                <a href="${linkedin}"><p>${name}</p></a>
                <p>CEO, Co-founder, <a href="https://bam.money/landing${sourceEmailParam}">fAlpha.ai</a></p>`
    }

    //funct to get email template for V400
    function getTemplateV400(chartData, data, image_src) {

        const sourceEmailParam = (remail == "" ? "" : `?source_email=${encodeURIComponent(remail)}`);
        
        return `<p>Hi ${rfname}, </p>
                <p>After reviewing your fund's recent 13F filings, we noticed opportunities to reduce risk and potentially enhance returns on your top holdings on ${Object.keys(chartData).join(", ")}.</p>
                <p>Our analysis indicates you could have achieved an additional PnL of <strong>${Math.round(data.pnl * 100000) / 1000}%</strong> last quarter while minimizing drawdowns with a worst trough difference of only <strong>${Math.round(data.worst_trough * 100000) / 1000}%</strong>.(see below)</p>
                <a href="https://bam.money/landing${sourceEmailParam}"><img src="${image_src}" alt="" width="300px" /></a> 
                <p>I'm ${name.split(" ")[0]} ${name.split(" ")[1]}, CEO of <a href="https://bam.money/landing${sourceEmailParam}">fAlpha.ai</a>. After 20 yrs leading market and investment risk at the firms like GS and Barcap, I co-founded fAlpha to bring to firms like yours, the most effective trading risk indicators.
                I'd love to schedule a 15-minute call to show how our engine can boost your fund's performance, no strings attached. When would be a convenient time for you?</p>
                <p>Best Regards,</p>
                <a href="${linkedin}"><p>${name}</p></a>
                <p>CEO, Co-founder, <a href="https://bam.money/landing${sourceEmailParam}">fAlpha.ai</a></p>
                <p>PS.<br>1-If you're not the right person to discuss this with, please point me to the appropriate individual in your organization.
                <br>2- fAlpha.ai provides discretionary funds with advanced insights by combining alternative and traditional risk data.</p>`

    }
    //function to grt email template for analysts
    function getTemplateAnalyst(tickers, large, image_src, version) {
        const sourceEmailParam = (remail == "" ? "" : `?source_email=${encodeURIComponent(remail)}`);
        // const msg = large ? "fAlpha.ai provides advanced insights and scenario analysis, covering over 5,000 corporate names and ETFs with alternative and traditional data. Our engine, when combined with your analysis, can improve your call accuracy and timeliness, offering complementary predictions with confidence levels."
        //     : "fAlpha.ai provides advanced insights and scenario analysis, covering over 5,000 corporate names and ETFs with alternative and traditional data. Our engine, when combined with your analysis, can improve your call accuracy and timeliness, offering complementary predictions with confidence levels. We are so confident that we can extend a free trial of our tool and offer 1-hour of top-notch data science support and customization to you.";
        let msg = "";
        if (version === "Analyst (Large git clone https://github.com/techbammoney/fAlpha-crawlers.gitvFirm) V1") {
            msg = "fAlpha.ai is designed to empower analysts at larger firms by providing advanced insights and scenario analysis across over 5,000 corporate names and ETFs. Our engine leverages both alternative and traditional data sources to enhance the accuracy and timeliness of your calls. Importantly, fAlpha is meant to complement your expertise, not replace it.";
        } else if (large) {
            msg = "fAlpha.ai provides advanced insights and scenario analysis, covering over 5,000 corporate names and ETFs with alternative and traditional data. Our engine, when combined with your analysis, can improve your call accuracy and timeliness, offering complementary predictions with confidence levels.";
        } 
        else if(version === "Analyst (Large Firm) V2") {
            msg = `fAlpha.ai offers in-depth insights and scenario analysis for over 5,000 corporate names and ETFs. Our platform combines alternative and traditional data to deliver highly accurate, timely predictions that complement your expertise without replacing it.`
        }
        else if(version === "Analyst (Large Firm) V3") {
            msg = `fAlpha.ai is a powerful platform designed to empower analysts at larger firms by providing advanced insights and scenario analysis across over 5,000 corporate names and ETFs. By integrating alternative and traditional data sources, our tool provides predictions that are both timely and accurate. Importantly, fAlpha is meant to complement your expertise, not replace it.`
        }
        if (version === "Analyst (Large Firm) V4") {
            msg = "fAlpha.ai is a platform designed to empower analysts by providing unique insights and scenario analysis across over 6,000 corporate names and ETFs. By quickly and smoothly integrating alternative and traditional data sources, our tool provides predictions that are very timely.";
        }

        else {
            msg = "fAlpha.ai provides advanced insights and scenario analysis, covering over 5,000 corporate names and ETFs with alternative and traditional data. Our engine, when combined with your analysis, can improve your call accuracy and timeliness, offering complementary predictions with confidence levels. We are so confident that we can extend a free trial of our tool and offer 1-hour of top-notch data science support and customization to you.";
        }
        


        let intro = "";
        if (version === "Analyst (Large Firm) V1"){
            intro = `I hope this message finds you well. My name is ${name.split(" ")[0]}, and I am the Data Science Officer at <a href="https://bam.money/for-analysts${sourceEmailParam}">fAlpha.ai</a>. Upon reviewing  your  recommendations and price targets for ${tickers.join(", ")}, I see an opportunity for you to refine your analysis further with the support of our platform.`
        } else if (version === "Analyst (Large Firm) V2"){
            intro = `My name is ${name.split(" ")[0]}, and I am the Data Science Officer at <a href="https://bam.money/for-analysts${sourceEmailParam}">fAlpha.ai</a>. After analyzing your recent recommendations and price targets for ${tickers.join(", ")},  I see a great opportunity for you to enhance your analysis with our platform.`}
        else if (version === "Analyst (Large Firm) V3"){
                intro = `I hope this message finds you well. My name is ${name.split(" ")[0]}, and I am the Data Science Officer at <a href="https://bam.money/for-analysts${sourceEmailParam}">fAlpha.ai</a>. I've taken a close look at your recent recommendations and price targets for  ${tickers.join(", ")}, and I believe fAlpha can provide a substantial boost to your analytical process.`}
        else if (version === "Analyst (Large Firm) V4"){
            intro = `I hope this message finds you well. My name is ${name.split(" ")[0]}, CEO of <a href="https://bam.money/for-analysts${sourceEmailParam}">fAlpha.ai</a>. We have released a machine learning trading insights tool for ${tickers.join(", ")}. We can prove that it can significantly reduce your less than ideal calls (as well as confirm the great ones).`}
        else{
            intro = `I'm ${name.split(" ")[0]}, Co-founder at <a href="https://bam.money/for-analysts${sourceEmailParam}">fAlpha.ai</a>. I recently reviewed your recommendations and price targets for ${tickers.join(", ")}, and I believe there's a valuable opportunity for you to enhance your analysis with our tool.`
        }



        let call = '';
        if(version === "Analyst (Large Firm) V1"){
            call = `I'd appreciate the opportunity to discuss how fAlpha can support your analytical work. Would you be available for a brief 15-minute call? Additionally, I invite you to take advantage of our free trial to explore the full capabilities of our platform.<br /><br />Looking forward to your response.`
        }
        else if(version === "Analyst (Large Firm) V2"){
            call = `I'd love to demonstrate how fAlpha can fit into your existing workflow during a brief 15-minute call. Additionally, we're offering a free trial of our platform to give you a firsthand experience of its benefits.<br /><br />Please let me know when you're available to connect!`
        }
        else if(version === "Analyst (Large Firm) V3"){
            call = `I'd like to offer you a free trial of fAlpha.ai, along with an opportunity to discuss how it can be integrated into your strategy during a quick 15-minute call. When would be a convenient time for you? <br /></br/>I'm excited about the potential to collaborate.`
        }
        else if(version === "Analyst (Large Firm) V4"){
            call = `I'd love to schedule a 15-minute call to show how our engine can boost your fund's performance no strings attached.<br><br>When would be a convenient time for you?`
        }
        else{
            call = `I'd love to show you our tool in a quick 15-minute call. When would be convenient for you? `
        }



        return `<p>Hi ${rfname}, </p>
                <br />
                <p>${intro}</p>
                <p>${msg}</p>
                <p>${call}</p>
                <br />
                <br />
                <p>Best Regards,</p>
                <a href="${linkedin}"><p>${name}</p></a>
                <p>CEO, Founder</p>
                <a href="https://bam.money/for-analysts${sourceEmailParam}"><p>fAlpha.ai</p></a>
                <br />
                <p>PS</p>
                <p>This has been our result with the aggregate of the S&P500:</p>
                <a href="https://bam.money/for-analysts${sourceEmailParam}"><img src="${image_src}" alt="" width="300px" /></a>`
    }

    //function to encode MIME msg
    function base64EncodeMimeMessage(mimeMessage) {
        return btoa(unescape(encodeURIComponent(mimeMessage)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    //funct to trigger draft creation
    function triggerDraft(template, subject, images) {
        const boundary = 'Part_0_123456789.1234567890';
        const lineBreak = '\r\n';
        let mimeMessage = '';
        mimeMessage += "MIME-Version: 1.0" + lineBreak + `Subject: ${subject}` + lineBreak;
        if (remail.length > 5) mimeMessage += `To: ${remail}${lineBreak}`
        mimeMessage += `Content-Type: multipart/mixed; boundary="${boundary}"${lineBreak}${lineBreak}`;
        mimeMessage += `--${boundary}${lineBreak}`
        mimeMessage += `Content-Type: text/html; charset="utf-8"${lineBreak}`;
        mimeMessage += 'Content-Transfer-Encoding: 8bit' + lineBreak + lineBreak;
        mimeMessage += template + lineBreak + lineBreak;
        images.forEach((image, index) => {
            mimeMessage += `--${boundary}${lineBreak}`;
            mimeMessage += `Content-Type: ${image.mimeType}${lineBreak}`;
            mimeMessage += `Content-Transfer-Encoding: base64${lineBreak}`;
            mimeMessage += `Content-ID: <image${index + 1}>${lineBreak}`;
            mimeMessage += `Content-Disposition: inline; filename="${image.filename}"${lineBreak}${lineBreak}`;
            mimeMessage += image.data + lineBreak + lineBreak;
        });
        mimeMessage += `--${boundary}--`
        createDraft(base64EncodeMimeMessage(mimeMessage))
        return;
    }

    //required cols for diff templates THIS IS HOW CSV WORKS AND GETS INFO
    const templateRequiredCols = {
        General: ["email", "company", "title", "first_name", "last_name", "top_iis_holdings"],
        "Analyst (Large Firm)": ["email", "company", "title", "first_name", "last_name"],
        "Analyst (Small Firm)": ["email", "company", "title", "first_name", "last_name"],
        "Analyst (Large Firm) V1": ["email", "company", "title", "first_name", "last_name"],
        "Analyst (Large Firm) V2": ["email", "company", "title", "first_name", "last_name"],
        "Analyst (Large Firm) V3": ["email", "company", "title", "first_name", "last_name"],
        "Analyst (Large Firm) V4": ["email", "company", "title", "first_name", "last_name"],
        "V400" : ["email", "company", "title", "first_name", "last_name", "top_iis_holdings"]
        
    }

    //funct to create bulk drafts
    function findMissingElements(array, subarray) {
        const missing = [];
        for (let i = 0; i < array.length; i++) {
            if (!subarray.includes(array[i])) {
                missing.push(array[i]);
            }
        }
        return missing;
    }

    async function bulkDraft() {
        const missingElements = findMissingElements(templateRequiredCols[template], Object.keys(fileData[0]));
        if (missingElements.length > 0) {
            alert(`CSV doesn't contain required columns: ${missingElements.join(", ")}`);
            return;
        }
        let startTime = Date.now();
        for (let i = 0; i < fileData.length; i++) {
            setMessage(`Generating Email (${i + 1})`)
            setLoading(true);
            let currentTime = Date.now();
            if (startTime + 2400 * i > currentTime) {
                // delay start if it is running too fast
                await new Promise(r => setTimeout(r, (startTime + 2400 * i) - currentTime));
            }
            try {
                if (template == "General") {
                    const chartData = JSON.parse(fileData[i].top_iis_holdings)
                    const data = await submitData(chartData);
                    if (data) {
                        remail = fileData[i].email
                        rfirm = fileData[i].company
                        rtitle = fileData[i].title
                        rfname = fileData[i].first_name
                        rlname = fileData[i].last_name

                        if (rfname != fileData[i].first_name) {
                            console.log("error");
                        }
                        if (data.pnl > 0.0005) {
                            triggerDraft(
                                getTemplateGeneral(chartData, data, "cid:image1"),
                                `${rlname}, Boost ${rfirm}'${!(rfirm).endsWith("s") ? "s" : ""} performance with fAlpha's unique insights (${Math.round(data.pnl * 100000) / 1000}% excess PnL, less risk)`,
                                [
                                    {
                                        filename: 'portfolio_pnl.png',
                                        mimeType: 'image/png',
                                        data: data.image_base64
                                    }
                                ]
                            );
                        } else {
                            addToLog(`failed because pnl was too low: ${data.pnl}`)
                        }
                    }
                } 
                else if (template == "V400") {
                    const chartData = JSON.parse(fileData[i].top_iis_holdings)
                    const data = await submitData(chartData);
                    if (data) {
                        remail = fileData[i].email
                        rfirm = fileData[i].company
                        rtitle = fileData[i].title
                        rfname = fileData[i].first_name
                        rlname = fileData[i].last_name
                        // rholdings = fileData[i].top_iis_holdings

                        if (rfname != fileData[i].first_name) {
                            console.log("error");
                        }
                        if (data.pnl > 0.0005) {
                            triggerDraft(
                                getTemplateV400(chartData, data, "cid:image1"),
                                `${rfname}, Reduce ${rfirm.trim()}'${!(rfirm.trim()).endsWith("s") ? "s" : ""} Risk While Maintaining Focus on Holdings Like ${fileData[i].holdings.split(',')[0]}`,
                                [
                                    {
                                        filename: 'portfolio_pnl.png',
                                        mimeType: 'image/png',
                                        data: data.image_base64
                                    }
                                ]
                            );
                        } else {
                            addToLog(`failed because pnl was too low: ${data.pnl}`)
                        }
                    }
                } 
                
                
                else if (template == "Analyst (Large Firm)" || template == "Analyst (Small Firm)" || template == "Analyst (Large Firm) V1"  || template == "Analyst (Large Firm) V2" || template == "Analyst (Large Firm) V3" || template == "Analyst (Large Firm) V4") {
                    const response = await fetch(`/api/analyst/get_top_tickers_public?name=${fileData[i].first_name} ${fileData[i].last_name}`);
                    const responseData = await response.json();
                    console.log(responseData)
                    const tickers = responseData.tickers.filter((t) => t);
                    if (tickers && Array.isArray(tickers) && tickers.length > 0) {
                        remail = fileData[i].email
                        rfirm = fileData[i].company
                        rtitle = fileData[i].title
                        rfname = fileData[i].first_name
                        rlname = fileData[i].last_name
                        triggerDraft(
                            getTemplateAnalyst(tickers, template == "Analyst (Large Firm)", "cid:image1", template),
                            `${rfname},  Amplify Your Analytical Calls on ${tickers[0]}  with fAlpha's machine learning Insights`,
                            [
                                {
                                    filename: 'sp500_pnl.png',
                                    mimeType: 'image/png',
                                    data: sp500chart
                                }
                            ]
                        );
                    } else {
                        addToLog(`failed because no tickers positive: ${fileData[i].first_name} ${fileData[i].last_name}`)
                    }
                }
            } catch (e) {
                console.log(e)
                console.error("json formatting error")
            }
        }
        setMessage("Done Generating");
        setLoading(false);
    }


    //funct for google login
    const googleLogin = useGoogleLogin({
        scope: SCOPES,
        onSuccess: async tokenResponse => {
            const userInfo = await axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data);
            setTokenResponse(tokenResponse);
            setUser(userInfo);
            addToLog(`${userInfo.email} logged in`);
        },
        onError: errorResponse => { addToLog(`login error: ${errorResponse}`); console.log(errorResponse) },
    });


    //funct for google logout
    function logOut() {
        addToLog(`${user.email} logged out`);
        googleLogout();
        setTokenResponse(null);
        setUser(null);
    }

    
    //funct to create draft in gmail
    async function createDraft(encoded_message) {
        fetch(`https://gmail.googleapis.com/gmail/v1/users/${encodeURIComponent(user.email)}/drafts`, {
            method: "POST",
            body: JSON.stringify({
                "message": {
                    "raw": encoded_message
                }
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${tokenResponse.access_token}`,
            }
        }).then(response => response.json())
            .then(data => console.log(data))
            .then(() => { addToLog("draft successfully created"); return; })
            .catch(
                ((e) => { addToLog(`error creating draft: ${e}`); return; })
            );
    }

    return (

        <Box>
            <h3>fAlpha.ai standalone chart generation</h3>

            <Box sx={{
                display: "flex",
                height: "100%",
                width: "100%",
            }}>
                <Box sx={{
                    height: "fit-content",
                    width: "50%",
                    padding: "20px",
                }}>
                    <p>Bulk CSV Entry Mode</p>
                    <Button
                        variant="contained"
                        component="label"
                    >
                        Upload File
                        <input
                            type="file"
                            onInput={handleFileChange}
                            hidden
                        />
                    </Button>
                    <p>Sample of data uploaded (max 15 rows)</p>
                    <TableContainer component={Paper}>
                        {fileData && Array.isArray(fileData) && fileData.length > 0 &&
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            Object.keys(fileData[0]).map((key, index) => <TableCell align={index == 0 ? "" : "right"} key={`chart-gen-table${index}`}>{key ? key : "N/A"}</TableCell>)
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fileData.slice(0, 15).map((row) => (
                                        <TableRow key={JSON.stringify(row)}>
                                            {
                                                Object.keys(row).map((key, index) => (
                                                    index == 0 ?
                                                        <TableCell component="th" scope="row" key={JSON.stringify(row) + index}>
                                                            {row[key] ? row[key] : "N/A"}
                                                        </TableCell>
                                                        :
                                                        <TableCell align="right" key={JSON.stringify(row) + index}>{row[key] ? row[key] : "N/A"}</TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                    </TableContainer>
                    <Button onClick={downloadCSV}>Convert Analysts + tickers into CSV</Button>

                </Box>
                <Box sx={{
                    height: "fit-content",
                    width: "50%",
                    padding: "20px",
                }}>
                    <p style={{ fontSize: "10px" }}>{message}</p>
                    {
                        loading &&
                        <>
                            <LinearProgress />
                            <br />
                        </>
                    }
                    <FormControl>
                        <InputLabel id="chart-gen-template-select-label">Template</InputLabel>
                        <Select
                            labelId="chart-gen-template-select-label"
                            value={template}
                            label="Template"
                            onChange={(e) => setTemplate(e.target.value)}
                        >
                            <MenuItem value={"General"}>General Template</MenuItem>
                            <MenuItem value={"Analyst (Small Firm)"}>Analyst (Small Firm) Template</MenuItem>
                            <MenuItem value={"Analyst (Large Firm)"}>Analyst (Large Firm) Template</MenuItem>
                            <MenuItem value={"Analyst (Large Firm) V1"}>Analyst (Large Firm) Template Version 1</MenuItem>
                            <MenuItem value={"Analyst (Large Firm) V2"}>Analyst (Large Firm) Template Version 2</MenuItem>
                            <MenuItem value={"Analyst (Large Firm) V3"}>Analyst (Large Firm) Template Version 3</MenuItem>
                            <MenuItem value={"Analyst (Large Firm) V4"}>Analyst (Large Firm) Template Version 4</MenuItem>
                            <MenuItem value={"V400"}>Version 400</MenuItem>
                        </Select>
                    </FormControl>


                    {
                        fileData && fileData.length > 0 &&
                        <>
                            {
                                tokenResponse ?
                                    <>
                                        <Button onClick={bulkDraft}>Bulk Create Drafts (max 25 draft / min)</Button>
                                    </>
                                    :
                                    <p>Log in to Google in order to bulk create drafts</p>
                            }
                        </>
                    }

                    <hr />
                    <p>Write your info for the email template</p>
                    <TextField
                        variant="outlined"
                        label={`Display Name`}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        sx={{ width: "80%" }}
                    />
                    <TextField
                        variant="outlined"
                        label={`Email`}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        sx={{ width: "80%" }}
                    />
                    <TextField
                        variant="outlined"
                        label={`Linkedin`}
                        value={linkedin}
                        onChange={(event) => setLinkedin(event.target.value)}
                        sx={{ width: "80%" }}
                    />
                    <hr />

                    {
                        user ?
                            <>
                                <p>Logged in as {user.name} ({user.email})</p>
                                <Button onClick={logOut}>Logout of Google</Button>
                                {
                                    log.length > 0 &&
                                    <>
                                        <p style={{ textAlign: "left" }}>Log of Events</p>
                                        {
                                            log.map((item) => {
                                                return <p key={item} style={{ color: "red", textAlign: "left", margin: 0 }}>{item}</p>
                                            })
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <p>Authorize Google Account</p>
                                <Button onClick={googleLogin}>Login to Google</Button>
                            </>

                    }

                </Box>
            </Box>
        </Box >

    )
}