import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";


export default function Terms() {
    window.document.title = "fAlpha.ai | Terms and Conditions";

    return (
        <>  
            <PublicNavbar />
            <PublicTitle title="Terms and Conditions" />
            <div style={{textAlign: "left", margin: "0 5vw"}}>
                <h5>Terms of Use</h5>
                <p>Please read the following Terms and Conditions (the “Agreement”) carefully, before using the services offered by fAlpha (“fAlpha.ai”). The website, www.falpha.ai (“Website”) is registered in the name of fAlpha.ai. By using or visiting the Website and any of the adjoining services (collectively “Services”), you agree to be bound by this Agreement. Use of this Website may be monitored, tracked and recorded by fAlpha.ai. Anyone using the Website expressly consents to the Agreement, as well as to such monitoring, tracking and recording.
                </p><h5>AGREEMENT</h5>
                <p>Customer acknowledges and agrees that the use of the Website, including any and all information, statements, materials, articles, research, data, software, links to thirdparty websites, products and services made available on or accessible through the Website (collectively, “Content”), is conditioned on the Customer's strict adherence to the terms of this Agreement, and that such acceptance creates a valid and binding contract between the Customer and fAlpha. Please note that the terms and conditions of this Agreement are in addition to any other agreements between the Customer and fAlpha.ai, including any Customer Account Agreements, and any other agreements that govern the Customers use of the Website and the Content. By using the Website and the Content, Customer further agrees: (a) to comply with U.S. law regarding the transmission of any information through the Website; (b) not to use the Website for illegal purposes; and (c) not to interfere with, trespass on or disrupt the networks or computers and equipment connected to the Website. If, at any time, Customer does not wish to accept this Agreement, Customer must discontinue use of the Website and any Content obtained from the Website.</p>
                <h5>NO REPRESENTATION, SOLICITATION, RECOMMENDATION OR OFFER</h5>
                <p>Securities or financial instruments mentioned in the Website may not be suitable or appropriate for all investors. Customer's particular needs, investment objectives and financial situation were not taken into account in the preparation of the Website. The fact that fAlpha.ai has made or may make Content available on or accessible through the Website does not constitute a representation by fAlpha.ai that any such Content is suitable or appropriate for Customer. The Content is for Customer's personal use, and fAlpha.ai is not soliciting any action based upon its provision. The Content is not to be construed as a recommendation or an offer to buy or sell, or the solicitation of an offer to buy or sell, or to enter into any transaction in respect of any security, financial product or other instrument from fAlpha.ai. Although the information contained in the Content is obtained or compiled from or based upon information that fAlpha.ai considers reliable, fAlpha.ai does not represent or warrant that such information is accurate, current or complete. Customer acknowledges that: (a) the Website is provided for informational purposes only; (b) the Website may include information taken from thirdparty sources;   and   (c)   any reliance on any portion of the Content shall be at Customer's   sole risk. fAlpha.ai does not own, sell, resell, provide, control, manage, offer, or supply any assets. Customers and users alone are responsible for their listings on the Website. When investor Customers contact an existing asset holder Customer regarding a specific asset, they are entering into a contract directly with each other. fAlpha.ai is not and does not become a party to or other participant in any contractual relationship between the Customers. fAlpha.ai is not acting as an agent in any capacity for any Customer.</p>
                <h5>INTELLECTUAL PROPERTY</h5>
                <p>The Website, including the Content, is owned by fAlpha.ai or its licensors, and is protected under the copyright, trademark, data misappropriation, unfair competition, or other intellectual property laws of the U.S. Thirdparty trademarks and service marks are the property of their respective owners. All other service marks and trademarks displayed on the Website are registered and unregistered service marks and trademarks of fAlpha.ai.  The Website is further protected as a collective work and/or compilation under U.S. copyright and other laws. Using, copying, downloading, reformatting or storing any portion of the Website for other than personal, noncommercial use, unless such use constitutes “fair use” under the Copyright Act of 1976 (17 U.S.C. §107), as amended, and except for one temporary copy in a single computer's memory, without the prior written consent of fAlpha.ai or the relevant licensor is expressly prohibited. Customer shall not modify, sell, display, distribute, publish, tran smit, broadcast or otherwise disseminate the Website or the Content to others without fAlpha.ai's prior written consent. Customer agrees to abide by all applicable copyright, trademark and other intellectual property laws and with all additional copyright and trademark notices, information, and restrictions contained in any of the Content.</p>
                <p>Use of this Website, does not permit the Customer to acquire any rights in fAlpha.ai's intellectual property. The Customer will be held liable if fAlpha.ai's intellectual property is unbundled or separated or used as a stand-alone product.</p>
                <p>By using and accessing the Website, you agree to keep all information you learn about companies, assets, funds and investment opportunities on the Website private and strictly confidential.</p>
                <h5>FEES</h5>
                <p>Registration with fAlpha.ai is currently subject to User Fees. The User Fee is under the exclusive control of fAlpha.ai and fAlpha.ai reserves the right to review and update the fees at any time.</p>
                <p>fAlpha.ai reserves the right to charge differential User Fees for different asset holders, that may be based on the size, volume, type of the assets and type of action. fAlpha.ai will provide email notification to the Customer regarding the applicable User Fees. The User Fees is non-refundable.</p>
                <p>Upon acceptance of this Terms of Use, the User shall be redirected to the detailed Payment Terms document. Users will be required to accept that document prior to proceeding into the fAlpha.ai Website. Once accepted, the Payment Terms and this Terms of Use will be binding on the User.</p>
                <p>fAlpha.ai reserves the right to change the charges for the usage of the Website and the services under the Agreement at any time subject to a respective modification of the Agreement. The User will have the opportunity to reject these changes upon notification. However, User should provide email notification of its rejection. Upon rejection, User shall stop using the fAlpha.ai Website immediately.</p>
                <p>All pricing information contained in this document or in the Payment Terms document is fAlpha.ai proprietary and confidential information and User shall maintain confidentiality regarding the same.</p>
                <h5>TAXES</h5>
                <p>Each Party will pay any taxes now or hereafter imposed by law on   the respective Party unless otherwise provided by the Agreement.</p>
                <h5>DISCLAIMER OF WARRANTIES</h5>
                <p>THE WEBSITE, INCLUDING THE CONTENT, IS PROVIDED "AS IS." fAlpha.ai, ITS LICENSORS, AND THEIR OFFICERS, DIRECTORS, AND EMPLOYEES (COLLECTIVELY, THE fAlpha.ai PARTIES”), MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NONINFRINGEMENT, AS TO THE WEBSITE OR ANY PORTION THEREOF OR RELATING TO ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE WEBSITE OR ANY LINKED WEBSITE, UNLESS SUCH REPRESENTATIONS, WARRANTIES AND GUARANTEES ARE NOT LEGALLY EXCLUDABLE. THE fAlpha.ai PARTIES HAVE NO RESPONSIBILITY TO MAINTAIN THE WEBSITE OR ANY PORTION THEREOF OR TO SUPPLY ANY CORRECTIONS, UPDATES, OR RELEASES THERETO. THE fAlpha.ai PARTIES DO NOT WARRANT THAT THE WEBSITE OR ANY PORTION THEREOF WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, THAT THE WEBSITE OR THE SERVER ON WHICH IT IS HOSTED IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SOFTWARE THEREIN IS COMPATIBLE WITH CUSTOMER'S EQUIPMENT. AVAILABILITY OF THE WEBSITE IS SUBJECT TO CHANGE WITHOUT NOTICE. NO ORAL OR WRITTEN ADVICE OR INFORMATION GIVEN BY fAlpha.ai, ITS EMPLOYEES OR LICENSEES WILL CREATE A WARRANTY OR GUARANTY, NOR MAY CUSTOMER RELY ON ANY SUCH INFORMATION OR ADVICE.</p>
                <p>fAlpha.ai does not claim to be and is not a BROKER, DEALER OR INVESTMENT ADVISOR AND NOTHING HEREIN SHALL CONSTITUTE A SALE OR OFFER TO BUY OR SELL OR RECOMMEND ANYTHING. THE CUSTOMER SHALL MAKE THEIR OWN INVESTMENT DECISIONS BASED UPON THEIR PERSONAL DUE DILIGENCE INVESTIGATION AND OTHER PERSONAL INVESTMENT CRITERIA.</p>
                <h5>LIMITATION OF LIABILITY</h5>
                <p>UNDER NO CIRCUMSTANCES SHALL fAlpha.ai, NOR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS (COLLECTIVELY “PARTIES”), BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES. PARTIES HAVE NO LIABILITY TO THE CUSTOMER OR TO ANY OTHER PERSON FOR THE QUALITY, ACCURACY, TIMELINESS, CONTINUED AVAILABILITY OR COMPLETENESS OF THE WEBSITE OR ANY PORTION THEREOF. IN NO EVENT WILL THE PARTIES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES THAT CUSTOMER OR ANY OTHER PERSON MAY INCUR IN CONNECTION WITH CUSTOMER'S ABILITY OR INABILITY TO USE THE WEBSITE, OR ANY PORTION THEREOF, OR INTERRUPTION OF SERVICE, INCLUDING BUT NOT LIMITED TO, DELAYS OR INTERRUPTION OF INTERNET OR NETWORK SERVICES NOT OPERATED OR CONTROLLED BY THE fAlpha.ai PARTIES, OR OMISSIONS OR INACCURACIES IN SUCH INFORMATION OR MATERIALS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES INCLUDING, WITHOUT LIMITATION, LOST PROFITS AND LOSS OF REVENUE, EVEN IF ANY OF THE fAlpha.ai PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                <p>NOTHING IN THIS AGREEMENT EXCLUDES OR RESTRICTS fAlpha.ai's LIABILITY IN RESPECT OF A BREACH BY fAlpha.ai OF ANY DUTY OR LIABILITY IT MAY HAVE TO CUSTOMER UNDER APPLICABLE REGULATIONS OR LAWS; FRAUD, WILFUL MISCONDUCT OR FOR DEATH OR PERSONAL INJURY CAUSED BY fAlpha.ai's NEGLIGENCE; OR ANY OTHER LIABILITY THAT CANNOT LAWFULLY BE EXCLUDED. IT IS CUSTOMER'S RESPONSIBILITY TO ENSURE THAT CUSTOMER MAINTAINS ADEQUATE INSURANCE TO COVER ANY DAMAGES, LOSSES, COSTS OR EXPENSES CUSTOMER MIGHT SUFFER IN THE EVENT OF ANY FAILURE OR UNAVAILABILITY OF THE   WEBSITE.</p>
                <h5>INDEMNIFICATION</h5>
                <p>Customer agrees to indemnify, defend and hold harmless fAlpha.ai from and against any and all losses, liabilities, judgments, fines, settlements, damages, costs and expenses (including attorneys' fees) resulting from or arising out of any suits, actions, claims, demands, investigations, hearings or similar proceedings (collectively, “Proceedings”) to the extent such Proceedings are based on or result from (i) Customer's inability to use the Website or any portion thereof, or any data, information, service, report, analysis or publication Customer derives therefrom; (ii) Customer's breach or violation of any term or condition contained herein; or (iii) Customer's violation of state or federal securities laws or regulations or any other person's rights, including but not limited to, infringement of any intellectual property, proprietary or privacy right of any thirdparty.</p>
                <h5>HYPERLINKS TO OR FROM THIRD-PARTY WEBSITES</h5>
                <p>The Website may contain links to certain Internet websites sponsored and maintained by thirdparties. These Internet websites are not under the control of fAlpha.ai. fAlpha.ai does not investigate, monitor or review such thirdparty websites for accuracy, completeness or conformance with applicable regulations or laws. In addition, thirdparty websites may contain links to the Website. fAlpha.ai is not responsible or liable for, and makes no representations or warranties, concerning the content of any such thirdparty websites. The fact that fAlpha.ai has provided a link to a thirdparty website, or that a thirdparty website has provided a link to the Website, does not constitute an endorsement, authorization, sponsorship, or affiliation by fAlpha.ai with respect to that website, its owners, or its providers. Customer accesses that website and any services or information provided therein at   Customer's own risk.</p>
                <h5>COMPUTER VIRUSES</h5>
                <p>Customer shall not introduce into the Website any code, malicious or hidden procedures, routines or mechanisms that would inhibit other
                  Customers' access to the Website, or to enable or impair its operation, nor shall Customer access the Website to gain any unauthorized access
                  to any computer system operated by fAlpha.ai.</p>
                <h5>SUSPICIOUS ACTIVITY</h5>
                <p>If fAlpha.ai determines that Customer is engaging in, or has engaged in, any suspicious activity, fAlpha.ai reserves the right to terminate Customer's access to all or part of the Website immediately and, if deemed appropriate by fAlpha.ai in its sole discretion, to notify any   relevant governmental or regulatory authority.</p>
                <p>fAlpha.ai also reserves the right to bring a private action against the Customer if the alleged suspicious   activity   affects   in   any   way fAlpha.ai's reputation and business management.</p>
                <p>fAlpha.ai is committed to provide a secure experience to Customers, whilst using the Website. Therefore, if any Customer individually comes across any suspicious activity taking place   on   the   Website,   they should inform fAlpha.ai immediately.</p>
                <p>In the event of a password theft, Customers should promptly inform fAlpha.ai and take all requisite measures to rectify the loss of information.</p>
                <h5>INCENTIVES</h5>
                <p>fAlpha.ai may introduce different schemes to incentivize current and future Customers. However, the process of incentivisation is purely under fAlpha.ai's control. fAlpha.ai may allow Customers to share some specific asset/Website information to third parties for advertising purposes. However, this will be conducted within the Customers and fAlpha.ai's confidentiality obligations.</p>
                <h5>MODIFICATIONS TO TERMS OF USE</h5>
                <p>fAlpha.ai reserves the right, without notice and at its sole discretion, to modify any portion of this Agreement. Customer should continue to review this Agreement whenever accessing or using the Website. Customer's use of the Website after the posting of changes to the Agreement will constitute Customer's acceptance of the new Agreement, as modified, and Customer will be bound by any such changes as of the date they are first posted to the Website. fAlpha.ai expressly rejects any terms and conditions proposed by Customer that are in addition to or that conflict with this Agreement and such proposed terms and conditions shall be of no force or effect.</p>
                <h5>ENFORCEABILITY AND GOVERNING LAW</h5>
                <p>If any provision of this Agreement is determined to be invalid, superseded, illegal or unenforceable, in whole or in part, the validity, legality or enforceability of any of the remaining provisions or notices shall not in any way be affected or impaired thereby and shall continue in full force and effect.</p>
                <p>Any dispute, controversy or claim arising out of or related to the Website or the Agreement shall be governed by and interpreted under the laws of New York County in the State of New York (excluding any rules of law that would lead to the application of the laws of any other jurisdiction). The Customer submits to the exclusive jurisdiction of   the New York Courts.</p>
                <h5>CONFIDENTIALITY</h5>
                <p>The Customers and any closely related affiliates or 3rd parties acknowledge that the information provided on the Website (hereinafter “Confidential Information”) is confidential. Therefore, for the term of the Agreement and thereafter, the Customer shall strictly keep confidential and shall not publish or otherwise disclose or use any Confidential Information or parts thereof. The term Confidential Information does not include information that: (i) is already in public domain at the time of disclosure; (ii) was already in the recipient's possession at the time of disclosure without restriction as to confidentiality; (iii) was obtained from a third party without restriction on disclosure; and (iv) independently developed by the recipient without regard to the Confidential Information of the   disclosing party.</p>
                <p>fAlpha.ai will use reasonable efforts to maintain the confidentiality of information marked as Customer Confidential Information provided by the Customer, in connection with the use of the Website and other Services. Nonetheless, Customer acknowledges and agrees that any such information sent via the Internet may not be protected and there can be no assurance that such information will remain confidential. With the exception of personal information and/or information properly marked as private i.e., using the tools provided by the system any communication or material Customer posts or transmits to fAlpha.ai over the Internet is, and will be treated as, non-confidential and nonproprietary. Customer's posting or transmitting of any unlawful, threatening, libelous, defamatory, obscene, pornographic, abusive, discriminatory or profane material, or any material that could constitute or encourage conduct that would be considered a criminal offence or violation of any law, is strictly prohibited. In addition, Customer acknowledges and agrees that, subject to applicable law, fAlpha.ai may disclose Customer's name and other personal and financial information about Customer or Customer's account(s) to its employees, representatives, officers, directors, agents and affiliates, or to any governmental or regulatory authority or self-regulatory organization, or to any third party service provider, (i) to comply with applicable laws, rules, orders, subpoenas or requests for information by governmental or regulatory authorities or self-regulatory organizations, or (ii) for any other purpose described in any agreement Customer may have with fAlpha.ai.</p>
                <p>fAlpha.ai has implemented adequate internal controls to ensure confidentiality of all marked Customer Confidential Information and fAlpha.ai will adhere to all industry standard rules and regulations to   ensure confidentiality.</p>
                <p>fAlpha.ai will not be responsible for any confidential information shared under any specific Customer non-disclosure agreements that users sign amongst themselves. Customers are free to exchange such information with other Customers as they may deem necessary by entering into an   independent non-disclosure agreements and fAlpha.ai will not be a party to the same.</p>
                <h5>TERMINATION</h5>
                <p>fAlpha.ai, reserves the right to terminate this Agreement and suspend any or all services for any single or group of users at any time via an email notification. Upon, termination, the Customer will no longer have access to the Website or any of the Services provided by fAlpha.ai.</p>
                <p>If a customer uploads an asset on the fAlpha Portal and the sale of such an asset occurs either by using the portal or otherwise, the customer is required to inform fAlpha immediately about the sale   of   the   asset   and promptly thereafter, remove the asset from the portal.</p>
                <p>Customer's failure to notify and timely removal of the asset can lead to fAlpha.ai levying appropriate digital penalties as may be   appropriate on the Customer.</p>
                <h5>NO ASSIGNMENT</h5>
                <p>The Customer shall not assign or transfer any of its rights and obligations under this Agreement without fAlpha.ai's prior written consent. fAlpha.ai may assign or transfer its rights and obligations   under this Agreement without the Customer's consent.</p>
            </div>
            <PublicFooter />
        </>
    );
}