import React, { useRef, useState, useEffect } from "react";
import '../css/d3.css'
import {
    select,
    line,
    area,
    curveCardinal,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    scaleTime,
    extent,
    timeMonth,
    timeDay,
    max,
    min,
    easeLinear
} from "d3";
import { Tooltip } from 'react-tooltip';

//chart component
export default function D3DynamicLine(props) {
    const svgRef = useRef();
    const boundingRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const dataTmp = [];
    // generate data
    let val = 1;
    let val2 = 1;
    let val3 = 1;
    for (let i = 0; i < 30; i++) {
        dataTmp.push({
            index: i,
            value1: val,
            value2: val2,
            value3: val3
        })
        val *= 1 + Math.random() / 50;
        val2 *= 1 + Math.random() / 20;
        val3 *= 1 + Math.random() / 10;
    }
    const [data, setData] = useState(props.data ? props.data : dataTmp);

    function handleWindowResize() {
        setWidth(boundingRef.current.offsetWidth);
        setHeight(boundingRef.current.offsetHeight);
        svgRef.current.style.width = boundingRef.current.offsetWidth;
        svgRef.current.style.height = boundingRef.current.offsetHeight;
    }

    useEffect(() => {
        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    function renderChart(data) {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        const x = scaleLinear()
            .domain(extent(data, d => d.index))
            .range([0, width]);

        let allValues = data.reduce((acc, obj) => {
            return acc.concat([obj.value1, obj.value2, obj.value3]);
        }, []);

        const y = scaleLinear()
            .domain([Math.min(...allValues), Math.max(...allValues)])
            .nice()
            .range([height, 0]);

        const line1 = line()
            .x(d => x(d.index))
            .y(d => y(d.value1));

        const line2 = line()
            .x(d => x(d.index))
            .y(d => y(d.value2));

        const line3 = line()
            .x(d => x(d.index))
            .y(d => y(d.value3));

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "black")
            .attr("stroke-width", 2)
            .attr("stroke-dasharray", "4, 3")
            .attr("d", line1);

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "var(--blue)")
            .attr("stroke-width", 2)
            .attr("d", line2);

        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", "var(--orange)")
            .attr("stroke-width", 2)
            .attr("d", line3);
    }

    function svgEmpty() {
        return select(svgRef.current).empty() || svgRef.current.style.width === "0px" || svgRef.current.style.height === "0px"
    }

    //draws chart
    useEffect(() => {
        if (data && data.length > 0) {
            renderChart(data);
        }
    }, [width, height, data]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (svgEmpty()) {
                handleWindowResize();
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [])

    return (
        <div ref={boundingRef} style={{ height: "100%", width: "100%" }} className="d3DL-container">
            <div className="d3-bounding-anim-inf" style={{ height: "100%", width: "100%" }}>
                <svg ref={svgRef} style={{ height: "100%", width: "100%" }}>
                </svg>
            </div>
        </div>
    );
};