import React, { useRef, useState, useEffect } from "react";
import '../css/d3.css'
import {
    select,
    line,
    scaleLinear,
    scaleTime,
    axisBottom,
    axisLeft,
    extent,
    timeFormat
} from "d3";

export default function D3PortfolioHistory({ data }) {
    // Refs for the SVG and container
    const svgRef = useRef();
    const boundingRef = useRef();

    // State for width and height
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        // Function to handle resizing
        function handleWindowResize() {
            setWidth(boundingRef.current.offsetWidth);
            setHeight(boundingRef.current.offsetHeight);
            svgRef.current.style.width = boundingRef.current.offsetWidth;
            svgRef.current.style.height = boundingRef.current.offsetHeight;
        }

        // Initial call to set dimensions
        handleWindowResize();

        // Adding resize event listener
        window.addEventListener('resize', handleWindowResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        // Parsing the data
        const parsedData = data.map(d => ({
            date: new Date(d.date * 1000),
            value: d.value
        }));

        // Setting up the SVG element
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        // Setting up scales
        const xScale = scaleTime()
            .domain(extent(parsedData, d => d.date))
            .range([60, width - 20]);

        const yScale = scaleLinear()
            .domain([0, Math.max(...parsedData.map(d => d.value))])
            .range([height - 30, 20]);

        // Setting up axes
        const xAxis = axisBottom(xScale).ticks(5).tickFormat(timeFormat("%Y-%m-%d"));
        const yAxis = axisLeft(yScale).ticks(6);

        // Line generator
        const lineGenerator = line()
            .x(d => xScale(d.date))
            .y(d => yScale(d.value));

        // Append the line path
        svg.append("path")
            .datum(parsedData)
            .attr("class", "line")
            .attr("d", lineGenerator)
            .style("stroke", "var(--steelblue)")
            .style("stroke-width", 2)
            .style("fill", "none");

        // Append the axes
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height - 30})`)
            .call(xAxis);

        svg.append("g")
            .attr("class", "y-axis")
            .attr("transform", "translate(60,0)")
            .call(yAxis)
            .selectAll('.tick line')
            .attr('opacity', 0.1);

    }, [data, width, height]);

    return (
        <div ref={boundingRef} style={{ height: "100%", width: "100%" }}>
            <div className="d3-bounding-anim-once">
                <svg ref={svgRef} style={{ padding: "10px", height: "100%", width: "100%" }}></svg>
            </div>
        </div>
    );
}
