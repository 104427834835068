
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useState, useRef, useEffect } from 'react'

export function UserVoteButton({
    ticker, hp, regime, isEI, dn_id,
    similarity, riskMetrics, sentimentData, ptLink,
    disabled = false
}) {
    const [lastClickTime, setLastClickTime] = useState(0)
    const clickTimeoutRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [tab, setTab] = useState(0);
    const [tabData, setTabData] = useState([]);
    const [tabNames, setTabNames] = useState([""]);
    const [futherQueried, setFurtherQueries] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem("user-email-bam-money") ? localStorage.getItem("user-email-bam-money") : "");
    const getProfile = async () => {
        if (email == "") {
            fetch("/api/account/profile")
                .then((response) => response.json())
                .then((json) => {
                    setEmail(json.email);
                    localStorage.setItem("user-email-bam-money", json.email);
                });
        }
    };
    useEffect(() => {
        getProfile()
    }, []);

    const navigate = useNavigate();

    function createFlow(json) {
        const tmpTabData = [];
        const tmpTabNames = [];
        // find analyst
        if (json && json.priceTarget) {
            console.log("here")
            const priceTarget = json.priceTarget;
            tmpTabData.push(
                <div key={`uservote-${ticker}-pt`}>
                    <p>Analyst Last Call</p>
                    <p>Target: ${priceTarget.priceTarget} ({ticker})</p>
                    <p>{priceTarget.analystName} ({priceTarget.analystCompany})</p>
                    <Link to={priceTarget.newsURL}>Link to Article</Link>
                </div>
            )
            tmpTabNames.push("Analyst")
        }
        for (let type = 0; type < 2; type++) {
            const fname = type == 0 ? "PRICE" : "COMB";
            if (similarity) {
                const field = similarity[fname];
                if (field) {
                    tmpTabData.push(
                        <TableContainer component={Paper} key={`uservote-${ticker}-pt`}>
                            <p>{fname == "PRICE" ? "Equity Dynamics Similarity" : "Financial Ratios Similarity"}</p>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ticker</TableCell>
                                        <TableCell align="right">Correlation</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {field.map((row, index) => (index < 5 &&
                                        <TableRow key={`uservote-${ticker}-eqsim-${row.ticker}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {row.ticker}
                                            </TableCell>
                                            <TableCell align="right">{row.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                    tmpTabNames.push(fname == "PRICE" ? "Equity Dyn." : "Fin. Ratios")
                }
            }
        }
        var date = "";
        var sentiScore = null;
        var link = "";
        for (let i = 0; i < sentimentData.length; i++) {
            if (sentimentData[i].hlink && sentimentData[i].high) {
                sentiScore = sentimentData[i].high;
                link = sentimentData[i].hlink;
            } else if (sentimentData[i].llink && sentimentData[i].low) {
                sentiScore = sentimentData[i].low;
                link = sentimentData[i].llink;
            } else if (sentimentData[i].alink && sentimentData[i].avg) {
                sentiScore = sentimentData[i].avg;
                link = sentimentData[i].alink;
            }
            if (sentiScore) {
                date = sentimentData[i].date.split("T")[0]
                break;
            }
        }
        if (sentiScore && link != "" && date != "") {
            tmpTabData.push(
                <>
                    <p>News Article</p>
                    <p>Date Posted: {date}</p>
                    <p>Sentiment: {sentiScore}</p>
                    <Link to={link}>Link to Article</Link>
                </>
            )
            tmpTabNames.push("News Article");
        }
        console.log(riskMetrics)
        if (riskMetrics) {
            tmpTabData.push(
                <>
                    <p>Risk Metrics ({riskMetrics["Date Range"].Start}-{riskMetrics["Date Range"].End})</p>
                    <p>Value at Risk: {riskMetrics.VaR.Amount}% ({riskMetrics.VaR["Percentage Cutoff"]}% Cutoff)</p>
                    <p>Expected Shortfall: {riskMetrics.ES.Amount}% ({riskMetrics.ES["Percentage Cutoff"]}% Cutoff)</p>
                    <p>Peak to Trough: {riskMetrics.PTT.Amount}% ({riskMetrics.PTT["Time Period"].Start}-{riskMetrics.PTT["Time Period"].End})</p>
                </>
            )
            tmpTabNames.push("Risk Met.");
        }

        function fisherYatesShuffle(obj1, obj2) {
            var index = obj1.length;
            var rnd, tmp1, tmp2;

            while (index) {
                rnd = Math.floor(Math.random() * index);
                index -= 1;
                tmp1 = obj1[index];
                tmp2 = obj2[index];
                obj1[index] = obj1[rnd];
                obj2[index] = obj2[rnd];
                obj1[rnd] = tmp1;
                obj2[rnd] = tmp2;
            }
        }
        fisherYatesShuffle(tmpTabData, tmpTabNames);

        tmpTabNames.unshift("")

        setTabData([...tmpTabData]);
        setTabNames([...tmpTabNames]);
    }

    const handleClick = async () => {
        const currentTime = new Date().getTime()
        const timeSinceLastClick = currentTime - lastClickTime

        if (timeSinceLastClick < 500) {  // 500ms threshold for double-click
            clearTimeout(clickTimeoutRef.current)
            await submitVote('disagree')
        } else {
            clickTimeoutRef.current = setTimeout(async () => {
                await submitVote('agree')
            }, 500)
        }

        setLastClickTime(currentTime)
    }
    const submitVote = async (vote) => {
        try {
            let hpp = '15d'
            if (hp > 30) {
                hpp = `${(hp / 5 - 5)}m`
            } else {
                hpp = `${hp}d`
            }
            const response = await fetch(`/api/analyst/vote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ticker: ticker,
                    type: isEI ? 'EI' : 'CI',
                    hp: hpp,
                    regime: (regime == 'bullish' ? 'bull' : (regime == 'bearish' ? 'bear' : 'neutral')),
                    vote: vote
                }),
            })
            if (response.status != 504) {
                const json = await response.json();
                setData({
                    'vote': vote,
                    'data': json
                });
                //createFlow(json);
            } else {
                setData({
                    'vote': vote,
                    'error': true
                });
                //createFlow(null);
            }
        } catch (error) {
            console.error('Error fetching post:', error)
        }
    };

    function submitFurtherQuery() {
        const message = `User Vote Button Further Query. \nTicker: ${ticker} \nTabNames: ${JSON.stringify(tabNames)} \nTabData: ${JSON.stringify(tabData)}\n`;
        fetch(`/api/public/feedback`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                message: message,
                url: window.location.href
            }),
        });
        setFurtherQueries(true);
    }

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {disabled ?
                <IconButton disabled style={{ opacity: 0 }}>
                    <TipsAndUpdatesIcon />
                </IconButton>
                :
                <Tooltip title="Click to View Analyst Insights">
                    <IconButton onClick={() => setOpen(true)}>
                        <TipsAndUpdatesIcon
                            sx={open ? { color: "var(--steelblue)" } : {}}
                        />
                    </IconButton>
                </Tooltip>
            }
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                }}
            >
                <Box sx={{
                    width: "30%",
                    backgroundColor: "white",
                    padding: "40px",
                    borderRadius: "30px",
                    textAlign: "center",
                    maxHeight: "80vh",
                    overflowY: "auto"
                }}>
                    <div>
                        {
                            data.vote &&
                            <p>You {data.vote}d with our signal on {ticker}.</p>
                        }
                        {
                            data.data &&
                            (data.data.priceTarget ?
                                <>

                                    <p>{Math.round(data.data.aligns_percentage * 100)}% of analysts {data.data.priceTarget && !data.data.priceTarget.priceTarget && "in similar companies"} align with your opinion</p>
                                    <p>
                                        Of them, the top analyst is <Link to={`/analyst/${data.data.top_analyst.name}`} style={{ color: 'black', textDecoration: 'none' }}>
                                            {data.data.top_analyst.name}
                                        </Link>
                                    </p>
                                    {data.data.priceTarget && data.data.priceTarget.priceTarget && (
                                        <>
                                            <p>This analyst's price target for this ticker was ${data.data.priceTarget.priceTarget} on {data.data.priceTarget.publishedDate.substring(0, 10)}</p>
                                        </>
                                    )}
                                </>
                                :
                                <p>No Price Targets Available for this ticker.</p>
                            )
                        }
                        {
                            data.error &&
                            <p>0% of analysts align with your opinion</p>
                        }
                        {data.vote &&
                            <Button onClick={() => navigate(ptLink)}>See More Analysts</Button>
                        }
                    </div>
                    <div style={{ display: "flex", columnGap: "10px" }}>
                        <Button
                            disabled={data.vote === "agree"}
                            onClick={() => submitVote('agree')}
                        >
                            Agree with the signal
                        </Button>
                        <Button
                            disabled={data.vote === "disagree"}
                            onClick={() => submitVote('disagree')}
                        >
                            Disagree with the signal
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
