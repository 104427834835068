import { HomeOnboardingSection, HomeStatisticsSection } from "./home";
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";


export default function Contact() {
    window.document.title = "fAlpha.ai | Contact Us";
    return (
        <>  
            <PublicNavbar />
            <PublicTitle title="Request Special Access" />
            <HomeOnboardingSection />
            <PublicFooter />
        </>
    );
}