import { useEffect, useState } from 'react';
import {
    useParams
} from 'react-router-dom';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LinkIcon from '@mui/icons-material/Link';
import { PublicFooter, PublicTitle, PublicNavbar } from '../components/pagestyle.js';


export default function Analyst() {
    const params = useParams();
    const name = (params.name ? params.name : '');
    const [data, setData] = useState({});
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`/api/analyst/get?name=${name}`);
            const responseData = await response.json();
            const parsedHistory = responseData.price_targets.map((item) => {
                return {
                    ...item,
                    publishedDate: new Date(item.publishedDate),
                }
            });
            setData({
                accuracy: responseData.accuracy,
                price_targets: parsedHistory
            });
        }
        fetchData();
    }, []);

    return (
        <>
            <PublicNavbar forceRedirectIfNoAccount={true} forceRedirectIfSubscriptionNotActive={true} />
            <PublicTitle title={name} />
            <Paper>
                All time accuracy: {data && data.accuracy && (data.accuracy * 100).toFixed(2)}%
            </Paper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {['Date', 'Ticker', 'Price target', 'Price when posted', 'Actual price', 'Title', 'URL'].map((rowName) => (
                                <TableCell>{rowName}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.price_targets && data.price_targets.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{
                                    //backgroundColor: row.correct ? 'rgba(var(--green-rgb), 0.7)' : 'rgba(var(--red-rgb), 0.7)'
                                }}
                            >
                                <TableCell component="th" scope="row">{row.publishedDate.toLocaleDateString('en-US')}</TableCell>
                                {['symbol', 'priceTarget', 'priceWhenPosted', 'actual_price', 'newsTitle'].map((fieldName) => (
                                    <TableCell component="th" scope="row">
                                        {row[fieldName].toString()}
                                    </TableCell>
                                ))}
                                <TableCell component="th" scope="row">
                                    <a href={row.newsURL} style={{color: 'black'}}>
                                        <LinkIcon />
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
}
