import '../css/forbanksmanagers.css';
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React from 'react';

export default function ForBanks() {
    window.document.title = "fAlpha.ai | For Banks";
    return (
        <>
            <PublicNavbar />
            <PublicTitle title="For Banks" />
            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">Before fAlpha</div>
                <div className="hp-text">
                    Traditionally, a sales and trading professional would do the following:<br />
                    <ul>
                        <li className="hp-list">Use their judgment and read a few direct and market-related news everyday hoping not to miss any important news, and</li>
                        <li className="hp-list">Use their systems and constantly search for signals of developments that could affect price movements hoping to be ahead of the market</li>
                        <li className="hp-list">Communicate with their clients and stakeholders</li>
                    </ul>
                </div>
            </div>
            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">Things Changed</div>
                <div className="hp-text">
                    Digital transactions and social media may seem old but they are not. However they have changed the market picture. Now, not only is "alt-data" estimated to account for 95%<sup>*</sup> of value in data but it also can change things radically much faster. (Think about a $102B bank run<sup>**</sup> on a medium sized bank caused by social media in only a few weeks).
                    <br />
                    <br /><div className="hp-small-text">
                        * Greenwich Associates: "Future of Trading", 2019<br />
                        ** Washington Post: "First Republic clients pulled $102B" April 24, 2023</div>
                </div>
            </div>

            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">fAlpha Provides</div>
                <div className="hp-text">
                    For thousands of corporate names, early trading and credit default indicators based on relevant news sentiment, web search traffic volume, price dynamics and more...
                    <br />
                    <ul>
                        <li className="hp-list">Fast and comprehensive news reading and classifications, through relevant news crawling and filtering, ranking and summarizing the critical articles.</li>
                        <li className="hp-list">Consistent searches for early and reliable indicators, through looking for hidden signals and highlighting information that could yield to future price volatility.</li>
                        <li className="hp-list">Similar name dynamics, organized financial ratios.</li>
                        <li className="hp-list">Notification and alert systems that are customizable to clients and stakeholders focus.</li>
                    </ul>
                    <br />
                    <table className="hp-small-text">
                        <tr>
                            <td width="50%" style={{ padding: "10px" }}><img className="hp-image-border" src="/images/forbankmanager/image3.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td>
                            <td width="50%" style={{ padding: "10px" }}><img className="hp-image-border" src="/images/forbankmanager/image2.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td></tr>
                        <tr>
                            <td><em>State of the art backtesting must include in and out of sample when using on the fly signals.</em></td>
                            <td><em>Statistical signals depend on a series of assumptions (e.g., market regimes, asset classes). They also depend on confidence levels.</em></td>
                        </tr>
                        <tr>
                            <td width="50%" style={{ padding: "10px" }}><img className="hp-image-border" src="/images/forbankmanager/image1.png" style={{ width: "400px", height: "300px", verticalAlign: "bottom" }} alt="" /></td>
                        </tr>
                        <tr>
                            <td><em>Different Trading Strategies yield different results but backtesting shows when signals are robust or not.</em></td>
                        </tr>

                    </table>
                    <br />
                </div>
            </div>

            <hr className="content-hr" />
            <div className="home-page-row">
                <div className="hp-heading">With fAlpha</div>
                <div className="hp-text">
                    <strong>PMs can then be freed from mundane activities to improve coverage and act more confidently to:</strong>
                    <br />
                    <ul>
                        <li className="hp-list">Efficiently track market related news, keep your clients in the loop w/o missing any potent news</li>
                        <li className="hp-list">Rank market news from multiple sources to act more confidently</li>
                        <li className="hp-list">Know what others have been interested in, before that gets into news, to generate alpha and trade leads</li>
                        <li className="hp-list">Automatically (or on demand) notify clients and/or stakeholders easily with a single click on the platform adding their nuanced views. All in private mode.</li>
                    </ul>
                    <br />
                    <img className="hp-image-border" src="images/forbankmanager/marketpulse-ss.png" />
                </div>
            </div>
            <PublicFooter />
        </>
    );
}