import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../css/SECTable.css'

export function SECTable(props) {
    const [secPeriod, setSECPeriod] = useState("quarter");

    const data = props.data;

    function parseData(numbers) {
        const formattedNumbers = numbers.map(number => {
            if (Math.abs(number) >= 1000000000) {
                const formatted = (number / 1000000000).toFixed(2);
                return `${formatted}b`;
            } else if (Math.abs(number) >= 1000000) {
                const formatted = (number / 1000000).toFixed(2);
                return `${formatted}m`;
            } else {
                const formatted = parseFloat(number).toFixed(3);
                return formatted;
            }
        });
        return formattedNumbers;
    }

    const rowData = [
        { name: 'Current Ratio', data: parseData(data.currentRatio) },
        { name: 'Long Term Solvency', data: parseData(data.longTermSolvency) },
        { name: 'Return On Sales', data: parseData(data.returnOnSales) },
        { name: 'Interest Coverage', data: parseData(data.interestCoverage) },
        { name: 'Current Assets', data: parseData(data.currentAssets) },
        { name: 'Total Debt', data: parseData(data.totalDebt) },
        { name: 'Total Assets', data: parseData(data.totalAssets) },
        { name: 'Net Income', data: parseData(data.netIncome) },
        { name: 'Total Sales', data: parseData(data.totalSales) },
        { name: 'Long Term Debt', data: parseData(data.longTermDebt) },
        { name: 'EBITDA', data: parseData(data.ebitda) },
        { name: 'Cash Balance', data: parseData(data.cashBalance) }
    ];
    const qRowData = [
        { name: 'Current Ratio', data: parseData(data.qCurrentRatio) },
        { name: 'Long Term Solvency', data: parseData(data.qLongTermSolvency) },
        { name: 'Return On Sales', data: parseData(data.qReturnOnSales) },
        { name: 'Interest Coverage', data: parseData(data.qInterestCoverage) },
        { name: 'Current Assets', data: parseData(data.qCurrentAssets) },
        { name: 'Total Debt', data: parseData(data.qTotalDebt) },
        { name: 'Total Assets', data: parseData(data.qTotalAssets) },
        { name: 'Net Income', data: parseData(data.qNetIncome) },
        { name: 'Total Sales', data: parseData(data.qTotalSales) },
        { name: 'Long Term Debt', data: parseData(data.qLongTermDebt) },
        { name: 'EBITDA', data: parseData(data.qEbitda) },
        { name: 'Cash Balance', data: parseData(data.qCashBalance) }
    ];

    const headers = data.releaseDate;
    const qHeaders = data.qReleaseDate;

    function clickQ() {
        setSECPeriod("quarter");
    }
    function clickY() {
        setSECPeriod("year");
    }

    return (
        <div>
            <div className="mktps-sec-table-header">
                <h3>Sector: {props.sector ? props.sector : "N/A"}</h3>
                <h3>SIC Code: {props.sicCode ? props.sicCode : "N/A"}</h3>
                <h3>Index: {props.index ? props.index : "N/A"}</h3>
                <button className={secPeriod === "quarter" ? "highlighted-button" : ""} onClick={clickQ}>Quarterly</button>
                <button className={secPeriod === "year" ? "highlighted-button" : ""} onClick={clickY}>Yearly</button>
            </div>
            <TableContainer className="table-container">
                <Table className="table" sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            {(secPeriod === "quarter" ? qHeaders : headers).map((header) => (
                                <TableCell align="right" key={header}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(secPeriod === "quarter" ? qRowData : rowData).map((row, rowIndex) => (
                            <TableRow key={row.name} className={rowIndex % 2 === 0 ? "alternate-row even" : "alternate-row odd"}>
                                <TableCell component="th" scope="row" sx={{ whiteSpace: 'nowrap' }}>
                                    {row.name}
                                </TableCell>
                                {row.data.map((data, index) => (
                                    <TableCell align="right" key={index}>
                                        {data}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    );
}