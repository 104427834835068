import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../css/SimilarityTable.css'

export function SimilarityTable(props) {

    const data = props.data;
    const antiRiskData = props.antiRiskData;

    if (!data && !antiRiskData) {
        return(<h3>No Similarity Data Available</h3>)
    }

    var keys = data ? (Object.keys(data)).reverse() : [];
    var new_keys = [];
    if (keys.includes("ETF_PRICE")) new_keys.push("ETF_PRICE");
    if (keys.includes("NEGATIVE_ETF_PRICE")) new_keys.push("NEGATIVE_ETF_PRICE");
    if (keys.includes("PRICE")) new_keys.push("PRICE");
    if (keys.includes("NEGATIVE_PRICE")) new_keys.push("NEGATIVE_PRICE");
    for (var key of keys) {
        if (!new_keys.includes(key)) new_keys.push(key);
    }
    keys = new_keys;

    function parseData(numbers) {
        const formattedNumbers = numbers.map(number => {
            if (Math.abs(number) >= 1000000000) {
                const formatted = (number / 1000000000).toFixed(2);
                return `${formatted}b`;
            } else if (Math.abs(number) >= 1000000) {
                const formatted = (number / 1000000).toFixed(2);
                return `${formatted}m`;
            } else {
                const formatted = parseFloat(number).toFixed(3);
                return formatted;
            }
        });
        return formattedNumbers;
    }

    const better_headers = {
        "ETF_PRICE": "Similar ETF Price",
        "NEGATIVE_PRICE": "Equity Price Hedge",
        "NEGATIVE_ETF_PRICE": "ETF Price Hedge",
        "PRICE": "Similar Equity Price",
        "ROS": "Return on Sales",
        "IRC": "Interest Rate Coverage",
        "LTS": "Long Term Solvency",
        "COMB": "Combined SEC Ratios",
        "BONDS": "Bond Price",
        "EI": "EI"
    }
    const relative_value_index = (data && 'relative_value' in data) ? data.relative_value.array_index : -1;
    const is_relative_value = (data && 'relative_value' in data) ? data.relative_value.isSameDirection : false;

    const headers = []
    const unparsed = []
    for (let i = 0; i < keys.length; i++) {
        if ((keys[i] !== "date_range") && (data[keys[i]] !== "N/A") && (keys[i] !== "relative_value")) {
            headers.push(better_headers[keys[i]]);
            unparsed.push(data[keys[i]]);
        }
    }
    if (antiRiskData) {
        headers.push("VaR-type Hedge");
        const antiRiskArray = []
        for (let i = 0; i < antiRiskData.length; i++) {
            const dict = {};
            dict['ticker'] = antiRiskData[i][0];
            dict['value'] = antiRiskData[i][1];
            antiRiskArray.push(dict);
        }
        unparsed.push(antiRiskArray);
    }

    const rowData = unparsed.reduce((prev, next) =>
             next.map((item, i) =>
            (prev[i] || []).concat(next[i])
        ), []);
    console.log(rowData);
    return (
        <div>
            {data && data.date_range ? <h3>Similarity {data.date_range[0]} to {data.date_range[1]}</h3> : null}
            {/* {data && data.date_range ? <h3>Similarity Date Range: {data.date_range[0]} to {data.date_range[1]}<br></br>Anti-Risk Date Range: {} to {data.date_range[1]}</h3> : null} */}
            {/* {data && data.date_range ? <h3>Similarity Range: Past 365 days<br></br>Anti-Risk Range: Past 140 days</h3> : null} */}
            <TableContainer className="table-container">
            <Table className="table" size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell align="center" key={header}>
                                <strong>{header}</strong>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowData.map((row, row_index) => (
                        <TableRow key={`sim_row_${row_index}`} >
                            {row.map((data, index) => (
                                <TableCell
                                    align="center"
                                    key={`sim_item_${index}`}
                                    sx={{
                                        backgroundColor:
                                            (index === 1 && relative_value_index >= 0 && row_index === relative_value_index)
                                                ? (is_relative_value ? 'var(--yellow)' : 'var(--green)')
                                                : (row_index % 2 === 0 ? 'var(--background-grey)' : 'var(--background-white)')
                                    }}
                                >
                                    <div
                                        onClick={() => props.searchFunction(data.ticker)}
                                        data-tooltip-id="mktps-header-tooltip"
                                        data-tooltip-content={`${index !== row.length - 1 ? "Correlation:" : "Combined VaR:"} ${data.value ? data.value.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0] : "N/A"}${index !== row.length - 1 ? "" : "%"}${(index === 1 ? ", EI: " + (Math.round(10000 * Math.abs(data.ei15)) / 100).toString() + "% " + (data.ei15 > 0 ? "buy" : "sell") : "")} ${(index === 1 && relative_value_index >= 0 && row_index === relative_value_index) ? "(Relative Value)" : ""}`}
                                    >
                                        {data.ticker + ((index === 1 && relative_value_index >= 0 && row_index === relative_value_index) ? " (RV)" : "")}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
}
