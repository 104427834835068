import React, { useState, useEffect } from 'react';

export function FeedbackModal(props) {
    const [email, setEmail] = useState(localStorage.getItem("user-email-bam-money") ? localStorage.getItem("user-email-bam-money") : "");
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState("");
    const [isCheckedWrong, setIsCheckedWrong] = useState(false);
    const [isCheckedMissing, setIsCheckedMissing] = useState(false);
    const [isCheckedAdd, setIsCheckedAdd] = useState(false);
    const [isCheckedExplore, setIsCheckedExplore] = useState(false);

    const getProfile = async () => {
        if (email == "") {
            fetch("/api/account/profile")
                .then((response) => response.json())
                .then((json) => {
                    setEmail(json.email);
                    localStorage.setItem("user-email-bam-money", json.email);
                });
        }
    };
    useEffect(() => {
        getProfile()
    }, []);

    let handleSubmitShare = async (e) => {
        e.preventDefault();
        handleSubmit("share");
    }

    let handleSubmitFeedback = async (e) => {
        e.preventDefault();
        handleSubmit("feedback");
    }

    async function handleSubmit(endpoint) {
        /*if (!email) {
            setResponse("Email required to submit");
            return;
        }*/
        const validateEmail = (em) => {
            return String(em)
                .toLowerCase().match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        };
        if (email && !validateEmail(email)) {
            setResponse("Valid email required to submit");
            return;
        }
        /*let token = captchaRef.current.getValue();
        if (!token) {
            setResponse("Recaptcha required to submit");
            return;
        }*/
        try {
            let res = await fetch(`/api/public/${endpoint}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    message: message,
                    url: window.location.href,
                    feedback: {
                        wrong: isCheckedWrong,
                        missing: isCheckedMissing,
                        somethingToAdd: isCheckedAdd,
                        somethingToExplore: isCheckedExplore
                    },
                    //captcha: token
                }),
            });
            //captchaRef.current.reset();
            if (res.status === 200) {
                setEmail("");
                setMessage("");
                setResponse("Message sent successfully");
            } else if (res.status === 403 || res.status === "403") {
                setResponse("Message error: please write in English and do not include links");
            } else {
                setResponse("Some error occurred");
                console.log("not here");
            }
        } catch (err) {
            console.log(err);
        }
    };

    function handleKeyDown(e) {
        e.target.style.height = 'inherit';
        e.target.style.height = `${Math.min(e.target.scrollHeight - 20, 116)}px`;
    }

    return (
        <div className="home-chat-modal">
            <h3>{props.type == "share" ? "Share by Email" : "Submit Feedback"}</h3>
            <form className="home-modal-form">
                {props.type !== "mktps" ? <input type="text" value={email} id="email" onChange={(e) => setEmail(e.target.value)} placeholder={props.type == "share" ? "Recipient" : "Your Email (optional)"} /> : null}
                {(props.type == "feedback") || (props.type == "mktps") ?
                    <>
                        <label><input type="checkbox" checked={isCheckedWrong} onChange={() => setIsCheckedWrong((prev) => !prev)} /><span>Something was wrong</span></label>
                        <label><input type="checkbox" checked={isCheckedMissing} onChange={() => setIsCheckedMissing((prev) => !prev)} /><span>Something was missing</span></label>
                        <label><input type="checkbox" checked={isCheckedAdd} onChange={() => setIsCheckedAdd((prev) => !prev)} /><span>Something to be added</span></label>
                        <label><input type="checkbox" checked={isCheckedExplore} onChange={() => setIsCheckedExplore((prev) => !prev)} /><span>Something to be explored</span></label>
                        <br />
                    </>
                    : null}
                <textarea rows="1" name="text" value={message} id="message" placeholder="Comments (optional)" onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyDown} />
                {/*<div className="home-onboarding-recaptcha">{recaptchaSiteKey ? <ReCAPTCHA sitekey={recaptchaSiteKey} ref={captchaRef} /> : null}</div>*/}
                <button onClick={props.type == "share" ? handleSubmitShare : handleSubmitFeedback}>Submit</button>
                <div className="message">{response ? <p>{response}</p> : null}</div>
            </form>
        </div>
    );
}