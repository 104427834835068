import { Table as MUITable, TableBody as MUITableBody, TableContainer, Paper, TableHead, TableRow, TableCell } from '@mui/material';

export function MFC({ data }) {
    if (!data) return null;  // for no data 

    const headers = {
        "0": "Sentiment",
        "1": "Price",
        "2": "Volume",
        "3": "Financial Ratios",
        "4": "Market Cap",
        "5": "Day of Week",
        "6": "Volatility",
        "7": "Credit Ratings",
        "8": "Sector",
        "9": "Combination Effects"
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: 40, width:'70%' }}> 
            <MUITable aria-label="interpret-percentages-table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><strong>Feature</strong></TableCell>
                        <TableCell align="center"><strong>Feature Contribution Percentages</strong></TableCell>
                    </TableRow>
                </TableHead>
                <MUITableBody>
                    {Object.keys(data).map((feature, index) => (
                        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                            <TableCell align="center">{headers[feature]}</TableCell>
                            <TableCell align="center">
                                {
                                    // making sure value exists and is a number=>convert to number & display day 29
                                    data[feature] && data[feature][29] !== undefined 
                                    ? Number(data[feature][29]).toFixed(2) + '%'
                                    : 'N/A'  // display 'N/A' if there's no data for day 29
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </MUITableBody>
            </MUITable>
        </TableContainer>
    );
}
