import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import React, { useState, useEffect } from 'react';
import { Link, } from "react-router-dom";

import { AlpacaClient, AlpacaStream } from '@master-chief/alpaca';

import { Typography, Container, Box, Button, ButtonGroup, List, TextField, Paper, Slider, ListItemButton, Switch } from '@mui/material';
import { TableCell, TableRow, Table, TableHead, TableBody, TableContainer, Select, MenuItem, FormControl, InputLabel, } from '@mui/material';
import { FormControlLabel } from '@mui/material';

import RefreshIcon from '@mui/icons-material/Refresh';
import D3PortfolioHistory from "../d3Charts/d3PortfolioHistory";
import dayjs from 'dayjs';

// Generate an array of dates for the past 30 days
const generatePast30Days = () => {
    const dates = [];
    for (let i = -1; i < 30; i++) {
        dates.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
    }
    return dates.reverse(); // So the earliest date is at the start of the array
};

function TradingRow(props) {
    const data = props.data;
    return (
        <TableRow>
            <TableCell>{data.ticker}</TableCell>
            <TableCell align="right">{data.side}</TableCell>
            <TableCell align="right">{`${data.value} @ ${data.quantity} × ${data.price}`}</TableCell>
            <TableCell align="right">{data.intraday_pnl}</TableCell>
            <TableCell align="right">{data.total_pnl}</TableCell>
        </TableRow>
    )
}

function CurrentPositions(props) {
    const rows = props.data;
    return (
        <Container>
            <h3>Current Positions</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 200 }} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ticker</TableCell>
                            <TableCell align="right">Direction</TableCell>
                            <TableCell align="right">Value (# Shares)</TableCell>
                            <TableCell align="right">Intraday PNL</TableCell>
                            <TableCell align="right">Total PNL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => <TradingRow data={row} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            {rows.length > 0 ? null : <p>You don't have any trading strategies yet.</p>}
        </Container>
    )
}

function CloseRow(props) {
    const data = props.data;
    return (
        <TableRow>
            <TableCell>{data.ticker}</TableCell>
            <TableCell align="right">{data.action}</TableCell>
            <TableCell align="right">{`$${Math.round(data.value * 100) / 100} @ ${Math.round(data.quantity * 100) / 100} × $${Math.round(data.price * 100) / 100}`}</TableCell>
            <TableCell align="right">{`$${Math.round(data.current_value * 100) / 100} @ ${Math.round(data.quantity * 100) / 100} × $${Math.round(data.current_price * 100) / 100}`}</TableCell>
            <TableCell align="right">{data.total_pnl}</TableCell>
        </TableRow>
    )
}

function OpenRow(props) {
    const data = props.data;
    return (
        <TableRow>
            <TableCell>{data.ticker}</TableCell>
            <TableCell align="right">{data.action}</TableCell>
            <TableCell align="right">{`$${Math.round(data.value * 100) / 100} @ ${Math.round(data.quantity * 100) / 100} × $${Math.round(data.price * 100) / 100}`}</TableCell>
        </TableRow>
    )
}

function PerformTrades(props) {
    const dates = generatePast30Days();
    const [value, setValue] = useState([15, 20]);
    const [closeRows, setCloseRows] = useState([]);

    const [filtering, setFiltering] = useState("All");
    const [maxPerName, setMaxPerName] = useState(1000);
    const [numNames, setNumNames] = useState(20);
    const [minPrice, setMinPrice] = useState(1.0);
    const [openRows, setOpenRows] = useState([]);

    const handleMinPriceChange = (event, newValue) => {
        setMinPrice(Math.pow(10, newValue) <= -2 ? -2 : Math.pow(10, newValue));
    };
    const handleMinPriceCommit = (event, newValue) => {
        setMinPrice(Math.pow(10, newValue) <= -2 ? -2 : Math.pow(10, newValue));
        fetchTradingpulse();
    };

    const minPriceMarks = [
        {
            value: -2,
            label: '$.01',
        },
        {
            value: -1,
            label: '$.10',
        },
        {
            value: 0,
            label: '$1',
        },
        {
            value: 1,
            label: '$10',
        },
        {
            value: 2,
            label: '$100',
        },
        {
            value: 3,
            label: '$1000',
        }
    ];


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getAriaValueText = (v) => {
        return dates[v];
    };

    async function handleChangeCommitted() {
        const closeDataTmp = await props.getPastPositions(dates[value[0]], dates[value[1] + 1]);
        setCloseRows(closeDataTmp);
    }

    function trySellAll() {
        props.attemptOrderNames(closeRows, 'sell')
    }
    function tryBuyAll() {
        // openRows = [{ticker: "AAPL", value: 34.56}...] value in usd
        props.attemptOrderNames(openRows, 'buy')
    }

    async function fetchTradingpulse() {
        // get tradingpulse
        const indicesParam = ((filtering != "All") ? `&indices=${encodeURIComponent(filtering)}` : "");
        const minPriceParam = `&min_price=${minPrice.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`
        const debtorsPerPage = `&limit=${numNames}`;
        const sortParam = `&sort=-ei15`;

        const response = await fetch(`/api/marketpulse?search=&page=1${indicesParam}${sortParam}${minPriceParam}${debtorsPerPage}&show_favorites=false&sort_def=-sorting_priority.average_pnl&sumPNL=false`)
        const json = await response.json();
        const tickerList = json.data.map((t) => {return(t.ticker)});
        const prices = await props.getPrices(tickerList);
        const parsed = tickerList.map(
            (t) => {
                console.log(prices[t].latestTrade.p)
                return({
                    ticker: t,
                    action: "Buy",
                    value: maxPerName,
                    quantity: maxPerName / prices[t].latestTrade.p,
                    price: prices[t].latestTrade.p
                })
            }
        )
        setOpenRows(parsed);
    }

    useEffect(() => {
        fetchTradingpulse();
    }, [filtering, maxPerName, numNames])

    return (
        <>
            <h3>Perform Trades</h3>
            <div style={{ display: "inline-flex" }}>
                <div style={{ margin: "20px", width: "45vw" }}>
                    <h4>Open New Positions</h4>
                    <p>Select conditions and a list of names will be generated for approval.</p>
                    <FormControl>
                        <InputLabel id="filtering-label-profile">Filtering by Index</InputLabel>
                        <Select
                            labelId="filtering-label-profile"
                            value={filtering}
                            label="Filtering"
                            onChange={event => { setFiltering(event.target.value); }}
                            MenuProps={{disableScrollLock: true}}
                        >
                            <MenuItem value={"All"}>All Names (5600+)</MenuItem>
                            <MenuItem value={"S&P 500"}>S&P500 only</MenuItem>
                            <MenuItem value={"S&P 400"}>S&P400 only</MenuItem>
                            <MenuItem value={"Russell 2000"}>Russell2k only</MenuItem>
                        </Select>
                        <br />
                    </FormControl>
                    <TextField style={{margin: "0 10px"}} label="Max Holdings Per Name ($)" type="number" variant="outlined" value={maxPerName} onChange={event => { setMaxPerName(event.target.value); }} />
                    <TextField label="Number of Names Per Day" type="number" variant="outlined" value={numNames} onChange={event => { setNumNames(event.target.value); }} />
                    <div style={{width: "40%", margin: "auto"}}> <p style={{margin: "auto"}}>Minimum Equity Price</p>
                    <Slider
                                    aria-label="Volume"
                                    min={-2}
                                    max={3}
                                    step={.01}
                                    value={Math.log10(minPrice)}
                                    valueLabelDisplay="auto"
                                    scale={x => `$${Math.pow(10, x).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`}
                                    onChange={handleMinPriceChange}
                                    onChangeCommitted={handleMinPriceCommit}
                                    marks={minPriceMarks}
                                    sx={{
                                        padding: "20px",
                                    }}
                                />
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticker</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                    <TableCell align="right">Purchase Value (# Shares)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {openRows.map((row) => <OpenRow data={row} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {openRows && openRows.length > 0 &&
                        <>
                            <button onClick={tryBuyAll}>Try to Buy Names Above</button>
                            <p>The button aboves attempts to submit the above quantities of equities as notional market buy orders. If brokerage cash runs out, an error will occur resolved by the exchange. The share quantities and equity prices displayed are estimates, not guarantees.</p>
                        </>
                    }

                </div>
                <div style={{ margin: "20px", width: "45vw" }}>
                    <h4>Close Old Positions</h4>
                    <div style={{ width: "40%", margin: "auto" }}>
                        <Slider
                            getAriaLabel={() => 'Date range'}
                            value={value}
                            onChange={handleChange}
                            onChangeCommitted={handleChangeCommitted}
                            valueLabelDisplay="auto"
                            valueLabelFormat={getAriaValueText}
                            getAriaValueText={getAriaValueText}
                            min={0}
                            max={dates.length - 2}
                            marks={dates.map((date, index) => ({ value: index }))}
                        />
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 100 }} aria-label="table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticker</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                    <TableCell align="right">Purchase Value (# Shares)</TableCell>
                                    <TableCell align="right">Current Value (# Shares)</TableCell>
                                    <TableCell align="right">Total PNL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {closeRows.map((row) => <CloseRow data={row} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {closeRows && closeRows.length > 0 &&
                        <>
                            <button onClick={trySellAll}>Try to Sell Names Above</button>
                            <p>The button aboves attempts to submit the above quantities of equities as market sell orders. If you have already sold them in the past or don't have a proper open position, an error may occur and will be resolved by the exchange in use.</p>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

function AlpacaPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isPaper, setIsPaper] = useState(false);
    const [auth, setAuth] = useState(false);
    const [client, setClient] = useState(null);
    const [positions, setPositions] = useState([]);
    const [error, setError] = useState("");
    const [account, setAccount] = useState({
        account_number: "N/A",
        buying_power: 0,
        cash: 0,
        portfolio_value: 0
    });
    const [chartData, setChartData] = useState([]);


    async function attemptLogin() {
        const client_ = new AlpacaClient({
            credentials: {
                key: username,
                secret: password,
                paper: isPaper,
            },
            rate_limit: true,
        });
        setClient(client_);
        const isAuth = await client_.isAuthenticated();
        if (isAuth) {
            setAuth(true);
        } else {
            setError("Invalid Credentials");
        }
    }

    async function updateAccount() {
        if (client) {
            const account = await client.getAccount();
            setAccount(account);
        }
    }

    async function getRenderChart() {
        updateAccount();

        if (client) {
            const history = await client.getPortfolioHistory({ period: '1M', timeframe: '1D' });
            let arr = [];
            for (let i = 0; i < history.equity.length; i++) {
                arr.push({ date: history.timestamp[i], value: history.equity[i] })
            }
            setChartData(arr);
        }
    }

    async function getOpenPositions() {
        if (client) {
            const posdata = await client.getPositions();
            let parsedPosdata = []
            for (let i = 0; i < posdata.length; i++) {
                parsedPosdata.push({
                    ticker: posdata[i].symbol,
                    side: posdata[i].side,
                    value: `$${posdata[i].market_value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`,
                    quantity: `${posdata[i].qty.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}`,
                    price: `$${posdata[i].current_price.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}`,
                    intraday_pnl: `${posdata[i].unrealized_intraday_pl > 0 ? "+" : "-"}$${Math.abs(Math.round(posdata[i].unrealized_intraday_pl * 100) / 100)} (${Math.round(posdata[i].unrealized_intraday_pl / posdata[i].cost_basis * 1000) / 1000}%)`,
                    total_pnl: `${posdata[i].unrealized_pl > 0 ? "+" : "-"}$${Math.abs(Math.round(posdata[i].unrealized_pl * 100) / 100)} (${Math.round(posdata[i].unrealized_pl / posdata[i].cost_basis * 1000) / 1000}%)`,
                })
            }
            setPositions(parsedPosdata);
        }
    }

    async function getPastPositions(date_start, date_end) {
        if (client) {
            let param = { activity_type: 'FILL' };
            if (date_start) {
                param.after = date_start
            }
            if (date_end) {
                param.until = date_end
            }
            const history = await client.getAccountActivities(param);
            const aggregated = history.reduce((acc, obj) => {
                // Check if the id already exists in the accumulator
                if (obj.side != "buy") {
                    return acc;
                }
                if (!acc[obj.symbol]) {
                    // If not, create an entry with the initial values
                    acc[obj.symbol] = { ticker: obj.symbol, quantity: 0, price: 0, value: 0, action: (obj.side == "buy" ? "sell" : "N/A") };
                }

                // weighted average of price obj.price
                acc[obj.symbol].price = (acc[obj.symbol].price * acc[obj.symbol].quantity + obj.price * obj.qty) / (acc[obj.symbol].quantity + obj.qty);

                // Sum the values for each id
                acc[obj.symbol].quantity += obj.qty;
                acc[obj.symbol].value += obj.price * obj.qty;

                return acc;
            }, {});
            var data = Object.values(aggregated);
            const keys = Object.keys(aggregated);
            if (keys && keys.length > 0) {
                const snapshot = await client.getSnapshots({ symbols: keys });
                data = data.map((row) => {
                    row.current_price = snapshot[row.ticker].latestTrade.p;
                    row.current_value = row.quantity * row.current_price;
                    let diff = row.current_value - row.value;
                    row.total_pnl = `${diff >= 0 ? "+" : "-"}$${Math.round(Math.abs(diff) * 100) / 100} (${Math.round(diff / row.value * 100) / 100}%)`
                    return row;
                });
            }
            return data;
        }
    }

    function reload() {
        getRenderChart();
        getOpenPositions();
    }

    async function placeOrder(ticker, qty, type) {
        await client.placeOrder({
            symbol: ticker,
            qty: qty,
            side: type,
            type: 'market',
            time_in_force: 'day'
        });
    }
    async function placeOrderVal(ticker, value, type) {
        await client.placeOrder({
            symbol: ticker,
            notional: value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0],
            side: type,
            type: 'market',
            time_in_force: 'day'
        });
    }

    async function attemptOrderNames(names, type) {
        // names = [{ticker: "AAPL", quantity: 3.4562}...] or [{ticker: "AAPL", value: 34.56}...] dollars
        for (let i = 0; i < names.length; i++) {
            if ("quantity" in names[i]) {
                placeOrder(names[i].ticker, names[i].quantity, type);
            } else if ("value" in names[i]) {
                placeOrderVal(names[i].ticker, names[i].value, type);
            }
        }
        setTimeout(reload, 500);
        setTimeout(reload, 1000);
    }

    async function getPrices(tickers) {
        if (client) {
            const response = await client.getSnapshots({ symbols: tickers });
            return response;
        }
        return {};
    }

    useEffect(() => {
        if (client) {
            reload();
        }
    }, [auth]);


    return (
        <>
            {
                !auth ?
                    <>
                        <h3>Authenticate to Alpaca</h3>
                        <TextField
                            required
                            id="outlined-required"
                            label="Key"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <br /><br />
                        <TextField
                            required
                            id="outlined-password-input"
                            label="Secret"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <br /><br />
                        <FormControlLabel control={<Switch onChange={(e) => setIsPaper(e.target.value)} />} label="Paper Account" />
                        <br /><br />
                        <Button variant="contained" onClick={attemptLogin}>Submit</Button>
                        {
                            error != "" &&
                            <>
                                <br />
                                <p style={{ color: "red" }}>{error}</p>
                            </>
                        }
                        <br /><br />
                        <a href="https://app.alpaca.markets/brokerage/dashboard/overview" target="_blank" rel="noreferrer noopener">
                            <img alt="" src="images/alpaca_steps.png" width="60%" />
                        </a>

                    </>
                    :
                    <>
                        <h3>You are Logged In to Alpaca ({account.account_number}) <Button onClick={reload}><RefreshIcon /></Button></h3>
                        <p>Buying Power: {account.buying_power}&nbsp;&nbsp;Cash: {account.cash}&nbsp;&nbsp;Portfolio Value: {account.portfolio_value}</p>
                        <div style={{ width: "70%", height: "300px", margin: "0 auto" }}>
                            <D3PortfolioHistory data={chartData} />
                        </div>
                        <CurrentPositions data={positions} />
                        <PerformTrades getPastPositions={getPastPositions} attemptOrderNames={attemptOrderNames} getPrices={getPrices}/>
                        <p>Disclaimer: all brokerage data is provided by Alpaca, not fAlpha.ai.</p>
                    </>


            }
        </>
    )
}

export default function AutoTrade() {
    window.document.title = "fAlpha.ai | Systematic Trading";
    const [brokerage, setBrokerage] = useState(null);

    return (
        <>
            <PublicNavbar />
            <PublicTitle title="Systematic Trading (BETA)" />
            <Box style={{ height: "fit-content", minHeight: "60vh" }}>
                {
                    !brokerage &&
                    <h3>Select a Brokerage to Get Started</h3>
                }
                <ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button variant={brokerage === "Alpaca" ? "contained" : "outlined"} onClick={() => setBrokerage("Alpaca")}>Alpaca</Button>
                    <Button variant={brokerage === "More" ? "contained" : "outlined"} onClick={() => setBrokerage("More")}>More..</Button>
                </ButtonGroup>
                {
                    brokerage === "Alpaca" &&
                    <AlpacaPage />
                }
                { // more case
                    brokerage === "More" &&
                    <h3>Looking for another online brokerage with an API connection? Please <Link to="/contact">Contact Us</Link> to have it pushed up the queue to be implemented.</h3>
                }
                <p>All Credentials and API calls are handled locally for your security. fAlpha.ai never has direct access to your brokerage account.</p>
            </Box>

            <PublicFooter />
        </>
    );
}