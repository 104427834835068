import React, { useRef, useState, useEffect } from "react";
import '../css/d3.css'
import {
    select,
    line,
    scaleLinear,
    axisBottom,
    axisLeft,
    svg,
    interpolateNumber,
    scaleTime,
    extent,
    bisector
} from "d3";
import { Tooltip } from 'react-tooltip';

//chart component
export default function D3TrainingResult({
    custom_pnl,
    bam_pnl,
    iis,
    model_name,
    onlyfAlpha
}) {
    const data = onlyfAlpha ? bam_pnl : custom_pnl.slice(Math.floor(custom_pnl.length * 0.8));
    //refs
    const svgRef = useRef();
    const boundingRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    function getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }


    useEffect(() => {
        function handleWindowResize() {
            setWidth(boundingRef.current.offsetWidth);
            setHeight(boundingRef.current.offsetHeight);
            svgRef.current.style.width = boundingRef.current.offsetWidth;
            svgRef.current.style.height = boundingRef.current.offsetHeight;
        }

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])

    //draws chart
    useEffect(() => {
        const svg = select(svgRef.current);
        svg.selectAll("*").remove();

        //scales
        const xScale = scaleTime()
            .domain(extent(data, d => new Date(d.date)))
            .range([0, width]);

        const yScale = scaleLinear().domain([Math.min(...data.map(item => item.avg)), Math.max(...data.map(item => item.avg))]).range([height - 30, 0]);
        //axes
        const xAxis = axisBottom(xScale).tickFormat(d => getFormattedDate(d));
        const yAxis = axisLeft(yScale);

        // Create a line generator
        const lineGenerator = line()
            .x(d => xScale(new Date(d.date)))
            .y(d => yScale(d.avg))



        // Create the line chart
        if (!onlyfAlpha) {
            svg.append("path")
                .datum(data)
                .attr("class", "line")
                .attr("d", lineGenerator)
                .style("stroke", "blue")
                .style("fill", "none");
        }

        svg.append("path")
            .datum(bam_pnl)
            .attr("class", "line")
            .attr("d", lineGenerator)
            .style("stroke", "black")
            .style("fill", "none");
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height - 20})`)
            .call(xAxis);

        svg.append("g")
            .attr("class", "y-axis")
            .attr("transform", "translate(50,0)")
            .call(yAxis);
        const horizontalLine = line()
            .x(d => d.x)
            .y(d => d.y)
            .defined(d => d !== null);
        const horizontalLineData = [
            { x: 0, y: yScale(0.0) },
            { x: width, y: yScale(0.0) }
        ];
        svg.append("path")
            .datum(horizontalLineData)
            .attr("class", "horizontal-line")
            .attr("d", horizontalLine)
            .style("stroke", "black")
            .style("stroke-width", "1px")
            .style("stroke-dasharray", "3,3")  // Make the line dashed
            .style("fill", "none");
        let iis_string = ""
        if (iis >= 0) {
            iis_string = `+${iis.toFixed(4)}`
        } else {
            iis_string = `${iis.toFixed(4)}`
        }
        let legendData = (onlyfAlpha ? [] : [{ color: "blue", label: `${model_name} ${iis_string}` }])
        legendData.push({ color: "black", label: "fAlpha Model" })

        // Adding legend
        const legend = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(${width - 350}, 20)`);

        legend.selectAll("rect")
            .data(legendData)
            .enter()
            .append("rect")
            .attr("x", 0)
            .attr("y", (d, i) => i * 20)
            .attr("width", 10)
            .attr("height", 10)
            .style("fill", d => d.color);

        legend.selectAll("text")
            .data(legendData)
            .enter()
            .append("text")
            .attr("x", 15)
            .attr("y", (d, i) => i * 20 + 10)
            .html(d => {
                if (d.label.includes(`${model_name}`)) {
                    let iis_label = iis_string;
                    if (iis >= 0)
                        return `${model_name} -
                        <tspan\
                            data-tooltip-id="d3-training-tooltip"\
                            data-tooltip-html="IIS score: ${iis_label}"\
                            dy="4" fill="green">Informative data</tspan>`
                    else return `${model_name} -
                        <tspan\
                            data-tooltip-id="d3-training-tooltip"\
                            data-tooltip-html="IIS score: ${iis_label}"\
                            dy="4" fill="red">Uninformative data</tspan>`
                } else {
                    return d.label;
                }
            })
            .attr("alignment-baseline", "middle");

        // Adding vertical bars for both datasets
        if (!onlyfAlpha) {
            const bisectDateBam = bisector(d => new Date(d.date)).left;

            custom_pnl.forEach(customItem => {
                const date = new Date(customItem.date);

                // Find closest data points in bam_pnl
                const bamIndex = bisectDateBam(bam_pnl, date, 1);
                const d0 = bam_pnl[bamIndex - 1];
                const d1 = bam_pnl[bamIndex];

                // Interpolate the avg value if date not found in bam_pnl
                let bamItem;
                if (d0 && d1) {
                    const interpolate = interpolateNumber(d0.avg, d1.avg);
                    const interpolatedAvg = interpolate((date - new Date(d0.date)) / (new Date(d1.date) - new Date(d0.date)));
                    bamItem = {
                        date: date,
                        avg: interpolatedAvg
                    };
                } else if (d0) {
                    bamItem = d0;
                } else if (d1) {
                    bamItem = d1;
                }

                if (bamItem) {
                    const barHeight = Math.abs(yScale(customItem.avg) - yScale(bamItem.avg));
                    const barY = yScale(Math.max(customItem.avg, bamItem.avg));
                    svg.append("rect")
                        .attr("x", xScale(date) - 0.5)
                        .attr("y", barY)
                        .attr("width", 1)
                        .attr("height", barHeight)
                        .attr("fill", customItem.avg > bamItem.avg ? "green" : "red")
                        .attr("opacity", 0.5);
                }
            });
        }
    }, [width, height, data]);

    return (
        <div ref={boundingRef} style={{ height: "100%", width: "100%" }}>
            <div className="d3-bounding-anim-once">
                <svg ref={svgRef} style={{ height: "100%", width: "100%" }}>
                </svg>
            </div>
            <Tooltip id="d3-training-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} />
        </div>
    );
};
