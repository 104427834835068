import '../css/charts.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";

import {registerables } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, ...registerables);

export function Sentiment(props) {

    const [chartOptions, setChartOptions] = useState(null);
    const [ssData, setssData] = useState(null);
    const [highSS, setHighSS] = useState(null);
    const [avgSS, setAvgSS] = useState(null);
    const [lowSS, setLowSS] = useState(null);
    const [hideChart, setHideChart] = useState(false);

    function initializeChart(json) {

        var sshs = json.high_score_latest30.map(x => x === 10 ? null : x);
        var ssas = json.avg_score_latest30.map(x => x === 10 ? null : x);
        var ssls = json.low_score_latest30.map(x => x === 10 ? null : x);
        for (let i = 0; i < sshs.length; i++) {
            if (sshs[i] == null && ssas[i] != null && ssls[i] != null) {
                sshs[i] = ssas[i];
            }
            else if (sshs[i] != null && ssas[i] == null && ssls[i] != null) {
                ssas[i] = (sshs[i] + ssls[i]) / 2;
            }
            else if (sshs[i] != null && ssas[i] != null && ssls[i] == null) {
                ssls[i] = ssas[i];
            }
            else if (sshs[i] == null && ssas[i] == null && ssls[i] != null) {
                sshs[i] = ssls[i];
                ssas[i] = ssls[i];
            }
            else if (sshs[i] == null && ssas[i] != null && ssls[i] == null) {
                sshs[i] = ssas[i];
                ssls[i] = ssas[i];
            }
            else if (sshs[i] != null && ssas[i] == null && ssls[i] == null) {
                ssas[i] = sshs[i];
                ssls[i] = sshs[i];
            }
        }

        if (5 < ssas.slice(-10).reduce((count, element) => { if (element === null) { count++; } return count; }, 0)) {
            setHideChart(true);
            return null;
        }

        function findLastSS(arr) {
            for (let i = arr.length - 1; i >= 0; i--) {
                if (arr[i] !== 10) {
                    return arr[i].toFixed(2);
                }
            }
            return null;
        }

        setHighSS(findLastSS(json.high_score_latest30));
        setAvgSS(findLastSS(json.avg_score_latest30));
        setLowSS(findLastSS(json.low_score_latest30));

        var xaxis_SS = new Array(30);
        var parts = json.SS_Date.split('/');
        var datec = new Date(parts[0], parts[1] - 1, parts[2]);

        for (var idx = 1; idx < 31; idx++) {
            var date = new Date(datec);
            date.setDate(datec.getDate() - idx + 1);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0');
            xaxis_SS[30 - idx] = mm + '/' + dd;
        }

        const radiusArray = [];
        for (let i = 0; i < sshs.length - 1; i++) {
            if (i < 6) {
                radiusArray.push(1 + i / 6);
            } else {
                radiusArray.push(2);
            }
        }
        radiusArray.push(3);

        var dataHigh = {
            label: "Bullish News",
            data: sshs,
            lineTension: 0,
            fill: false,
            pointRadius: radiusArray,
            borderColor: 'var(--green)',
            borderWidth: 1
        };
        var dataAvg = {
            label: "Average News",
            data: ssas,
            lineTension: 0,
            fill: false,
            pointRadius: radiusArray,
            borderColor: 'var(--blue)',
            borderWidth: 1
        };
        var dataLow = {
            label: "Bearish News",
            data: ssls,
            lineTension: 0,
            fill: false,
            pointRadius: radiusArray,
            borderColor: 'var(--red)',
            borderWidth: 1
        };
        var dataHighDash = {
            data: sshs,
            lineTension: 0,
            fill: false,
            borderColor: 'rgba(var(--green-rgb), 0.4)',
            pointRadius: 0,
            pointHitRadius: 0,
            spanGaps: true,
            borderWidth: 1
        };
        var dataAvgDash = {
            data: ssas,
            lineTension: 0,
            fill: false,
            borderColor: 'rgba(var(--blue-rgb), 0.4)',
            pointRadius: 0,
            pointHitRadius: 0,
            spanGaps: true,
            borderWidth: 1
        };
        var dataLowDash = {
            data: ssls,
            lineTension: 0,
            fill: false,
            borderColor: 'rgba(var(--red-rgb), 0.4)',
            pointRadius: 0,
            pointHitRadius: 0,
            spanGaps: true,
            borderWidth: 1
        };
        var ssData = {
            labels: xaxis_SS,
            datasets: [dataHigh, dataAvg, dataLow, dataHighDash, dataAvgDash, dataLowDash]
        };
        var chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: 0,
            },
            scales: {
                y: {
                    border: {
                        display: false,
                    },
                    min: -1.13,
                    afterBuildTicks: axis => axis.ticks = [-1, 0, 1].map(v => ({ value: v })),

                },
                x: {
                    border: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxRotation: 0,
                        //align: "inner",
                        autoSkip: false,
                        callback: function (value, index, values) {
                            if (index === 28) return xaxis_SS[29];
                            if (index === 0) return xaxis_SS[0];
                            else return '';
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            }
        };
        setChartOptions(chartOptions);
        setssData(ssData);
    }

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/data/public-ss?ticker=${props.ticker}`)
            if (!res.ok) {
                setHideChart(true);
                return;
            }
            try {
                let json = await res.json();
                initializeChart(json);
            } catch (error) {
                setHideChart(true);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {hideChart ? null :
                <div className="sentiment-container">
                    <h5 className="sentiment-header">News Sentiment</h5>
                    <h3 className="sentiment-h3">{props.ticker}</h3>
                    <div className="sentiment-chart-bounding">
                        {ssData && chartOptions ? <Line data={ssData} options={chartOptions} /> : <p>Loading...</p>}
                    </div>
                    <div className="sentiment-three-numbers">
                        <p className="sentiment-high">Bullish</p>
                        <p className="sentiment-high number">{highSS}</p>
                        <p className="sentiment-avg number">{avgSS}</p>
                        <p className="sentiment-low number">{lowSS}</p>
                        <p className="sentiment-low">Bearish</p>
                    </div>
                    <p></p>
                </div>}
        </>
    );
}

export function Check(props) {

    const [signal, setSignal] = useState("N/A");
    const [signalColor, setSignalColor] = useState("check-gray");
    const [signalConfidence, setSignalConfidence] = useState("N/A");
    const [eqSignal, setEqSignal] = useState("N/A");
    const [eqSignalColor, setEqSignalColor] = useState("check-gray");
    const [eqSignalConfidence, setEqSignalConfidence] = useState("N/A");
    const [VaR, setVaR] = useState("N/A");
    const [VaRConf, setVaRConf] = useState("N/A");
    const [ES, setES] = useState("N/A");
    const [ESConf, setESConf] = useState("N/A");
    const [PtT, setPtT] = useState("N/A");
    const [PtTDateStart, setPtTDateStart] = useState("N/A");
    const [PtTDateEnd, setPtTDateEnd] = useState("N/A");
    const [dotColor, setDotColor] = useState("check-dot-gray");
    const [hideChart, setHideChart] = useState(false);

    function initializeChart(json) {
        if (!Number.isNaN(json.lstm.ei15)) {
            setSignalConfidence((Math.abs(json.lstm.ei15) * 100).toFixed(1));
            if (json.lstm.ei15 > 0.55) {
                setSignalColor("check-green");
                setSignal("Buy");
            } else if (json.lstm.ei15 < -0.55) {
                setSignalColor("check-red");
                setSignal("Sell");
            } else {
                setSignalColor("check-blue");
                setSignal("Hold");
            }
        }
        if (!Number.isNaN(json.lstm.ci15)) {
            setEqSignalConfidence((Math.abs(json.lstm.ci15) * 100).toFixed(1));
            if (json.lstm.ci15 > 0.55) {
                setEqSignalColor("check-green");
                setEqSignal("Buy");
            } else if (json.lstm.ci15 < -0.55) {
                setEqSignalColor("check-red");
                setEqSignal("Sell");
            } else {
                setEqSignalColor("check-blue");
                setEqSignal("Hold");
            }
        }

        // handle peak to trough date calculation
        let currentDate = new Date();
        currentDate.setTime(Date.parse(json.risk.PTT["Time Period"]["End"]));
        setPtTDateEnd(`${currentDate.getMonth() + 1}/${currentDate.getDate()}`);
        currentDate.setTime(Date.parse(json.risk.PTT["Time Period"]["Start"]));
        setPtTDateStart(`${currentDate.getMonth() + 1}/${currentDate.getDate()}`);

        // handle rest of the variables
        setVaR(json.risk.VaR.Amount);
        setVaRConf(json.risk.VaR["Percentage Cutoff"]);
        setES(json.risk.ES.Amount);
        setESConf(json.risk.ES["Percentage Cutoff"]);
        setPtT(json.risk.PTT.Amount);

    }

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/data/public-check?ticker=${props.ticker}`)
            if (!res.ok) {
                setHideChart(true);
                return;
            }
            try {
                let json = await res.json();
                initializeChart(json);
            } catch (error) {
                setHideChart(true);
            }
        }
        fetchData();
    }, []);

    return (
        <>{hideChart ? null : 
            <div className='check-container'>
            <div className="check-section-top">
                <h5 className="check-header">Current Check</h5>
                <h3 className="check-ticker">{props.ticker}</h3>
                <div className={`check-signal equity ${eqSignalColor}`}>
                    <div>
                        <h3 className="check-signal-border">{eqSignal}</h3>
                        <p>Credit</p>
                    </div>
                    <span></span>
                    <div>
                        <h3>{eqSignalConfidence}%</h3> {/*(Math.abs(data.lstm.ei) * 100).toFixed(1)*/}
                        <p>Confidence</p>
                    </div>
                </div>
                <div className={`check-signal ${signalColor}`}>
                    <div>
                        <h3 className="check-signal-border">{signal}</h3>
                        <p>Equity</p>
                    </div>
                    <span></span>
                    <div>
                        <h3>{signalConfidence}%</h3> {/*(Math.abs(data.lstm.ei) * 100).toFixed(1)*/}
                        <p>Confidence</p>
                    </div>
                </div>
                <p className="check-desc-rm">Risk Metrics (100 trading days)</p>
                <div className="check-grid">
                    <div>
                        <h5>VaR ({VaRConf}% 1d)</h5>
                        <h5>{VaR}%</h5>
                    </div>
                    <span></span>
                    <div>
                        <h5>ES ({ESConf}% 1d)</h5>
                        <h5>{ES}%</h5>
                    </div>
                    <span></span>
                    <div>
                        <h5>Peak-to-Trough</h5>
                        <h5>{PtT}% {PtTDateStart}-{PtTDateEnd}</h5>
                    </div>
                </div>
            </div>
            {/*<div className="check-section-bottom">
                <h5 className="check-header">Historical Check</h5>
                <div className="check-chart-bounding">Chart container</div>
                <span className={`check-dot ${dotColor}`}></span>
            </div>*/}
        </div>
        }</>
    );
}

export function HistoricalCheck(props) {

    const [chartOptions, setChartOptions] = useState(null);
    const [pnlData, setpnlData] = useState(null);
    const [hideChart, setHideChart] = useState(false);
    const [dotColor, setDotColor] = useState("check-dot-gray");

    function initializeChart(json) {

        var pnl_arr = json["pnl_arr"][0].hp15;

        var xaxis = [];

        var return_arr = [];
        var zeroLine = [];

        for (let i = 0; i < pnl_arr.length; i++) {
            //generate label
            let date = new Date(pnl_arr[i]["date"]);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0');
            xaxis.push(mm + '/' + dd);

            return_arr.push(pnl_arr[i]["return15"]);
            zeroLine.push(0);
        }

        if (pnl_arr[pnl_arr.length - 1]['return15'] >= 0) {
            setDotColor('check-dot-green');
        } else if (pnl_arr[pnl_arr.length - 1]['return15'] < 0) {
            setDotColor('check-dot-red');
        }

        var returnData = {
            label: "Return",
            data: return_arr,
            lineTension: 0,
            fill: false,
            pointRadius: 1,
            borderColor: 'rgb(39, 125, 161)',
            borderWidth: 1
        };
        var pnlData = {
            labels: xaxis,
            datasets: [returnData]
        };
        var chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: 0,
            },
            scales: {
                y: {
                    border: {
                        display: false,
                    },
                    //min: -1.13,
                    //afterBuildTicks: axis => axis.ticks = [-1, 0, 1].map(v => ({ value: v })),
                },
                x: {
                    border: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxRotation: 0,
                        autoSkip: false,
                        //align: "inner",
                        callback: function (value, index, values) {
                            if (index === xaxis.length - 1) {
                                return xaxis[xaxis.length - 1]
                            } else if (index === 0) {
                                return xaxis[0]
                            } else return '';
                        }
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            }
        };
        setChartOptions(chartOptions);
        setpnlData(pnlData);
    }

    useEffect(() => {
        async function fetchData() {
            const res = await fetch(`/api/data/public-pnl-check?ticker=${props.ticker}`)
            if (!res.ok) {
                setHideChart(true);
                return;
            }
            try {
                let json = await res.json();
                initializeChart(json);
            } catch (error) {
                setHideChart(true);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {hideChart ? null :
                <div className="sentiment-container">
                    <h5 className="sentiment-header">Historical P{"&"}L <br />Check</h5>
                    <h3 className="sentiment-h3">{props.ticker}</h3>
                    <div className="sentiment-chart-bounding">
                        {pnlData && chartOptions ? <Line data={pnlData} options={chartOptions} /> : <p>Loading...</p>}
                    </div>
                    <span className={`check-dot ${dotColor}`}><p>P&L</p></span>
                </div>}
        </>
    );
}