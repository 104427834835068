// auto-articles feature is all here
import '../css/articles.css';
import { Routes, Route, useParams, useLocation, Link } from 'react-router-dom';
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle.js";
import { } from "../components/articlesComponents.js";
import { Box, List, ListItem, ListItemText, useMediaQuery, useTheme, Grid, Slider, FormControlLabel, Switch, Button, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Register } from '../components/header.js';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Link as PDFLink } from '@react-pdf/renderer';

function clearTags(html) {
    if (!html) return "";
    // close header tags to newlines
    html = html.replace(/<\/h[^>]*>/g, '\n');
    // close paragraph tags to newlines
    html = html.replace(/<\/p>/g, '\n');
    // remove remaining tags
    html = html.replace(/<[^>]*>/g, '');
    const truncatedTagMatch = html.match(/<[^>]*$/);
    if (truncatedTagMatch && truncatedTagMatch[0].length < 10) {
        // Remove the truncated tag
        html = html.replace(/<[^>]*$/, '');
    }
    return html;
}

function parseDate(date) {
    return (new Date(date)).toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric' }) + " ET"
}

function ArticleList({ list, title }) {
    return (
        <Box sx={{
            height: "fit-content",
            width: "100%",
            padding: "20px"
        }}>
            <h3>{title}</h3>
            <List sx={{ width: '100%' }}>
                {
                    list.map((item) =>
                        <ListItem sx={{
                            borderTop: "solid black 1px"
                        }}>
                            <Link to={`/analysis/article/${item._id}`} style={{ textDecoration: "none", }}>
                                <p style={{
                                    textDecoration: "none",
                                    color: "var(--dark-font)",
                                    margin: "0"
                                }}>{clearTags(item.title)}</p>
                                <p style={{
                                    color: "rgba(var(--dark-font-rgb), 0.6)",
                                    fontSize: "11px",
                                    margin: "0"
                                }}>{clearTags(item.subtitle)}</p>
                            </Link>
                        </ListItem>
                    )
                }
            </List>
        </Box>
    )
}

const style = {
    box: {
        border: "1px solid black",
        padding: "3px",
        marginBottom: "10px",
    },
    boxSub: {
        borderTop: "0.5px solid gray",
        marginTop: "3px",
        paddingTop: "3px"
    },
    boxTitle: {
        fontSize: "14px"
    },
    boxText: {
        fontSize: "12px"
    },
    boxDate: {
        fontSize: "10px"
    }
}

function ArticlePDFDocument({
    title,
    subtitle,
    authors,
    body,
    date,
    news,
    priceTargetCount,
    showSentimentNews,
}) {
    console.log(news)
    return (
        <Document
            pageMode={"useThumbs"}
            pageLayout={"singlePage"}
        >
            <Page size="A4" style={{ backgroundColor: 'white', paddingBottom: "60px" }}>
                <View style={{ marginTop: "10px", paddingBottom: "5px", marginHorizontal: "2.5%" }} render={({ pageNumber }) => {
                    return (
                        <View style={{ flexDirection: "row", borderBottom: (pageNumber == 1 ? "" : "1px solid black") }}>
                            <Image style={{ width: "25%" }} src="/images/logo-dark-new.png" cache={true} />
                            <Text style={{ marginLeft: "auto", fontSize: "10px", marginTop: "10px", marginBottom: "2px" }}>{date}</Text>
                        </View>
                    )
                }} fixed />
                <View style={{ backgroundColor: "rgb(0, 53, 102)", padding: "20px" }}>
                    <Text style={{ color: "white", marginBottom: "5px", fontSize: "30px" }}>{title}</Text>
                    <Text style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "15px" }}>{subtitle}</Text>
                </View>
                <View style={{ margin: "2.5%", flexDirection: "row" }}>
                    <View style={{ width: "57%", marginRight: "3%" }}>
                        {
                            body.split('\n').map((paragraph) =>
                                <Text style={{ marginBottom: "10px", fontSize: "12px" }}>{paragraph}</Text>
                            )
                        }
                    </View>
                    <View style={{ width: "40%" }}>
                        {
                            authors && Array.isArray(authors) && authors.length >= 1 &&
                            <View style={style.box}>
                                <Text style={style.boxTitle}>Authors (<PDFLink src="https://falpha.ai/analysis">Generated</PDFLink>)</Text>
                                {
                                    authors.map((a, i) => (
                                        ((a.name && a.name != "") || (a.company && a.company != "") || (a.title && a.title != "") || (a.phone && a.phone != "") || (a.email && a.email != "")) &&
                                        <View style={style.boxSub}>
                                            {a.name && a.name != "" ?
                                                <Text style={style.boxText}>{a.name} {a.company && a.company != "" && `(${a.company})`}</Text>
                                                :
                                                (a.company && a.company != "" && <Text style={style.boxText}>{a.company}</Text>)
                                            }
                                            {a.title && a.title != "" && <Text style={style.boxText}>{a.title}</Text>}
                                            {a.phone && a.phone != "" && <Text style={style.boxDate}>{a.phone}</Text>}
                                            {a.email && a.email != "" && <PDFLink src={`mailto:${a.email}`}><Text style={style.boxDate}>{a.email}</Text></PDFLink>}
                                        </View>
                                    )
                                    )
                                }
                            </View>
                        }
                        {
                            news &&
                            <View style={style.box}>
                                <Text style={style.boxTitle}>{news.stock_exchange && `${news.stock_exchange}:`}{news.ticker} {news.Index && `(${news.Index})`}</Text>
                                <View style={style.boxSub}>
                                    <Text style={style.boxText}>{news.debtor}</Text>
                                </View>
                                {showSentimentNews &&
                                    <View style={style.boxSub}>
                                        <PDFLink src={news.high_link}><Text style={style.boxText}>High Sentiment News ({news.high_date})</Text></PDFLink>
                                        <PDFLink src={news.avg_link}><Text style={style.boxText}>Avg Sentiment News ({news.avg_date})</Text></PDFLink>
                                        <PDFLink src={news.low_link}><Text style={style.boxText}>Low Sentiment News ({news.low_date})</Text></PDFLink>
                                    </View>
                                }
                            </View>
                        }
                        {news && news.price_targets && Array.isArray(news.price_targets) && news.price_targets.length >= 1 && priceTargetCount > 0 &&
                            <View style={style.box}>
                                <Text style={style.boxTitle}>Recent Price Targets</Text>
                                {
                                    news.price_targets.map((pt, i) => {
                                        if (i < priceTargetCount) {
                                            return (<View style={style.boxSub}>
                                                <PDFLink src={pt.newsURL}><Text style={style.boxText}>{pt.analystName} ({pt.analystCompany})</Text></PDFLink>
                                                <Text style={style.boxText}>${pt.priceWhenPosted} {'>>'} ${pt.priceTarget}</Text>
                                                <Text style={style.boxDate}>{parseDate(pt.publishedDate)}</Text>
                                            </View>)
                                        }
                                    })
                                }
                            </View>
                        }





                    </View>
                </View>
                <View style={{
                    position: 'absolute',
                    fontSize: 12,
                    bottom: 30,
                    left: 0,
                    right: 0,
                    width: "100%",
                    color: 'grey'
                }} render={({ pageNumber, totalPages }) => {
                    return (
                        <View style={{ flexDirection: "row", width: "calc(95%)", margin: "auto", borderTop: "1px solid black", padding: "5px" }}>
                            <Image style={{ width: "80px" }} src="/images/logo-dark-new.png" cache={true} />
                            {pageNumber == 1 && <Text style={{ fontSize: "12px", marginLeft: "10px" }}>Report uses Generative AI. Please read our <PDFLink src="https://falpha.ai/terms">Terms</PDFLink>.</Text>}
                            <Text style={{ fontSize: "12px", marginLeft: "auto" }}>{pageNumber} / {totalPages}</Text>
                        </View>
                    )
                }} fixed />
            </Page>
        </Document>
    )
}

function ArticleSingle() {
    const { id } = useParams();
    const [render, setRender] = useState(false)
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")
    const [body, setBody] = useState("")
    const [date, setDate] = useState("")
    const [msg, setMsg] = useState("Loading Data...")
    const [news, setNews] = useState(null)
    const [priceTargetCount, setPriceTargetCount] = useState(3)
    const [showSentimentNews, setShowSentimentNews] = useState(true);
    const [authors, setAuthors] = useState([])
    const [showDefaultAuthors, setShowDefaultAuthors] = useState(true);
    var authorId = 0;
    const defaultAuthors = [{
        name: "fAlpha.ai",
        title: "Research Analyst",
        company: "",
        phone: "+1-203-609-0177",
        email: "info@fAlpha.ai"
    }]

    useEffect(() => {
        async function fetchListMain() {
            const response = await fetch(`/api/article/article?id=${id}`)
            const responseData = await response.json();
            setTitle(responseData.title)
            setSubtitle(responseData.subtitle)
            setBody(responseData.body)
            setDate(parseDate(responseData.createdAt))
            const responseNews = await fetch(`/api/marketpulse/search_by_id?is_etf=false&ticker=${responseData.ticker}`);
            const responseDataNews = await responseNews.json();
            setNews(responseDataNews)

            setRender(true)
        }
        try {
            fetchListMain();
        } catch (e) {
            setMsg(`error: ${e}`)
        }
    }, [])

    useEffect(() => {
        window.document.title = `fAlpha.ai | ${title == "" ? "Analysis Article" : clearTags(title)}`;
    }, [title])

    function updateAuthors(index, field, newValue) {
        const tmpAuthors = [...authors];
        tmpAuthors[index][field] = newValue;
        setAuthors(tmpAuthors);
    }

    const PDFDocument = (
        <ArticlePDFDocument
            title={clearTags(title)}
            subtitle={clearTags(subtitle)}
            authors={authors.concat(showDefaultAuthors ? defaultAuthors : [])}
            body={clearTags(body)}
            date={date}
            news={news}
            priceTargetCount={priceTargetCount}
            showSentimentNews={showSentimentNews}
        />
    )

    const downloadLink = (
        <PDFDownloadLink
            document={PDFDocument}
            fileName={`fAlpha_analysis_${id}.pdf`}
        >
            {({ blob, url, loading, error }) =>
                loading ? <button>Generating Analysis...</button> : <button>Download Analysis</button>
            }
        </PDFDownloadLink>
    )

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    if (isMobile) {
        return (
            <div>
                <h2>PDF Editor not supported on mobile. Download Below</h2>
                {downloadLink}
            </div>
        )
    }

    return (
        <div style={{
            minHeight: "80vh",
            display: "flex"
        }}>
            <div style={{
                width: "50%",
            }}>
                <h2>PDF Settings</h2>
                {downloadLink}
                <Register text="Try it Free" highlight />
                <div className="article-controls-container">
                    <div className="article-controls-column">
                        <div>
                            <FormControlLabel control={
                                <Switch
                                    checked={showSentimentNews}
                                    onChange={(e) => setShowSentimentNews(e.target.checked)}
                                />
                            } label="Show News Articles Links" />
                        </div>
                        <div>
                            <p>Number of Price Targets</p>
                            <Slider
                                value={priceTargetCount}
                                onChange={(e) => setPriceTargetCount(e.target.value)}
                                step={1}
                                marks
                                min={0}
                                max={5}
                                valueLabelDisplay="auto"
                            />
                        </div>


                    </div>
                    <div className="article-controls-column">
                        <div>
                            <FormControlLabel control={
                                <Switch
                                    checked={showDefaultAuthors}
                                    onChange={(e) => setShowDefaultAuthors(e.target.checked)}
                                />
                            } label="Show Default fAlpha Author" />
                        </div>
                        <div>
                            {
                                authors.map((author, index) =>
                                    <div style={{ border: "1px solid var(--lightsteelblue)", marginBottom: "10px" }} key={`author-add-key-${author.id}`}>
                                        <div style={{ display: "flex", padding: "5px", columnGap: "5px" }}>
                                            <TextField
                                                size="small"
                                                placeholder="Name"
                                                value={authors[index].name}
                                                onChange={(e) => updateAuthors(index, "name", e.target.value)}
                                            />
                                            <TextField
                                                size="small"
                                                placeholder="Title"
                                                value={authors[index].title}
                                                onChange={(e) => updateAuthors(index, "title", e.target.value)}
                                            />
                                        </div>
                                        <div style={{ display: "flex", padding: "5px", columnGap: "5px" }}>
                                            <TextField
                                                size="small"
                                                placeholder="Company"
                                                value={authors[index].company}
                                                onChange={(e) => updateAuthors(index, "company", e.target.value)}
                                            />
                                            <TextField
                                                size="small"
                                                placeholder="Phone Number"
                                                value={authors[index].phone}
                                                onChange={(e) => updateAuthors(index, "phone", e.target.value)}
                                            />
                                        </div>
                                        <div style={{ display: "flex", padding: "5px", columnGap: "5px" }}>
                                            <TextField
                                                size="small"
                                                placeholder="Email"
                                                value={authors[index].email}
                                                onChange={(e) => updateAuthors(index, "email", e.target.value)}
                                            />
                                            <Button
                                                onClick={() => setAuthors(authors.filter((_, i) => i !== index))}
                                            ><DeleteForeverIcon />Delete</Button>
                                        </div>
                                    </div>
                                )
                            }
                            <Button onClick={() => { setAuthors(authors.concat([{ name: "", title: "", company: "", phone: "", email: "", id: authorId++ }])) }}><AddIcon />Add Author</Button>
                        </div>
                    </div>

                </div>
            </div>
            {
                render ?
                    <PDFViewer width="50%" height="1000px" className="truecolor">
                        {PDFDocument}
                    </PDFViewer>
                    :
                    <>
                        <p>{msg}</p>
                        <Register text="Try it Free" highlight />
                    </>
            }
        </div>
    )

}

function ArticlePage() {
    window.document.title = "fAlpha.ai | Analysis";
    const [articleListMain, setArticleListMain] = useState([]);

    useEffect(() => {
        async function fetchListMain() {
            const response = await fetch("/api/article/article-listing")
            const responseData = await response.json();
            setArticleListMain(responseData)
        }
        fetchListMain();
    }, [])

    return (
        <>
            <PublicTitle title="Analysis" />
            <div style={{
                margin: "auto",
                width: "60%",
                minWidth: "300px"
            }}>
                <ArticleList list={articleListMain} title="Latest Analysis" />
            </div>

        </>

    )
}


export function ArticlesMain() {
    let location = useLocation();

    return (
        <>
            <PublicNavbar />
            <Routes location={location}>
                <Route path="/article/:id" element={<ArticleSingle />} />
                <Route path="*" element={<ArticlePage />} />
            </Routes>
            <PublicFooter />
        </>
    )
}