import '../css/company.css';
import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";
import { Tooltip } from 'react-tooltip';

function TeamMemberIcon(props) {
    return (
        <a className="company-person" href={props.link} data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>${props.text}</p>`}>
            <div>
                <img src={props.img} alt="" />
            </div>
            <h3>{props.name}</h3>
            <p>{props.title}</p>
        </a>
    );
}

function AdvisorIcon(props) {
    return (
        <a className="company-advisor" href={props.link}>
            <img src={props.img} alt="" />
            <div>
                <h3>{props.name}</h3>
                <p>{props.desc}</p>
            </div>
        </a>
    );
}

export default function Company() {
    window.document.title = "fAlpha.ai | Company";

    return (
        <>
            <PublicNavbar />
            <PublicTitle title="About Us" />
            <div className="company-display-1">
                <div className="company-text">
                    <p>We are financial risk managers and data engineers who understand that the volume of data and noise in the financial landscape will continue to increase and this creates a huge challenge to some markets. Recognizing that efficient risk signals and high liquidity are essential, we draw on over 50 years of collective experience to navigate these challenges, safeguarding investments and identifying new opportunities.</p>
                    <h1>Vision</h1>
                    <p>A world where information producers own their data, control its release, and everyone understands the distinction between data and information.</p>
                </div>
                <img src="images/company-vision.png" alt="" className="company-img" />
            </div>
            <div className="company-display-2">
                <img src="images/company-mission.png" alt="" className="company-img" />
                <div className="company-text">
                    <h1>Mission</h1>
                    <p>
                        To empower traders to compete with industry giants.
                    </p>
                </div>
            </div>
            <div className="company-text">
                <h1>Values and Algorithm</h1>
                <p>
                    We believe that FACTS are the foundation of knowledge and are crucial for investors, traders, and financial professionals.
                </p>
                <p>
                    The identification of opportunities,  risk assessment and management are the foundation of sound investment decisions and form the integrity of financial markets. Factual data provides the basis for building trust and confidence among market participants. Therefore, FACTS serve as the cornerstone of fAlpha's values.
                </p>
                <p>
                    Our algorithm involves engaging the best team of focused producers guided by truth and the ambition of solving relevant problems. A curated network of users and producers is essential for our algorithm to work efficiently.
                </p>
                <p>
                    <p className="company-values" data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>we prioritize clarity and concentration, dedicating our efforts to the most impactful goals and improving results through mindful attention and purposeful actions</p>`}><strong>F</strong>ocus</p>
                    <p className="company-values" data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>we look ahead for  performance and responsible, meaningful innovations</p>`}><strong>A</strong>head</p>
                    <p className="company-values" data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>our clients are at the heart of everything we do. We prioritize their needs and keep their expectations grounded.</p>`}><strong>C</strong>lients</p>
                    <p className="company-values" data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>we commit to openness and clarity to foster trust through transparent communication, operations and verifiable information</p>`}><strong>T</strong>ransparency</p>
                    <p className="company-values" data-tooltip-id="company-page-tooltip" data-tooltip-html={`<p style='max-width: 40vw'>we leverage diverse talents and perspectives, fostering an environment where each member's contribution is valued and celebrated</p>`}><strong>S</strong>tronger Together</p>
                </p>
            </div>
            <hr />
            <h1>Core Team</h1>
            <div className="company-people-horizontal">
                <TeamMemberIcon link="https://www.linkedin.com/in/geraldo-filgueiras-03b37a6/" img="images/id_photos/Geraldo_photo.jpeg" name="Geraldo Filgueiras" title="CEO" text='Builder, risk manager, researcher with 20 yrs in financial capital markets including with Goldman Sachs, Barclays. His quantitative research includes "Value and Use of Information in Financial Markets".' />
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/rickrutter/" img="images/id_photos/RickRutter.jpeg" name="Rick Rutter" title="CMO" text='A data-driven marketer with 20+ years of experience across start-ups and Fortune 500 brands, including iShares and New England Financial.' />*/}
                <TeamMemberIcon link="https://www.linkedin.com/in/akhil-dayal-217878167/" img="images/id_photos/Akhil_photo.jpeg" name="Akhil Dayal" title="Product Designer" text='Designer and optimist who seeks to empower people through technology. He uses design to democratize technological solutions at scale.' />
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/rohan-kapur/" img="images/id_photos/RohanKapur.jpeg" name="Rohan Kapur" title="Data and Tech Lead"/>*/}
                <TeamMemberIcon link="https://www.linkedin.com/in/khanhn201/" img="images/id_photos/KhanhNguyen.jpeg" name="Khanh Nguyen" title="AI/ML/FS Engineer" text='Implementer, optimizer and parallel developer of frameworks to handle DE solvers and simulations. He loves to work with infrastructure, data science and machine learning.' />
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/linda-dominguez/" img="images/id_photos/linda.png" name="Linda Dominguez" title="Data Officer" text='Data scientist dedicated to optimizing financial efficiencies through advanced data models and driving system improvements with innovative solutions.' />*/}
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/mayajairam/" img="images/id_photos/maya.jpeg" name="Maya Jairam" title="Technology Officer" text='Passion for leveraging technology to solve real-world problems, enhancing efficiency through advanced data analysis, and improving systems through innovative integrations.' />*/}
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/kevinzhaoma/" img="images/id_photos/KevinZhao.jpeg" name="Kevin Zhao" title="Software Engineer" text='Software engineer with work in machine learning and quant finance. He loves to deal with algorithmic development, optimization, math and data analysis.' />*/}
                {/*<TeamMemberIcon link="https://www.linkedin.com/in/phyllis-c-chou-8973bb139/" img="images/id_photos/PhyllisChou.png" name="Phyllis Chou" title="Sales and Partnerships" text='Entrepreneur across various industries with expertise in finance and asset management. Focused on creating synergies through partnerships and creating sustainable solutions.' />*/}

            </div>
            <h1>Our Advisors</h1>
            <div className="company-people-horizontal">
                <TeamMemberIcon link="https://www.linkedin.com/in/kay-giesecke-a0797810/" img="images/id_photos/kaygiesecke.png" name="Kay Giesecke" title="" text="Kay is a tenured professor at Stanford University. His research and teaching are in the area of financial and risk analytics." />
                <TeamMemberIcon link="https://www.linkedin.com/in/bruce-petersen-131b0199/" img="images/id_photos/brucepeterson.png" name="Bruce Petersen" title="" text="Bruce was an executive at Goldman Sachs for 28 years. His expertises are in the areas of trading, risk and investment management." />
                <TeamMemberIcon link="https://www.linkedin.com/in/humberto-v-9364b719/" img="images/id_photos/humbertovalencia.jpeg" name="Humberto Valencia" title="" text="Humberto is a financial engineer and data researcher. He holds a PhD in Engineering from Stanford and previous research teaching and research roles with ITESM, MIT and Columbia." />
            </div>
            <Tooltip id="company-page-tooltip" multiline={true} data-html={true} style={{ zIndex: "105", backgroundColor: "white", color: "black", boxShadow: "0 0 5px #D9D9D9" }} />
            <PublicFooter />
        </>
    );
};
