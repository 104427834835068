import { useEffect, useState } from "react";

import { PublicFooter, PublicTitle, PublicNavbar } from "../components/pagestyle";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function AllPDF() {
    window.document.title = "fAlpha.ai | All PDFs";
    const [rows, setRows] = useState([]);

    function parsePDFData(pdf) {
        pdf.name = pdf.file.name;
        pdf.timestamp = (new Date(pdf.date)).toLocaleString();
        pdf.url = `/api/public/pdf-documents?id=${pdf["_id"]}`
        return pdf;
    }

    useEffect(() => {
        fetch('/api/public/all-pdf-data', {})
            .then((response) => response.json())
            .then((json) => setRows(json.map(parsePDFData)))
      }, []);


    return (
        <>
            <PublicNavbar />
            <PublicTitle title="All PDFs" />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>PDF Name</TableCell>
                            <TableCell align="right">Timestamp</TableCell>
                            <TableCell align="right">URL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.timestamp}</TableCell>
                                <TableCell align="right"><a href={row.url}>{row["_id"]}</a></TableCell> 
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PublicFooter />
        </>
    );
};